import useConsultings from "hooks/useConsultings";
import { ConsultingProps } from "models/Consulting";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
} from "react";

type Context = {
  consultings: ConsultingProps[] | undefined;
  error: string;
  isLoading: boolean;
  setConsultings: Dispatch<SetStateAction<ConsultingProps[] | undefined>>;
};

const ConsultingContext = createContext<Context>({} as Context);

export const useConsultingContext = (): Context =>
  useContext(ConsultingContext);

type Props = {
  children: React.ReactNode;
};

const ConsultingContextWrapper: FC<Props> = ({ children }) => {
  const { consultings, error, isLoading, setConsultings } = useConsultings();

  const contextValue = useMemo(
    () => ({
      consultings,
      error,
      isLoading,
      setConsultings,
    }),
    [consultings, error, isLoading, setConsultings]
  );

  return (
    <ConsultingContext.Provider value={contextValue}>
      {children}
    </ConsultingContext.Provider>
  );
};

export default ConsultingContextWrapper;
