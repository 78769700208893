import { FC, ReactElement } from "react";
import Box from "@mui/material/Box";

type CustomCardProps = {
  children: ReactElement;
};

const CustomCard: FC<CustomCardProps> = ({ children }) => {
  return (
    <Box
      sx={{ boxShadow: 3, padding: 2, borderRadius: 4, position: "relative" }}
    >
      {children}
    </Box>
  );
};
export default CustomCard;
