import { FC } from "react";
import MuiAlert from "@mui/material/Alert";

import { Snackbar } from "@mui/material";

type CustomSnackbarProps = {
  severity?: "error" | "warning" | "info" | "success";
  open: boolean;
  onClose: () => void;
  message: string;
};

const CustomSnackbar: FC<CustomSnackbarProps> = ({
  severity = "success",
  open,
  onClose,
  message,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ width: "100%" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert onClose={onClose} severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
export default CustomSnackbar;
