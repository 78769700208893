import { FC, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { getLocalizedString } from "use18n";

import { Box, Stack, Typography } from "@mui/material";
import { COLORS } from "ui/theme";
import { EpisodesProps } from "models/Episode";
import { ConsultingProps } from "models/Consulting";
import { getResponsiveValue } from "utils/app.utils";

type ListProps = Partial<EpisodesProps | ConsultingProps>;

type ItemNavigationProps = {
  list: ListProps;
  onClick: () => void;
  isPrev?: boolean;
  isNext?: boolean;
};

/**
 * A component representing a single list item in the navigation.
 * @param {ItemNavigationProps} props - Component props
 * @param {ListProps} props.list - The list object to display
 * @param {Function} props.onClick - Function to be called on click of the item
 * @param {boolean} [props.isPrev] - Determines if the item represents the previous list
 * @param {boolean} [props.isNext] - Determines if the item represents the next list
 * @returns {JSX.Element} - List item navigation component
 */

const ItemNavigation: FC<ItemNavigationProps> = ({
  list,
  onClick,
  isPrev,
  isNext,
}) => {
  return (
    <Stack alignItems="baseline" spacing={1} direction="row">
      <Box color={COLORS.gray[500]}> {isPrev && "<"}</Box>
      <Stack spacing={-1} onClick={onClick}>
        <Typography color={COLORS.gray[500]}>
          {getLocalizedString(list.title)}
        </Typography>
      </Stack>
      <Box color={COLORS.gray[500]}>{isNext && ">"}</Box>
    </Stack>
  );
};

type ListNavigationProps = {
  list: ListProps[];
  currentList: string;
  getPath: (val: string) => string;
};

/**
 * Navigation component that displays buttons to navigate to the previous and next list.
 * @param {ListNavigationProps} props - Component props
 * @param {Array<List>} props.list - Array of list
 * @param {string} props.currentList - Id of the current list
 * @example
 * <ListNavigation
 *    currentList={_id}
 *    list={list}
 * />
 * @returns {JSX.Element} - Navigation components
 */

const ListNavigation: FC<ListNavigationProps> = ({
  list,
  currentList,
  getPath,
}) => {
  const navigate = useNavigate();

  const currentIndex = useMemo(
    () => list.findIndex((list) => list._id === currentList),
    [currentList, list]
  );

  const prevList = useMemo(() => {
    if (currentIndex > 0) {
      return list[currentIndex - 1];
    }
  }, [currentIndex, list]);

  const nextList = useMemo(() => {
    if (currentIndex < list.length - 1) {
      return list[currentIndex + 1];
    }
  }, [currentIndex, list]);

  const handlePrevList = useCallback(() => {
    if (currentIndex > 0) {
      const prevList = list[currentIndex - 1];
      navigate(getPath(prevList?.title?.fr as string));
    }
  }, [currentIndex, list, navigate, getPath]);

  const handleNextList = useCallback(() => {
    if (currentIndex < list.length - 1) {
      const nextList = list[currentIndex + 1];
      navigate(getPath(nextList?.title?.fr as string));
    }
  }, [currentIndex, list, navigate, getPath]);

  return (
    <Stack
      sx={{
        cursor: "pointer",
        paddingX: getResponsiveValue(2, 10),
        paddingY: getResponsiveValue(2, 5),
      }}
      justifyContent="space-between"
      direction={"row"}
    >
      {prevList ? (
        <ItemNavigation isPrev onClick={handlePrevList} list={prevList} />
      ) : (
        <Box />
      )}
      {nextList ? (
        <ItemNavigation isNext onClick={handleNextList} list={nextList} />
      ) : (
        <Box />
      )}
    </Stack>
  );
};

export default ListNavigation;
