import useTraductions, {
  TraductionsAPI,
  Translation,
} from "hooks/useTraductions";

import { createContext, FC, useContext, useMemo } from "react";

type Context = {
  translations: TraductionsAPI | undefined;
  emptyTrad: Translation | undefined;
  error: string;
  isLoading: boolean;
};

const TranslationContext = createContext<Context>({} as Context);

export const useTranslationContext = (): Context =>
  useContext(TranslationContext);

type Props = {
  children: React.ReactNode;
};

const TranslationContextWrapper: FC<Props> = ({ children }) => {
  const { emptyTrad, error, isLoading, translations } = useTraductions();

  const contextValue = useMemo(
    () => ({
      translations,
      emptyTrad,
      error,
      isLoading,
    }),
    [translations, emptyTrad, error, isLoading]
  );

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationContextWrapper;
