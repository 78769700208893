import { Dispatch, FC, SetStateAction } from "react";
import { IconButton, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import HeaderPageWrapper from "Container/HeaderPageWrapper";

const mainTitle = "Ajouter un consulting";

type AddConsultingProps = {
  setIsConsultingFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onResetConsultingState: () => void;
};

const AddConsulting: FC<AddConsultingProps> = ({
  setIsConsultingFormModalOpen,
  onResetConsultingState,
}) => {
  return (
    <HeaderPageWrapper>
      <>
        <Typography sx={{ marginRight: 1 }} variant="h6">
          {mainTitle}
        </Typography>
        <IconButton
          onClick={() => {
            onResetConsultingState();
            setIsConsultingFormModalOpen(true);
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </>
    </HeaderPageWrapper>
  );
};
export default AddConsulting;
