import type { Dayjs } from "dayjs";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { capitalizeFirstLetter } from "utils/app.utils";

const useDate = (
  _date?: string | number | Date | Dayjs | null
): {
  monthAndYear: string;
  dayMonthAndYear: string;
  abbreviatedMonthAndYear: string;
} => {
  const { language } = navigator;

  const lang = language.includes("fr") ? "fr" : "en";

  const monthAndYear = capitalizeFirstLetter(
    dayjs(_date).locale(lang).format("MMMM YYYY")
  );
  const dayMonthAndYear = dayjs(_date).locale(lang).format("DD MMMM YYYY");
  const abbreviatedMonthName = dayjs(_date).locale(lang).format("MMM");
  const year = dayjs(_date).locale(lang).format("YYYY");

  return {
    monthAndYear,
    dayMonthAndYear,
    abbreviatedMonthAndYear: `${abbreviatedMonthName} ${year}`,
  };
};

export default useDate;
