import i18n, { InitOptions, ResourceKey } from "i18next";
import { initReactI18next } from "react-i18next";
import { useEffect } from "react";
import useTraductions from "hooks/useTraductions";

export type Lng = "fr" | "en";

type ItemLngs = {
  nativeName: string;
  flag: string;
};

export interface LocalizedString extends Record<Lng, string> {}

export const appLanguages: ReadonlyArray<Lng> = ["fr", "en"];

export const appLanguagesMapping: Record<string, ItemLngs> = {
  fr: { nativeName: "fr", flag: "🇫🇷" },
  en: { nativeName: "en", flag: "🇬🇧" },
};

const useI18n = () => {
  const { isLoading, translations } = useTraductions();

  useEffect(() => {
    if (translations) {
      const tempResources: { [key in Lng]: { translation: ResourceKey } } = {
        fr: { translation: translations.fr ?? {} },
        en: { translation: translations.en ?? {} },
      };

      const options: InitOptions = {
        resources: tempResources,
        lng: appLanguages[0],
        fallbackLng: appLanguages[0],
        interpolation: {
          escapeValue: false,
        },
      };

      i18n.use(initReactI18next).init(options);
    }
  }, [translations]);

  return { i18n, isLoading };
};

export const getLocalizedString = (
  localizedString?: LocalizedString
): string | undefined => {
  return localizedString
    ? localizedString[i18n.language as keyof LocalizedString]
    : undefined;
};

export default useI18n;
