import { useMemo } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SpotifyIcon from "assets/icons/spotify-logo.png";

const useSocialNetwork = () => {
  const spotifyUrl =
    "https://open.spotify.com/user/31itowf23xbzjggtql4uapkyt4vu?si=H5DgTLZZTf2oNXOfxiN6vw";
  const instagramUrl = "https://www.instagram.com/studioambiant/";
  const linkedinUrl = "https://www.linkedin.com/company/studio-ambiant/";

  const socialNetworks = useMemo(() => {
    return [
      {
        icon: (
          <LinkedInIcon
            sx={{
              color: "black",
            }}
          />
        ),
        url: linkedinUrl,
        title: "Linkedin",
      },
      {
        icon: (
          <InstagramIcon
            sx={{
              color: "black",
            }}
          />
        ),
        url: instagramUrl,
        title: "Instagram",
      },
      {
        icon: (
          <img
            style={{
              height: "21px",
              width: "21px",
            }}
            crossOrigin="anonymous"
            src={SpotifyIcon}
            alt="SpotifyIcon"
            loading="lazy"
          />
        ),
        url: spotifyUrl,
        title: "Spotify",
      },
    ];
  }, []);

  return { socialNetworks };
};

export default useSocialNetwork;
