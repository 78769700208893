import { Alert, Box } from "@mui/material";
import { FC } from "react";
type ErrorAPIProps = {
  message: string;
  minHeight?: string;
};
const ErrorAPI: FC<ErrorAPIProps> = ({ message, minHeight = "85vh" }) => {
  return (
    <Box
      justifyContent="center"
      display="flex"
      alignItems="center"
      minHeight={minHeight}
    >
      <Alert severity="error">{message}</Alert>
    </Box>
  );
};

export default ErrorAPI;
