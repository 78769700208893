import React, { FC } from "react";
import { Box, Typography } from "@mui/material";

import { getImgUrl, getResponsiveValue } from "utils/app.utils";
import { minHeight, sessionLeftPanelWidth } from "constants/constants";
type Props = {
  image: string;
  title?: Array<string | undefined>;
  text: Array<string | undefined>;
};

const SessionDetailHeader: FC<Props> = ({ image, title, text }) => {
  return (
    <Box
      display="flex"
      sx={{
        height: minHeight, // Subtract the height of the header from viewport height
        flexDirection: getResponsiveValue("column", "row"),
      }}
    >
      <Box
        sx={{
          background: `url(${getImgUrl(image)})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          width: getResponsiveValue("100%", sessionLeftPanelWidth),
          height: getResponsiveValue("70%", "100%"),
        }}
      />
      <Box
        sx={{
          backgroundColor: "white",
          width: getResponsiveValue("70%", "52%"),
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 3,
        }}
      >
        <Box>
          {title?.map((t) => (
            <Typography key={t + "title_episode_detail"} variant="h1">
              {t}
            </Typography>
          ))}
        </Box>
        <Box>
          {text.map((t, index) => (
            <Typography
              color={index >= 1 ? "gray" : undefined}
              key={"episode_consulting_detail_header_" + t}
              variant="body1"
            >
              {t}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SessionDetailHeader;
