import { Fab } from "@mui/material";
import { FC } from "react";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
type CustomButtonUploadFileProps = {
  label: string;
};
const CustomButtonUploadFile: FC<CustomButtonUploadFileProps> = ({ label }) => {
  return (
    <Fab
      color="secondary"
      size="small"
      component="span"
      aria-label="add"
      variant="extended"
    >
      <AddCircleOutlineOutlined style={{ marginRight: 2 }} /> {label}
    </Fab>
  );
};
export default CustomButtonUploadFile;
