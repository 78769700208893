import { FC } from "react";
import { RenderPdf } from "components/MainApp/RenderPdf";
import mentionLegales from "assets/pdf/mentionLegales.pdf";
import { Box } from "@mui/material";

const Legal: FC = () => {
  return (
    <Box p={1}>
      <RenderPdf file={mentionLegales} />
    </Box>
  );
};

export default Legal;
