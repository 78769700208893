import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import CustomButton from "ui/CustomButton";

import CustomInput from "ui/form/CustomInput";
import { InputStruct } from "models/App";
import API from "services/api";

import CustomSnackbar from "ui/CustomSnackbar";
import { CONTACT_URL } from "services/endPoint";

import { validateEmail } from "utils/app.utils";
import { useTranslation } from "react-i18next";

type ApiResponse = {
  severity: "error" | "warning" | "info" | "success" | undefined;
  message: string;
};
const initInputState = { value: "", error: "" };

// const ContactItem: FC<{ label: string; mail: string }> = ({ label, mail }) => {
//   const handleMailTo = () => {
//     window.location.href = `mailto:${mail}`;
//   };

//   return (
//     <Stack
//       alignItems="center"
//       spacing={0}
//       onClick={handleMailTo}
//       sx={{ cursor: "pointer", ":hover": { opacity: 0.7 } }}
//     >
//       <Typography variant="body2">{label}</Typography>
//       <Typography>{mail}</Typography>
//     </Stack>
//   );
// };

const Contact = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<InputStruct>(initInputState);
  const [message, setMessage] = useState<InputStruct>(initInputState);
  const [firstName, setFirstName] = useState<InputStruct>(initInputState);
  const [lastName, setLastName] = useState<InputStruct>(initInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse>();

  const handleCloseSnackbar = () => setIsSnackbarOpen(false);

  const handleSubmitMessage = () => {
    let error = false;
    if (!validateEmail(email.value)) {
      error = true;
      setEmail({
        ...email,
        error: t("contact.inputs.formatEmailError"),
      });
    }
    if (email.value === "") {
      error = true;
      setEmail({ ...email, error: t("common.requiredField") });
    }
    if (message.value === "") {
      error = true;
      setMessage({ ...message, error: t("common.requiredField") });
    }
    if (firstName.value === "") {
      error = true;
      setFirstName({ ...firstName, error: t("common.requiredField") });
    }
    if (lastName.value === "") {
      error = true;
      setLastName({ ...lastName, error: t("common.requiredField") });
    }

    if (error) return;
    const body = {
      email: email.value,
      message: message.value,
      firstName: firstName.value,
      lastName: lastName.value,
    };
    setIsLoading(true);
    API.post({
      path: CONTACT_URL,
      body: { ...body },
    })
      .then((res) => {
        setApiResponse({
          message: res.ok ? res.data : res.error,
          severity: res.ok ? "success" : "error",
        });
        setIsSnackbarOpen(true);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <Stack
        // direction={{ xs: "column", md: "row" }}
        // gap={{ xs: 2, sm: 4 }}
        px={{ xs: 1, sm: 8 }}
        py={{ xs: 2, sm: 8 }}
        // justifyContent="center"
        alignItems="center"
      >
        {/* <Stack
          sx={{ background: "#F2F3F3" }}
          p={{ xs: 1, sm: 4 }}
          alignItems="center"
          spacing={5}
          justifyItems="center"
          flex={1}
        >
          <Typography variant="h2">{t("contact.title")}</Typography>
          <ContactItem label="Direction" mail={t("contact.mail")} />
          <ContactItem label="Sales" mail={t("contact.salesMail")} />
          <ContactItem label="Marketing" mail={t("contact.marketingMail")} />
          <ContactItem label="Job" mail={t("contact.jobMail")} />
        </Stack> */}
        <Stack
          sx={{ background: "#F2F3F3" }}
          p={{ xs: 1, sm: 4 }}
          // justifyItems="center"
          spacing={3}
          maxWidth={600}
          // justifyContent="center"
          // flex={1}
        >
          <Typography mb={4} textAlign="center" variant="h2">
            {t("contact.oneQuestion")}
          </Typography>
          <Stack spacing={3} direction="row" justifyContent="space-between">
            <Box flex={1}>
              <CustomInput
                value={firstName.value}
                label={t("contact.firstName")}
                fullwidth
                onChange={(e) => {
                  setFirstName({ error: "", value: e.target.value });
                }}
                error={firstName.error}
              />
            </Box>
            <Box flex={1}>
              <CustomInput
                value={lastName.value}
                label={t("contact.lastName")}
                fullwidth
                onChange={(e) => {
                  setLastName({ error: "", value: e.target.value });
                }}
                error={lastName.error}
              />
            </Box>
          </Stack>

          <CustomInput
            value={email.value}
            label="Email"
            fullwidth
            onChange={(e) => {
              setEmail({ error: "", value: e.target.value });
            }}
            error={email.error}
          />

          <CustomInput
            value={message.value}
            label="Message"
            fullwidth
            rows={6}
            error={message.error}
            onChange={(e) => {
              setMessage({ error: "", value: e.target.value });
            }}
          />

          <CustomButton
            fullWidth
            title={t("common.submit")}
            onClick={handleSubmitMessage}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
      <CustomSnackbar
        message={apiResponse?.message as string}
        severity={apiResponse?.severity}
        open={isSnackbarOpen}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Contact;
