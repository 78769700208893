export const title = "title";
export const description = "description";
export const adress = "adress";
export const link = "link";

export const initState = {
  [description]: { value: "", error: "" },
  [title]: { value: "", error: "" },
  [adress]: { value: "", error: "" },
  [link]: { value: "", error: "" },
};
