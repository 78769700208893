import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";

import MainMediaApp from "components/MainApp/MainMediaApp";
import SEO from "components/SEO";

import SectionWrapper from "Container/SectionWrapper";

import { APP_STUDIO_PATH, APP_EPISODES_PATH } from "router/constants";
import PhraseWrapper from "Container/PhraseWrapper";

import MainWrapper from "Container/MainWrapper";
import EpisodesList from "components/MainApp/Episode/EpisodesList";
import { getResponsiveValue } from "utils/app.utils";
import { useNavigate } from "react-router-dom";
import CustomRoundedButton from "ui/CustomRoundedButton";

import { useTranslationContext } from "context/TranslationContext";

import { useConfigContext } from "context/ConfigContext";

const Home: FC = () => {
  const { config } = useConfigContext();
  const { t } = useTranslation();
  const { emptyTrad } = useTranslationContext();

  const navigate = useNavigate();

  const introTrad = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.homePage.introduction).map((key) =>
            t("homePage.introduction." + key)
          )
        : [],
    [emptyTrad, t]
  );

  return (
    <Box>
      <SEO
        title="Studio Ambiant - Accueil"
        description={t("homePage.introduction.text1") as string}
      />
      <Box mb={8}>
        <MainMediaApp logo={config?.logo} src={config?.mainImgUri} />
      </Box>

      <MainWrapper>
        <SectionWrapper
          title={t("common.studioAmbiant")}
          description={introTrad}
          footer={
            <Box>
              <CustomRoundedButton
                onClick={() => navigate(APP_STUDIO_PATH)}
                label={t("homePage.aboutUs")}
              />
            </Box>
          }
        />
        <Stack sx={{ py: getResponsiveValue(2, 0) }}>
          <Box mb={4}>
            <CustomRoundedButton
              label={
                t("homePage.episodes.select") +
                " " +
                t("homePage.episodes.labelLink")
              }
              onClick={() => navigate(APP_EPISODES_PATH)}
            />
          </Box>

          <EpisodesList sliceCount={3} />
        </Stack>
        <PhraseWrapper phrase={t("homePage.phrase")} />
      </MainWrapper>
    </Box>
  );
};
export default Home;
