import { FC } from "react";

import { getLocalizedString } from "use18n";
import { useTranslation } from "react-i18next";

import { EpisodesProps } from "models/Episode";

import useDate from "hooks/useDate";

import SEO from "components/SEO";
import SessionDetails from "components/MainApp/Session/SessionDetails";
import SessionDetailHeader from "components/MainApp/Session/SessionDetailHeader";
import { getEpisodePath } from "router/constants";

type Props = {
  episode: EpisodesProps;
  episodes: EpisodesProps[];
};

/**
 * EpisodeDetails component that displays the details of an episode.
 * @param {Props} props - Component props
 * @param {EpisodesProps} props.episode - Episode object
 * @param {Array<EpisodesProps>} props.episodes - Array of episodes objects
 * @returns {JSX.Element} - EpisodeDetails component
 */

const EpisodeDetails: FC<Props> = ({ episode, episodes }) => {
  const { t } = useTranslation();
  const { title, mainImgUri, subtitle, subtitle2, city, date } = episode;

  return (
    <>
      <SEO title={getLocalizedString(title) as string} />
      <SessionDetailHeader
        image={mainImgUri}
        title={[getLocalizedString(title), getLocalizedString(subtitle)]}
        text={[
          getLocalizedString(subtitle2),
          useDate(date).monthAndYear + ", " + city,
        ]}
      />
      {/* EPISODE DESCRIPTION */}
      <SessionDetails
        text={{
          title: t("episodeDetail.concept"),
          phrase: t("episodeDetail.phrase"),
        }}
        session={episode}
        sessions={episodes}
        getPath={getEpisodePath}
      />
    </>
  );
};

export default EpisodeDetails;
