import { FC, useMemo } from "react";
import { Box, Stack } from "@mui/material";

import SEO from "components/SEO";

import MainWrapper from "Container/MainWrapper";
import SectionWrapper from "Container/SectionWrapper";

import { useTranslation } from "react-i18next";
import EpisodesList from "components/MainApp/Episode/EpisodesList";
import PhraseWrapper from "Container/PhraseWrapper";
import { getResponsiveValue } from "utils/app.utils";
import TitleWithList from "components/MainApp/TitleWithList";

import { useTranslationContext } from "context/TranslationContext";

const Episodes: FC = () => {
  const { t } = useTranslation();
  const { emptyTrad } = useTranslationContext();

  const introEpisode = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.episode.description).map((key) =>
            t("episode.description." + key)
          )
        : [],
    [t, emptyTrad]
  );
  const interventionAreas = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.episode.interventionAreas.list).map((key) =>
            t("episode.interventionAreas.list." + key)
          )
        : [],
    [t, emptyTrad]
  );
  const knowHow = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.episode.knowHow.list).map((key) =>
            t("episode.knowHow.list." + key)
          )
        : [],
    [t, emptyTrad]
  );

  return (
    <MainWrapper>
      <SEO
        title="Studio Ambiant - Épisodes"
        description="Épisodes et évènements culinaires"
      />
      <Stack spacing={6}>
        <SectionWrapper title={t("episode.title")} description={introEpisode} />
        <Box
          sx={{ px: getResponsiveValue(1, 0), pb: getResponsiveValue(4, 0) }}
        >
          <EpisodesList />
        </Box>
      </Stack>
      <Stack
        py={getResponsiveValue(6, 0)}
        direction={getResponsiveValue("column", "row")}
        spacing={6}
      >
        <Box flex={1}>
          <TitleWithList title={t("episode.knowHow.title")} items={knowHow} />
        </Box>
        <Box flex={1}>
          <TitleWithList
            title={t("episode.interventionAreas.title")}
            items={interventionAreas}
          />
        </Box>
      </Stack>
      <PhraseWrapper phrase={t("episode.phrase")} />
    </MainWrapper>
  );
};

export default Episodes;
