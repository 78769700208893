import AddEpisode from "components/Admin/Episode/AddEpisode";
import EpisodeModalForm from "components/Admin/Episode/EpisodeModalForm";
import EpisodesList from "components/Admin/Episode/EpisodesList";
import ErrorAPI from "components/ErrorAPI";

import { appLanguages, LocalizedString } from "use18n";
import { ImgState, DateState, ImagesState } from "models/App";
import {
  EpisodeFormState,
  EpisodePayloadToAPI,
  EpisodesProps,
} from "models/Episode";
import { FC, useCallback, useState } from "react";
import API from "services/api";
import { EPISODE_URL } from "services/endPoint";

import CustomLoader from "ui/CustomLoader";
import { Files } from "ui/form/CustomInputUploadFiles";
import {
  createInitialState,
  createInputStruct,
  createLocalizedInputStruct,
  initDateState,
  initImagesState,
  initImgState,
} from "utils/app.utils";
import { episodeInputs } from "utils/episode.utils";
import { useEpisodeContext } from "context/EpisodeContext";

const Episodes: FC = () => {
  const {
    episodes,
    isLoading: isLoadingEpisode,
    error: isEpisodesError,
    setEpisodes,
  } = useEpisodeContext();

  // TODO REFACTO with same object on EpisodeModal form
  const emptyLocalizedString: LocalizedString = appLanguages.reduce(
    (obj, language) => {
      obj[language] = "";
      return obj;
    },
    {} as LocalizedString
  );

  const initialEpisodeFormState = () => ({
    ...createInitialState(episodeInputs),
    description: [emptyLocalizedString],
    published: false,
  });

  const [episodeFormState, setEpisodeFormState] = useState<EpisodeFormState>(
    initialEpisodeFormState() as EpisodeFormState
  );
  const [isEpisodeFormModalOpen, setIsEpisodeFormModalOpen] = useState(false);
  const [mainImgState, setMainImgState] = useState<ImgState>(initImgState);
  const [dateState, setDateState] = useState<DateState>(initDateState);
  const [imagesState, setImagesState] = useState<ImagesState>(initImagesState);
  const [isLoading, setIsLoading] = useState(false);
  const isEditing = !!episodeFormState._id;

  const onResetEpisodeState = () => {
    setEpisodeFormState(initialEpisodeFormState() as EpisodeFormState);
    setMainImgState(initImgState);
    setDateState(initDateState);
    setImagesState(initImagesState);
  };

  const onSubmitEpisode = async () => {
    if (
      episodeFormState.title.fr.value === "" ||
      episodeFormState.title.en.value === ""
    ) {
      setEpisodeFormState({
        ...episodeFormState,
        title: {
          ...episodeFormState.title,
          fr: {
            ...episodeFormState.title.fr,
            error:
              episodeFormState.title.fr.value === ""
                ? "Le champ titre français est requis"
                : "",
          },
          en: {
            ...episodeFormState.title.en,
            error:
              episodeFormState.title.en.value === ""
                ? "Le champ titre anglais est requis"
                : "",
          },
        },
      });
      return;
    }

    try {
      setIsLoading(true);
      // TODO use appLanguages
      const body: EpisodePayloadToAPI = {
        title: {
          fr: episodeFormState.title.fr.value,
          en: episodeFormState.title.en.value,
        },
        subtitle: {
          fr: episodeFormState.subtitle.fr.value,
          en: episodeFormState.subtitle.en.value,
        },
        subtitle2: {
          fr: episodeFormState.subtitle2.fr.value,
          en: episodeFormState.subtitle2.en.value,
        },
        description: episodeFormState.description,
        city: episodeFormState.city.value,
        date: dateState.date as Date,
        published: episodeFormState.published,
      };

      if (mainImgState.file !== undefined) {
        try {
          const resMainImg = await API.uploadFile("image", mainImgState.file);

          body.mainImgUri = resMainImg.path;
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }

      if (Object.keys(imagesState.files).length !== 0) {
        let responseApiImages: string[] = [];
        for (const image of Object.keys(imagesState.files)) {
          const fileIndex = Number(image);

          await API.uploadFile(
            "image",
            (imagesState.files as Files)[fileIndex]
          ).then((resFile) => {
            if (resFile.ok) responseApiImages.push(resFile.path);
          });
        }
        body.images = responseApiImages;
      }

      const resEpisode = isEditing
        ? await API.put({
            path: EPISODE_URL + episodeFormState._id,
            body,
          })
        : await API.post({
            path: EPISODE_URL,
            body,
          });

      if (resEpisode.ok) {
        setEpisodes(resEpisode.data);

        onResetEpisodeState();
      } else {
        console.error(resEpisode);
        setIsLoading(false);
      }
    } catch (error) {
      // TODO TRAIT ERROR
      console.log("error", error);
    } finally {
      setIsEpisodeFormModalOpen(false);
      setIsLoading(false);
    }
  };
  const onClickOnUpdateEpisode = useCallback(
    async (episodeData: EpisodesProps) => {
      setEpisodeFormState({
        _id: episodeData._id,
        mainImgUri: episodeData.mainImgUri,
        date: episodeData.date,
        images: episodeData.images,
        title: createLocalizedInputStruct(episodeData?.title),
        description: episodeData.description,
        city: createInputStruct(episodeData?.city),
        subtitle: createLocalizedInputStruct(episodeData?.subtitle),
        subtitle2: createLocalizedInputStruct(episodeData?.subtitle2),
        published: episodeData.published,
      });
      setDateState({ date: episodeData.date, error: false });
      setIsEpisodeFormModalOpen(true);
    },
    []
  );

  const onDeleteEpisode = useCallback(
    async (_id: string) => {
      const resEpisode = await API.delete({
        path: EPISODE_URL + _id,
      });
      if (resEpisode.ok) setEpisodes(episodes?.filter((ep) => ep._id !== _id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [episodes]
  );

  if (isEpisodesError) return <ErrorAPI message={isEpisodesError} />;
  if (isLoadingEpisode) return <CustomLoader />;

  return (
    <>
      <AddEpisode
        onResetEpisodeState={onResetEpisodeState}
        setIsEpisodeFormModalOpen={setIsEpisodeFormModalOpen}
      />
      <EpisodesList
        onClickOnUpdateEpisode={onClickOnUpdateEpisode}
        episodes={episodes}
        onDeleteEpisode={onDeleteEpisode}
      />
      <EpisodeModalForm
        isLoading={isLoading}
        setEpisodeFormState={setEpisodeFormState}
        setMainImgState={setMainImgState}
        setImagesState={setImagesState}
        setDateState={setDateState}
        setIsEpisodeFormModalOpen={setIsEpisodeFormModalOpen}
        onSubmitEpisode={onSubmitEpisode}
        onCloseModal={() => setIsEpisodeFormModalOpen(false)}
        episodeFormState={episodeFormState}
        mainImgState={mainImgState}
        imagesState={imagesState}
        dateState={dateState}
        isEpisodeFormModalOpen={isEpisodeFormModalOpen}
      />
    </>
  );
};

export default Episodes;
