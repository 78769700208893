import Application from "pages/Admin/Application";

import Consulting from "pages/Admin/Consulting";
import Episodes from "pages/Admin/Episodes";
import Studio from "pages/Admin/Studio";
import Ticketing from "pages/Admin/Ticketing";

import {
  CONSULTING_PATH,
  ADMIN_PATH,
  EPISODES_PATH,
  STUDIO_PATH,
  TICKETING_PATH,
} from "./constants";

const useAdminRouter = () => {
  const screens = [
    {
      component: <Application />,
      index: 0,
      path: ADMIN_PATH,
      name: "Application",
    },
    {
      component: <Episodes />,
      index: 1,
      path: EPISODES_PATH,
      name: "Épisodes",
    },
    {
      component: <Consulting />,
      index: 3,
      path: CONSULTING_PATH,
      name: "Conseil",
    },
    { component: <Studio />, index: 4, path: STUDIO_PATH, name: "Studio" },
    {
      component: <Ticketing />,
      index: 5,
      path: TICKETING_PATH,
      name: "Billeterie",
    },
  ];
  return { screens };
};

export default useAdminRouter;
