import { FC, useMemo } from "react";

import { Box, Stack, Typography } from "@mui/material";
import SEO from "components/SEO";
import MainWrapper from "Container/MainWrapper";
import SectionWrapper from "Container/SectionWrapper";
import { useTranslation } from "react-i18next";

import ErrorAPI from "components/ErrorAPI";
import CustomLoader from "ui/CustomLoader";

import LogoItem from "components/MainApp/LogoItem";
import { getResponsiveValue } from "utils/app.utils";
import ImageFullScreen from "ui/images/CustomImageFullScreen";

import { useTranslationContext } from "context/TranslationContext";
import { useStudioContext } from "context/StudioContext";

const Studio: FC = () => {
  const { t } = useTranslation();
  const { emptyTrad } = useTranslationContext();

  const {
    temperament,
    loadingTemperament,
    errorTemperament,
    partners,
    loadingPartners,
    errorPartners,
    customers,
    loadingCustomers,
    errorCustomers,
  } = useStudioContext();

  const foundersData = useMemo(
    () => [
      {
        name: t("studio.founders.Hanna.name"),
        job: t("studio.founders.Hanna.job"),
        description: t("studio.founders.Hanna.description"),
      },
      {
        name: t("studio.founders.Grégoire.name"),
        job: t("studio.founders.Grégoire.job"),
        description: t("studio.founders.Grégoire.description"),
      },
    ],
    [t]
  );

  const valuesData = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.studio.values.description).map((key) =>
            t("studio.values.description." + key)
          )
        : [],
    [t, emptyTrad]
  );

  if (errorTemperament || errorPartners || errorCustomers)
    return (
      <ErrorAPI
        message={errorPartners + " " + errorTemperament + " " + errorCustomers}
      />
    );
  if (loadingTemperament || loadingPartners || loadingCustomers)
    return <CustomLoader />;

  return (
    <>
      <MainWrapper>
        <SEO
          title="Studio Ambiant - Tempérament, Valeurs, Partenaires, Presse"
          description="Tempérament, Valeurs, Partenaires, Press"
        />

        <SectionWrapper
          title={t("studio.temperament.title")}
          description={[t("studio.temperament.description")]}
        />
        <SectionWrapper
          title={t("studio.values.title")}
          description={valuesData}
        />
        {/* FOUNDERS */}
        <Box
          sx={{
            gap: { ...getResponsiveValue(4, 8), lg: 16 },
            flexDirection: getResponsiveValue("column", "row"),
            alignItems: getResponsiveValue("center", ""),
            py: getResponsiveValue(3, 0),
          }}
          display="flex"
          justifyContent="space-evenly"
        >
          {temperament && (
            <Box
              sx={{
                maxWidth: getResponsiveValue("500px", "100%"),
                mx: getResponsiveValue(-1, 0),
              }}
              flex={1}
            >
              <ImageFullScreen imageUrl={temperament.mainImgUri} />
            </Box>
          )}

          <Stack spacing={6} flex={1}>
            <Typography variant="h6">{t("studio.founders.title")}</Typography>
            {foundersData.map(({ name, job, description }) => (
              <Stack key={name + job} spacing={0}>
                <Typography variant="h2">{name}</Typography>
                <Stack spacing={1}>
                  <Typography fontWeight={600} variant="body2">
                    {job}
                  </Typography>
                  <Typography variant="body2">{description}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
        {/* partners */}
        <Stack pb={8}>
          <SectionWrapper
            title={t("studio.partners.title")}
            description={[t("studio.partners.description")]}
            footer={
              <Stack pt={4} direction="row" spacing={2}>
                {partners?.map((partner) => (
                  <Box key={partner._id} maxWidth="200px" flex={1}>
                    <LogoItem
                      key={partner._id}
                      logo={partner.logo}
                      articleLink={partner.articleLink}
                    />
                  </Box>
                ))}
              </Stack>
            }
          />
        </Stack>
        {/* CUSTOMERS */}
        {customers?.length && (
          <Stack pb={8}>
            <SectionWrapper
              title={t("studio.customers.title")}
              description={[t("studio.customers.description")]}
              footer={
                <Stack pt={4} direction="row" spacing={2}>
                  {customers?.map((customer) => (
                    <Box key={customer._id} maxWidth="200px" flex={1}>
                      <LogoItem
                        key={customer._id}
                        logo={customer.logo}
                        articleLink={customer.articleLink}
                      />
                    </Box>
                  ))}
                </Stack>
              }
            />
          </Stack>
        )}
      </MainWrapper>
    </>
  );
};
export default Studio;
