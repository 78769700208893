import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

interface Props {
  file: any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const RenderPdf: React.FC<Props> = ({ file }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Box height={"100%"} overflow="auto">
      <Box mb="20px" display="flex" justifyContent="center">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Page width={380} pageNumber={pageNumber} />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Page pageNumber={pageNumber} />
          </Box>
        </Document>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          w: "200px",
          mx: "auto",
        }}
      >
        {pageNumber !== 1 ? (
          <IconButton
            sx={{ mb: 3 }}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            {"<"}
          </IconButton>
        ) : (
          <Box />
        )}

        <Typography>
          Page {pageNumber} sur {numPages}
        </Typography>
        {pageNumber !== numPages ? (
          <IconButton
            sx={{ mb: 3 }}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            {">"}
          </IconButton>
        ) : (
          <Box />
        )}
      </Box>
    </Box>
  );
};
