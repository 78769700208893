import { Box, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { DateState, ImgState } from "models/App";

import CustomModal from "ui/CustomModal";
import CustomImg from "ui/images/CustomImg";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomInput from "ui/form/CustomInput";
import { adress, description, link, title } from "utils/ticketing.utils";
import { TicketingForm } from "models/Ticketing";
import CustomDateHourPicker from "ui/form/CustomDateHourPicker";

type TicketingModalFormProps = {
  setIsModalFormOpen: Dispatch<SetStateAction<boolean>>;
  setImgState: Dispatch<SetStateAction<ImgState>>;
  setTicketingFormState: Dispatch<SetStateAction<TicketingForm>>;
  imgState: ImgState;
  setDateState: Dispatch<SetStateAction<DateState>>;
  dateState: DateState;
  onCloseModal: () => void;
  onSubmitTicketing: () => void;
  isModalFormOpen: boolean;
  isEditing: boolean;
  ticketingFormState: TicketingForm;
};

const TicketingModalForm: FC<TicketingModalFormProps> = ({
  setIsModalFormOpen,
  isModalFormOpen,
  isEditing,
  imgState,
  setImgState,
  setTicketingFormState,
  ticketingFormState,
  setDateState,
  onCloseModal,
  onSubmitTicketing,
  dateState,
}) => {
  const onInputsChange = (e: { target: { name: string; value: string } }) => {
    setTicketingFormState({
      ...ticketingFormState,
      [e.target.name]: {
        error: "",
        value: e.target.value,
      },
    });
  };
  return (
    <CustomModal
      header={isEditing ? "Modifier l' événement" : "Ajouter un événement"}
      open={isModalFormOpen}
      setOpen={setIsModalFormOpen}
      onValidate={onSubmitTicketing}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <>
          <Box sx={{ mb: 2 }}>
            {isEditing ? (
              <Box>
                {!imgState.file && (
                  <Box sx={{ mb: 1 }}>
                    <CustomImg
                      alt="event_image"
                      imgUri={ticketingFormState.imgUri}
                    />
                  </Box>
                )}
                <CustomInputUploadFile
                  label="Modifier l'image"
                  name="image"
                  file={imgState.file}
                  setFile={(file) => setImgState({ error: false, file })}
                />
              </Box>
            ) : (
              <CustomInputUploadFile
                name="image"
                file={imgState.file}
                setFile={(file) => setImgState({ error: false, file })}
                setError={(val) => setImgState({ ...imgState, error: val })}
                error={imgState.error as boolean}
              />
            )}
          </Box>
          <Box sx={{ mb: 2 }}>
            <CustomInput
              autoFocus
              fullwidth
              name={title}
              label="Titre"
              onChange={onInputsChange}
              error={ticketingFormState[title].error}
              value={ticketingFormState[title].value}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <CustomInput
              fullwidth
              name={description}
              label="Description"
              rows={5}
              onChange={onInputsChange}
              error={ticketingFormState[description].error}
              value={ticketingFormState[description].value}
            />
          </Box>
          <Box mb={2}>
            <CustomDateHourPicker
              value={dateState.date}
              onChange={(date) => {
                setDateState({ date: date as Date, error: false });
              }}
            />
            <Box sx={{ height: "16px" }}>
              {dateState.error && (
                <Typography color="error">{"Date et heure requis"}</Typography>
              )}
            </Box>
          </Box>
          <Box mb={1}>
            <CustomInput
              fullwidth
              name={adress}
              label="Adresse"
              onChange={onInputsChange}
              error={ticketingFormState[adress].error}
              value={ticketingFormState[adress].value}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <CustomInput
              fullwidth
              name={link}
              label="Lien"
              onChange={onInputsChange}
              error={ticketingFormState[link].error}
              value={ticketingFormState[link].value}
            />
          </Box>
        </>
      }
    />
  );
};

export default TicketingModalForm;
