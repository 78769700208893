import { Box, Typography } from "@mui/material";
import type { FC } from "react";

import React from "react";

type Props = { onClick: () => void; label: string };

const CustomRoundedButton: FC<Props> = ({ onClick, label }) => {
  return (
    <Box
      bgcolor="black"
      onClick={onClick}
      justifyContent="center"
      alignItems="center"
      component="span"
      px={2}
      py={1}
      borderRadius={8}
      display="inline-flex"
      sx={{
        "&:hover": {
          opacity: 0.7,
          cursor: "pointer",
        },
      }}
    >
      <Typography
        textAlign="center"
        variant="caption"
        textTransform="uppercase"
        color="white"
      >
        {label}
      </Typography>
    </Box>
  );
};

export default CustomRoundedButton;
