import { FC, useState } from "react";

import AddPartners from "./AddPartners";
import {
  PartnersFormState,
  PartnersPayloadToAPI,
  PartnersProps,
} from "models/Studio/Partners";
import API from "services/api";
import PartnersModalForm from "./PartnersModalForm";
import PartnersList from "./PartnersList";
import { PARTNERS_URL } from "services/endPoint";

import ErrorAPI from "components/ErrorAPI";
import CustomLoader from "ui/CustomLoader";
import { useStudioContext } from "context/StudioContext";

const initState = {
  _id: "",
  logo: "",
  file: undefined,
  error: false,
  articleLink: "",
};

const StudioPartners: FC = () => {
  const { partners, loadingPartners, errorPartners, setPartners } =
    useStudioContext();

  const [isPartnersFormModalOpen, setIsPartnersFormModalOpen] = useState(false);
  const [partnersFormState, setPartnersFormState] =
    useState<PartnersFormState>(initState);
  const isEditing = !!partnersFormState._id;

  const onClickOnUpdatePartners = async (partnersData: PartnersProps) => {
    setPartnersFormState({
      ...partnersFormState,
      _id: partnersData?._id,
      logo: partnersData?.logo,
      articleLink: partnersData?.articleLink,
    });
    setIsPartnersFormModalOpen(true);
  };

  // ! DELETE
  const onDeletePartners = async (_id: string) => {
    const resPartners = await API.delete({
      path: PARTNERS_URL + _id,
    });
    setPartners(resPartners.data);
  };

  const onResetState = () => {
    setIsPartnersFormModalOpen(false);
    setPartnersFormState(initState);
  };

  const onCloseModal = () => {
    onResetState();
  };

  const onSubmitPartners = async () => {
    const body: PartnersPayloadToAPI = {};
    body.articleLink = partnersFormState.articleLink;
    let resImg;
    if (partnersFormState.file !== undefined) {
      resImg = await API.uploadFile("image", partnersFormState.file);
      body.logo = resImg.path;
    }
    let resPartners;
    if (isEditing) {
      resPartners = await API.put({
        path: PARTNERS_URL + partnersFormState._id,
        body,
      });
    } else {
      resPartners = await API.post({
        path: PARTNERS_URL,
        body,
      });
    }
    if (resPartners.ok) {
      setPartners(resPartners.data);
      onResetState();
    } else {
      // TODO TRAIT ERROR
      console.log("error");
    }
  };

  if (errorPartners)
    return <ErrorAPI minHeight="72vh" message={errorPartners} />;
  if (loadingPartners) return <CustomLoader />;

  return (
    <>
      <AddPartners setIsPartnersFormModalOpen={setIsPartnersFormModalOpen} />
      <PartnersList
        partners={partners}
        onClickOnUpdatePartners={onClickOnUpdatePartners}
        onDeletePartners={onDeletePartners}
      />
      <PartnersModalForm
        setPartnersFormState={setPartnersFormState}
        setIsPartnersFormModalOpen={setIsPartnersFormModalOpen}
        onSubmitPartners={onSubmitPartners}
        onCloseModal={onCloseModal}
        partnersFormState={partnersFormState}
        isPartnersFormModalOpen={isPartnersFormModalOpen}
      />
    </>
  );
};
export default StudioPartners;
