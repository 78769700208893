import { Box } from "@mui/material";
import React, { FC } from "react";
import { getImgUrl, getResponsiveValue } from "utils/app.utils";

const IMG_SIZE_XS = 300;
const IMG_SIZE_MD = 400;

const imgSize = getResponsiveValue(IMG_SIZE_XS, IMG_SIZE_MD);

type Props = {
  images?: string[];
  onImageClick: (index: number) => void;
};

export const SessionImagesList: FC<Props> = ({ images, onImageClick }) => {
  return (
    <Box
      display="flex"
      sx={{
        overflowX: "auto",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
          // Chrome && Safari
          width: "0px",
          background: "transparent",
        },
        scrollbarWidth: "none", // Firefox
        msOverflowStyle: "none", // Internet Explorer, Edge
      }}
    >
      {images?.map((imgUrl, index) => (
        <Box
          onClick={() => onImageClick(index)}
          key={imgUrl + "_" + index}
          sx={{
            width: imgSize,
            height: imgSize,
            flexShrink: 0,
            cursor: "pointer",
          }}
        >
          <img
            src={getImgUrl(imgUrl)}
            alt={`session_${imgUrl}_${index + 1}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
