import { EpisodesProps } from "models/Episode";
import { useEffect, useState } from "react";
import API from "services/api";
import { GET_EPISODES_URL } from "services/endPoint";

const useEpisodes = () => {
  const [episodes, setEpisodes] = useState<EpisodesProps[]>();
  const [error, setError] = useState("");
  const [isLoading, setIsloading] = useState(false);
  // TODO use useGetRequest hook to handle this
  // const { data: episodes, error, isLoading } = useGetRequest<EpisodesProps[]>({
  //   path: GET_EPISODES_URL,
  // });
  const getEpisodes = async () => {
    setIsloading(true);
    try {
      const response = await API.get({ path: GET_EPISODES_URL });

      if (response.ok) {
        setEpisodes(response.data);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getEpisodes();
  }, []);

  return { episodes, error, setEpisodes, isLoading };
};

export default useEpisodes;
