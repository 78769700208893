// PAGE 404

import { Box, Typography } from "@mui/material";
import png404 from "assets/imgs/404.png";
import { useNavigate } from "react-router-dom";

const NoMatch = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: 20,
          letterSpacing: "3px",
        }}
        fontFamily={"FenomenSans-Thin"}
        mb={1}
      >
        Page non trouvé
      </Typography>
      <Box mb={1}>
        <img
          style={{
            height: "200px",
            width: "auto",
          }}
          crossOrigin="anonymous"
          src={png404}
          alt={"img_404"}
          loading="lazy"
        />
      </Box>

      <Box onClick={() => navigate("/")}>
        <Typography
          sx={{ textDecoration: "underline", "&:hover": { cursor: "pointer" } }}
          variant="subtitle2"
        >
          Retour à la page d'accueil
        </Typography>
      </Box>
    </Box>
  );
};

export default NoMatch;
