import { Box, Typography } from "@mui/material";

import type { FC } from "react";
import { getResponsiveValue } from "utils/app.utils";

type Props = { phrase: string };

const PhraseWrapper: FC<Props> = ({ phrase }) => {
  return (
    <Box
      sx={{ pb: getResponsiveValue(8, 16), pt: 8 }}
      pb={16}
      pt={8}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography textAlign="center" variant="h3">
        {phrase}
      </Typography>
    </Box>
  );
};

export default PhraseWrapper;
