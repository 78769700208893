import { PressProps } from "models/Studio/Press";
import { useState } from "react";
import API from "services/api";
import { GET_PRESS_URL } from "services/endPoint";

const usePress = () => {
  const [press, setPress] = useState<PressProps[]>();
  const [error, setError] = useState("");
  const getPress = async () => {
    const response = await API.get({ path: GET_PRESS_URL });
    if (response.ok) {
      setPress(response.data);
    } else {
      setError(response.error);
    }
  };

  return { press, error, getPress, setPress };
};

export default usePress;
