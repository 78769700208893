import { FC } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import frLocale from "date-fns/locale/fr";

type CustomDateTimePickerProps = {
  value: Date | null;
  label?: string;
  onChange: (newValue: React.SetStateAction<Date | null>) => void;
};

const CustomDateTimePicker: FC<CustomDateTimePickerProps> = ({
  value,
  label = "Date et heure",
  onChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={(newValue: React.SetStateAction<Date | null>) => {
          onChange(newValue);
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField {...params} />
        )}
      />
    </LocalizationProvider>
  );
};
export default CustomDateTimePicker;
