import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getImgUrl } from "utils/app.utils";
import "./CustomImg.css";

SwiperCore.use([Navigation, Pagination]);

type Props = {
  images: string[];
  initialSlide?: number;
  onClose: () => void;
};

const CustomFullScreenImgSlider = ({
  images,
  initialSlide = 0,
  onClose,
}: Props) => {
  const slides = images.map((imageUri, index) => {
    return {
      id: index,
      image: getImgUrl(imageUri),
      alt: `slide_${index + 1}`,
    };
  });

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(0,0,0,0.9)"
      zIndex={1300}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box position="absolute" zIndex={1301} top={0} right={0} m={2}>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      </Box>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        initialSlide={initialSlide}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <Box
              height="100vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={slide.image}
                alt={slide.alt}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CustomFullScreenImgSlider;
