import { Box } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { APP_HOME_PATH } from "router/constants";
import { useConfigContext } from "context/ConfigContext";

import { getImgUrl } from "utils/app.utils";

type SizesScall = {
  // lg: number;
  md: number;
  sm: number;
};

const sizes: SizesScall = {
  // lg: 300,
  md: 200,
  sm: 150,
};

type StudioAmbiantLogoProps = {
  size?: keyof SizesScall;
};

const StudioAmbiantLogo: FC<StudioAmbiantLogoProps> = ({ size = "md" }) => {
  const navigate = useNavigate();
  const { config } = useConfigContext();
  if (!config) return <Box />;

  return (
    <Box
      sx={{ ":hover": { cursor: "pointer", opacity: 0.7 } }}
      onClick={() => navigate(APP_HOME_PATH)}
    >
      <img
        style={{
          height: "auto",
          width: sizes[size],
        }}
        crossOrigin="anonymous"
        src={getImgUrl(config.logo)}
        alt="logo"
        loading="lazy"
      />
    </Box>
  );
};

export default StudioAmbiantLogo;
