import { Box } from "@mui/material";
import { ReactElement } from "react";

type HeaderPageWrapperProps = {
  children: ReactElement;
};

const HeaderPageWrapper = ({ children }: HeaderPageWrapperProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: 1,
        alignItems: "center",
        bgcolor: "#BDBDBD",
        borderRadius: 8,
        py: 1,
      }}
    >
      {children}
    </Box>
  );
};
export default HeaderPageWrapper;
