import { ConsultingProps } from "models/Consulting";
import { useEffect, useState } from "react";
import API from "services/api";
import { GET_CONSULTINGS_URL } from "services/endPoint";

const useConsultings = () => {
  const [consultings, setConsultings] = useState<ConsultingProps[]>();
  const [error, setError] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const getConsultings = async () => {
    setIsloading(true);
    try {
      const response = await API.get({ path: GET_CONSULTINGS_URL });

      if (response.ok) {
        setConsultings(response.data);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getConsultings();
  }, []);

  return { consultings, error, setConsultings, isLoading };
};

export default useConsultings;
