import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { getImgUrl } from "utils/app.utils";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const Root = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.8)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1200,
});

const ImageFullScreenTsx = styled("img")({
  width: "100%",
  height: "auto",
  objectFit: "contain",
});

interface ImageFullScreenProps {
  imageUrl: string;
}

const ImageFullScreen: React.FC<ImageFullScreenProps> = ({ imageUrl }) => {
  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleWheel = () => {
    if (fullScreen) {
      toggleFullScreen();
    }
  };

  return (
    <Root>
      {fullScreen && (
        <Overlay onScroll={handleWheel} onClick={toggleFullScreen}>
          <IconButton
            onClick={toggleFullScreen}
            style={{
              position: "absolute",
              bottom: 8,
              right: "50%",
              left: "50%",
            }}
          >
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Overlay>
      )}
      <ImageFullScreenTsx
        src={getImgUrl(imageUrl)}
        onClick={toggleFullScreen}
        onWheel={handleWheel}
        alt={"iamge_" + imageUrl}
        style={{
          maxWidth: fullScreen ? "100vw" : "100%",
          maxHeight: fullScreen ? "100vh" : "auto",
          position: fullScreen ? "fixed" : "static", // fixe la position de l'image en plein écran
          top: fullScreen ? 0 : "auto", // centre l'image en plein écran
          left: fullScreen ? 0 : "auto", // centre l'image en plein écran
          zIndex: fullScreen ? 1201 : undefined,
          cursor: fullScreen ? "zoom-out" : "zoom-in",
        }}
      />
    </Root>
  );
};

export default ImageFullScreen;
