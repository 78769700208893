import { Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomImg from "../images/CustomImg";
import CustomButtonUploadFile from "./CustomButtonUploadFile";
import { maxFileSize } from "utils/app.utils";

const imgSize = "200px";

const useStyles = makeStyles({
  imageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 4,
    position: "relative",
    padding: 8,
    marginTop: "8px",
  },

  iconTrash: {
    "&:hover": {
      cursor: "pointer",
      color: "red",
    },
    color: "#757575",
  },
});

type CustomInputUpdloadFileProps = {
  name: string;
  label?: string;
  file?: File;
  setFile: (file: File | undefined) => void;
  setError?: (val: boolean) => void;
  error?: boolean;
};

const CustomInputUploadFile: FC<CustomInputUpdloadFileProps> = ({
  name,
  label = "Importer une photo",
  file,
  setFile,
  setError,
  error,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onInputChange = async (e: any) => {
    if (setError) setError(false);
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > maxFileSize) {
      setErrorMessage("L'image selectionnée est trop large, max. 5 MB.");
      e.target.value = "";
    } else {
      setErrorMessage(null);
      setFile(selectedFile);
    }
  };

  return (
    <Stack spacing={1}>
      <label htmlFor={name}>
        <input
          accept="image/jpeg, image/png"
          style={{ display: "none" }}
          onChange={onInputChange}
          id={name}
          name={name}
          type="file"
        />

        <CustomButtonUploadFile label={label} />
      </label>
      <div
        style={{ border: `1px solid ${error ? "#DD5C5D" : "#C4C4C4"}` }}
        className={classes.imageWrapper}
      >
        {file ? (
          <>
            <CustomImg
              preview={URL.createObjectURL(file)}
              alt={"preview_img_inputFIle"}
              size="md"
            />
            <div
              onClick={() => setFile(undefined)}
              className="cursor-pointer hover:text-red-500"
            >
              <DeleteIcon className={classes.iconTrash} />
            </div>
          </>
        ) : (
          <Typography color={error ? "error" : ""} sx={{ height: imgSize }}>
            Aucune image ...
          </Typography>
        )}
      </div>
      {errorMessage && (
        <Typography color="error" variant="caption">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};
export default CustomInputUploadFile;
