import { stringToUrl } from "./../utils/app.utils";
// *** ADMIN
export const LOGIN_PATH = "/login";

export const ADMIN_PATH = "/admin"; // base path && serve Application screen
export const STUDIO_PATH = ADMIN_PATH + "/studio";
export const CONSULTING_PATH = ADMIN_PATH + "/consulting";

export const EPISODES_PATH = ADMIN_PATH + "/episodes";
export const TICKETING_PATH = ADMIN_PATH + "/ticketing";

// *** MAIN APP
export const APP_HOME_PATH = "/";
export const APP_EPISODES_PATH = "/episodes";

export const APP_CONSULTING_PATH = "/consulting";
export const APP_STUDIO_PATH = "/studio";
export const APP_TICKETING_PATH = "/ticketing";

export const APP_PRESS_PATH = "/press";
export const APP_TEMPERAMENT_PATH = "/temperament";
export const APP_PARTNERS_PATH = "/partners";
export const APP_CONTACT_PATH = "/nous-contacter";
export const APP_LEGAL_PATH = "/mentions-legales";
export const APP_CGU_PATH = "/conditions-generales-utilisation";

export const getEpisodePath = (episodeTitle: string) => {
  return APP_EPISODES_PATH + "/" + stringToUrl(episodeTitle);
};

export const getConsultingPath = (consultingTitle: string) => {
  return APP_CONSULTING_PATH + "/" + stringToUrl(consultingTitle);
};
export const getTicketingByIdPath = (ticketingId: string) => {
  return APP_TICKETING_PATH + "/" + ticketingId;
};

// TITLE
