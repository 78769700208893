import { Box } from "@mui/material";
import { PartnersFormState } from "models/Studio/Partners";
import { Dispatch, SetStateAction } from "react";
import CustomModal from "ui/CustomModal";
import CustomInput from "ui/form/CustomInput";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomImg from "ui/images/CustomImg";

type PartnersModalFormProps = {
  setPartnersFormState: Dispatch<SetStateAction<PartnersFormState>>;
  setIsPartnersFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmitPartners: () => void;
  onCloseModal: () => void;
  partnersFormState: PartnersFormState;
  isPartnersFormModalOpen: boolean;
};
const PartnersModalForm = ({
  setPartnersFormState,
  setIsPartnersFormModalOpen,
  onSubmitPartners,
  onCloseModal,
  partnersFormState,
  isPartnersFormModalOpen,
}: PartnersModalFormProps) => {
  const isEditing = !!partnersFormState._id;
  const onInputsChange = (e: { target: { name: string; value: string } }) => {
    setPartnersFormState({
      ...partnersFormState,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CustomModal
      header={isEditing ? "Modifier le partenaire" : "Ajouter un partenaire"}
      open={isPartnersFormModalOpen}
      setOpen={setIsPartnersFormModalOpen}
      onValidate={onSubmitPartners}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <>
          <Box sx={{ mb: 1 }}>
            {isEditing ? (
              <Box>
                {!partnersFormState.file && (
                  <Box sx={{ mb: 1 }}>
                    <CustomImg
                      alt="partners_image"
                      imgUri={partnersFormState.logo}
                    />
                  </Box>
                )}
                <CustomInputUploadFile
                  label="Modifier l'image"
                  name="image"
                  file={partnersFormState.file}
                  setFile={(file) =>
                    setPartnersFormState({ ...partnersFormState, file })
                  }
                />
              </Box>
            ) : (
              <CustomInputUploadFile
                name="image"
                file={partnersFormState.file}
                setFile={(file) =>
                  setPartnersFormState({ ...partnersFormState, file })
                }
                error={partnersFormState.error as boolean}
              />
            )}
          </Box>
          <Box>
            <CustomInput
              fullwidth
              name={"articleLink"}
              label="Lien du partenaire"
              onChange={onInputsChange}
              // error={partnersFormState["articleLink"].error}
              value={partnersFormState["articleLink"]}
            />
          </Box>
        </>
      }
    />
  );
};
export default PartnersModalForm;
