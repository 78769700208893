import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  CardActionArea,
  Box,
} from "@mui/material";
import useDate from "hooks/useDate";
import { getLocalizedString } from "use18n";
import { ConsultingProps } from "models/Consulting";
import { EpisodesProps } from "models/Episode";
import type { FC } from "react";

import React from "react";
import { getImgUrl } from "utils/app.utils";

type Props = {
  item: EpisodesProps | ConsultingProps;
  onClick?: () => void;
};

/**
 * A custom card media component that displays information about an episode or consulting item.
 * @param {EpisodesProps | ConsultingProps} props - The props object.
 * @param {EpisodesProps|ConsultingProps} props.item - Required - The episode or consulting item to display.
 * @param {Function} [props.onClick] - Optional - The function to execute when the card is clicked.
 * @returns {JSX.Element} The rendered custom card media component.
 */

const CustomCardMedia: FC<Props> = ({
  item: { mainImgUri, subtitle2, date, title, subtitle, city },
  onClick,
}) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        borderRadius: 0,
        boxShadow: 0,
        margin: "auto",
        cursor: onClick ? "pointer" : "default",
        pointerEvents: onClick ? "auto" : "none",
        "&:hover": {
          background: onClick ? "inherit" : "none",
        },
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="315"
          image={getImgUrl(mainImgUri)}
          alt={"episodeImg_" + title}
          crossOrigin="anonymous"
        />
        <CardContent sx={{ pl: 0 }}>
          <Stack spacing={2}>
            <Stack>
              <Typography
                textTransform="uppercase"
                variant="h5"
                component="div"
              >
                {getLocalizedString(title)}
              </Typography>

              <Typography color="gray" variant="caption">
                {getLocalizedString(subtitle)}
              </Typography>
            </Stack>

            <Stack
              divider={<Box>|</Box>}
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Typography variant="subtitle2">
                {getLocalizedString(subtitle2)}
              </Typography>
              <Typography variant="subtitle2" textTransform="capitalize">
                {useDate(date).abbreviatedMonthAndYear}
              </Typography>

              <Typography variant="subtitle2" textTransform="capitalize">
                {city}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CustomCardMedia;
