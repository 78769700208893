import {
  APP_CGU_PATH,
  APP_CONSULTING_PATH,
  APP_CONTACT_PATH,
  APP_EPISODES_PATH,
  APP_HOME_PATH,
  APP_LEGAL_PATH,
  APP_STUDIO_PATH,
  // APP_TICKETING_PATH,
} from "./constants";

import Episodes from "pages/MainApp/Episode";
import Home from "pages/MainApp/Home";

import Consulting from "pages/MainApp/Consulting";
import Studio from "pages/MainApp/Studio";
import Contact from "pages/MainApp/Contact";
import Legal from "pages/MainApp/Footer/Legal";
import CGU from "pages/MainApp/Footer/CGU";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
// import Ticketings from "pages/MainApp/Ticketings";

export type ScreenProps = {
  component: ReactNode;
  path: string;
  name?: string;
};

type FooterScreen = {
  name: string;
  component: ReactNode;
  index: number;
  path: string;
};

type MainAppRouter = {
  screens: ScreenProps[];
  footerScreens: FooterScreen[];
};

const useMainAppRouter = (): MainAppRouter => {
  const { t } = useTranslation();
  const screens = [
    {
      component: <Home />,
      path: APP_HOME_PATH,
      // name: t("footer.followUs"),
    },
    {
      component: <Episodes />,
      path: APP_EPISODES_PATH,
      name: t("header.episodes"),
    },
    {
      component: <Consulting />,
      path: APP_CONSULTING_PATH,
      name: t("header.consulting"),
    },
    {
      component: <Studio />,
      path: APP_STUDIO_PATH,
      name: t("header.studio"),
    },
    {
      component: <Contact />,
      path: APP_CONTACT_PATH,
      name: t("common.contact"),
    },
    // {
    //   component: <Ticketings />,
    //   index: 5,
    //   path: APP_TICKETING_PATH,
    //   name: "Billeterie",
    // },
  ];
  const footerScreens = [
    {
      name: t("footer.legalNotice"),
      component: <Legal />,
      index: 11,
      path: APP_LEGAL_PATH,
    },
    {
      name: t("footer.useTerms"),
      component: <CGU />,
      index: 12,
      path: APP_CGU_PATH,
    },
  ];

  return { screens, footerScreens };
};

export default useMainAppRouter;
