import { FC } from "react";
import { Box } from "@mui/material";
import { CustomersProps } from "models/Studio/Customers";
import CustomCard from "ui/CustomCard";
import CustomImg from "ui/images/CustomImg";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
type CustomersListProps = {
  customers?: CustomersProps[];
  onClickOnUpdateCustomers: (customer: CustomersProps) => void;
  onDeleteCustomers: (id: string) => void;
};
const CustomersList: FC<CustomersListProps> = ({
  customers,
  onClickOnUpdateCustomers,
  onDeleteCustomers,
}) => {
  return (
    <CustomCard>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {customers?.map((customer) => {
          return (
            <Box key={customer._id} sx={{ position: "relative", mr: 1, my: 1 }}>
              <CustomImg
                actionIcon={[
                  {
                    index: 12000,
                    action: () => onClickOnUpdateCustomers(customer),
                    icon: <BorderColorIcon />,
                    appBarActionHoverColor: "green",
                  },
                  {
                    index: 21001,
                    action: () => onDeleteCustomers(customer._id),
                    icon: <DeleteIcon />,
                    appBarActionHoverColor: "red",
                  },
                ]}
                hasAppBar
                appBarSubtitle={customer.articleLink}
                size="lg"
                alt="bluana_img"
                imgUri={customer.logo}
              />
            </Box>
          );
        })}
      </Box>
    </CustomCard>
  );
};
export default CustomersList;
