import { Box, Stack, Typography, Switch } from "@mui/material";
import { appLanguages, Lng, LocalizedString } from "use18n";
import { ImgState, DateState, ImagesState } from "models/App";
import { ConsultingFormState } from "models/Consulting";

import { Dispatch, FC, SetStateAction, useCallback } from "react";
import CustomButton from "ui/CustomButton";
import CustomDivider from "ui/CustomDivider";
import CustomModal from "ui/CustomModal";
import CustomDatePicker from "ui/form/CustomDatePicker";
import CustomInput from "ui/form/CustomInput";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomInputUploadFiles from "ui/form/CustomInputUploadFiles";
import CustomImagesList from "ui/images/CustomImagesList";
import CustomImg from "ui/images/CustomImg";

import { consultingInputs } from "utils/consulting.utils";

type ConsultingModalFormProps = {
  setConsultingFormState: Dispatch<SetStateAction<ConsultingFormState>>;
  setMainImgState: Dispatch<SetStateAction<ImgState>>;
  setImagesState: Dispatch<SetStateAction<ImagesState>>;
  setDateState: Dispatch<SetStateAction<DateState>>;
  setIsConsultingFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmitConsulting: () => void;
  onCloseModal: () => void;
  consultingFormState: ConsultingFormState;
  mainImgState: ImgState;
  imagesState: ImagesState;
  dateState: DateState;
  isConsultingFormModalOpen: boolean;
  isLoading?: boolean;
};

const ConsultingModalForm: FC<ConsultingModalFormProps> = ({
  setConsultingFormState,
  setMainImgState,
  setImagesState,
  setDateState,
  setIsConsultingFormModalOpen,
  onSubmitConsulting,
  onCloseModal,
  consultingFormState,
  mainImgState,
  imagesState,
  dateState,
  isConsultingFormModalOpen,
  isLoading,
}) => {
  // TODO REFACTO WITH SAME ON EPISODE ADMIN PAGE
  const emptyLocalizedString: LocalizedString = appLanguages.reduce(
    (obj, language) => {
      obj[language] = "";
      return obj;
    },
    {} as LocalizedString
  );

  const isEditing = !!consultingFormState._id;
  const onInputsChange = useCallback(
    (e: { target: { name: string; value: string } }, language?: Lng) => {
      if (language) {
        setConsultingFormState({
          ...consultingFormState,
          [e.target.name]: {
            // @ts-ignore
            ...consultingFormState[e.target.name],
            [language]: {
              error: "",
              value: e.target.value,
            },
          },
        });
      } else {
        setConsultingFormState({
          ...consultingFormState,
          [e.target.name]: {
            error: "",
            value: e.target.value,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consultingFormState]
  );
  const onDescriptionChange = useCallback(
    (
      e: { target: { name: string; value: string } },
      language: Lng,
      index: number
    ) => {
      setConsultingFormState(() => {
        const updatedDescription = [...(consultingFormState.description ?? [])];
        updatedDescription[index][language] = e.target.value;
        return {
          ...consultingFormState,
          description: updatedDescription,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consultingFormState, setConsultingFormState]
  );

  const handleAddDescription = useCallback(() => {
    const newDescription =
      consultingFormState.description?.concat(emptyLocalizedString);

    setConsultingFormState({
      ...consultingFormState,
      description: newDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultingFormState]);

  const handleChangedIsPublished = useCallback(
    (_: any, checked: boolean) => {
      setConsultingFormState({
        ...consultingFormState,
        published: checked,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consultingFormState]
  );

  return (
    <CustomModal
      isLoading={isLoading}
      header={isEditing ? "Modifier l'Épisode" : "Ajouter un Épisode"}
      open={isConsultingFormModalOpen}
      setOpen={setIsConsultingFormModalOpen}
      onValidate={onSubmitConsulting}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <Stack divider={<CustomDivider />} spacing={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography
              sx={{ opacity: consultingFormState.published ? 0.2 : 1 }}
              variant="body1"
            >
              {"Non publié"}
            </Typography>
            <Switch
              checked={consultingFormState.published}
              onChange={handleChangedIsPublished}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography
              sx={{ opacity: !consultingFormState.published ? 0.2 : 1 }}
              variant="body1"
            >
              {"Publié"}
            </Typography>
          </Stack>
          {consultingInputs.map(({ lng, name, label, title }) =>
            lng ? (
              <Stack spacing={1} key={name}>
                <Typography variant="body1">{title}</Typography>
                {appLanguages.map((lng) => (
                  <CustomInput
                    key={`${name}_${lng}`}
                    fullwidth
                    autoFocus
                    name={name}
                    // @ts-ignore
                    error={consultingFormState[name][lng].error}
                    // @ts-ignore
                    value={consultingFormState[name][lng].value}
                    label={`${label} (${lng.toUpperCase()})`}
                    onChange={(e) => onInputsChange(e, lng)}
                  />
                ))}
              </Stack>
            ) : (
              <Stack spacing={1} key={name}>
                <Typography variant="body1">{title}</Typography>
                <CustomInput
                  fullwidth
                  autoFocus
                  name={name}
                  // @ts-ignore
                  error={consultingFormState[name].error}
                  // @ts-ignore
                  value={consultingFormState[name].value}
                  label={label}
                  onChange={(e) => onInputsChange(e)}
                />
              </Stack>
            )
          )}
          <Stack spacing={1}>
            <Typography variant="body1">{"Description"}</Typography>
            <Stack pb={1} spacing={2}>
              {consultingFormState.description?.map((descri, descriIndex) =>
                appLanguages.map((language) => (
                  <Box key={`${descriIndex}-${language}`}>
                    <CustomInput
                      fullwidth
                      rows={4}
                      autoFocus
                      name={`description-${descriIndex}`}
                      value={descri[language]}
                      label={`Description ${
                        descriIndex + 1
                      } (${language.toUpperCase()})`}
                      onChange={(e) =>
                        onDescriptionChange(e, language, descriIndex)
                      }
                    />
                  </Box>
                ))
              )}
            </Stack>
            <CustomButton
              title="ajouter un bloc de description"
              onClick={handleAddDescription}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">{"Date"}</Typography>
            <CustomDatePicker
              value={dateState.date}
              label="Date de l'épisode"
              onChange={(date) => {
                setDateState({ date: date as Date, error: false });
              }}
            />
            <Box sx={{ height: "16px" }}>
              {dateState.error && (
                <Typography color="error">{"Date requise"}</Typography>
              )}
            </Box>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">{"Image principale"}</Typography>
            {isEditing ? (
              <>
                {!mainImgState.file && (
                  <Box>
                    <CustomImg
                      alt="Econsulting_image"
                      imgUri={consultingFormState.mainImgUri}
                    />
                  </Box>
                )}
                <CustomInputUploadFile
                  label="Modifier l'image"
                  name="image"
                  file={mainImgState.file}
                  setFile={(file) => setMainImgState({ error: false, file })}
                />
              </>
            ) : (
              <CustomInputUploadFile
                label="Image principale"
                name="image"
                file={mainImgState.file}
                setFile={(file) => setMainImgState({ error: false, file })}
                setError={(val) =>
                  setMainImgState({ ...mainImgState, error: val })
                }
                error={mainImgState.error as boolean}
              />
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">{"Les autres images"}</Typography>
            {isEditing ? (
              <>
                {Object.keys(imagesState.files).length === 0 && (
                  <CustomImagesList images={consultingFormState.images} />
                )}
                <CustomInputUploadFiles
                  name="pictures"
                  files={imagesState.files}
                  setFiles={(files) =>
                    setImagesState({ ...imagesState, files })
                  }
                  label="Modifier les images"
                />
              </>
            ) : (
              <CustomInputUploadFiles
                name="pictures"
                files={imagesState.files}
                setFiles={(files) => setImagesState({ ...imagesState, files })}
                label="Images de l'Épisode"
                error={imagesState.error}
                setError={(val) =>
                  setImagesState({ ...imagesState, error: val })
                }
              />
            )}
          </Stack>
        </Stack>
      }
    />
  );
};
export default ConsultingModalForm;
