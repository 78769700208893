import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "router/constants";

export const useAuth = () => {
  return useContext(AuthContext);
};

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { user } = useAuth();

  if (user.isLoaded && !user.isLogged) {
    return <Navigate to={LOGIN_PATH} replace />;
  }

  return children;
}
