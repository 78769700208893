import { FC, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { minHeight, sessionLeftPanelWidth } from "constants/constants";

import useDisclosure from "hooks/useDisclosure";

import { getLocalizedString } from "use18n";
import { EpisodesProps } from "models/Episode";
import { ConsultingProps } from "models/Consulting";

import CustomDivider from "ui/CustomDivider";
import CustomFullScreenImgSlider from "ui/images/CustomFullScreenImgSlider";

import ListNavigation from "components/MainApp/ListNavigation";
import MainWrapper from "Container/MainWrapper";
import PhraseWrapper from "Container/PhraseWrapper";
import { COLORS } from "ui/theme";
import { getResponsiveValue } from "utils/app.utils";
import { SessionImagesList } from "./SessionImagesList";

type Props = {
  session: EpisodesProps | ConsultingProps;
  sessions: EpisodesProps[] | ConsultingProps[];
  text: { title: string; phrase: string };
  getPath: (val: string) => string;
};

/**
 * SessionDetails component that displays the details of an session.
 * @param {Props} props - Component props
 * @param {EpisodesProps} props.session - Episode object
 * @param {Array<EpisodesProps>} props.sessions - Array of sessions objects
 * @returns {JSX.Element} - SessionDetails component
 */

const SessionDetails: FC<Props> = ({
  session: { _id, description, images },
  sessions,
  text,
  getPath,
}) => {
  const [imgIndex, setImgIndex] = useState(0);

  const fullScreenSlides = useDisclosure();

  return (
    <>
      <Box
        sx={{
          background: COLORS.gray[100],
          minHeight: minHeight,
        }}
        display="flex"
        flexDirection="column"
      >
        <Stack p={3} direction={getResponsiveValue("column", "row")}>
          <Box mb={8} width={sessionLeftPanelWidth}>
            <Typography variant="h4">{text.title}</Typography>
          </Box>
          <Stack spacing={2} flex={1}>
            {description?.map((d, index) => (
              <Typography
                mb={index === 0 ? 2 : undefined}
                variant={index === 0 ? "h4" : "body1"}
                key={"description_" + getLocalizedString(d) + index}
              >
                {getLocalizedString(d)}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Box pb={4}>
          <SessionImagesList
            images={images}
            onImageClick={(index) => {
              fullScreenSlides.open();
              setImgIndex(index);
            }}
          />
        </Box>
      </Box>

      {sessions.length > 1 && (
        <Box bgcolor={COLORS.gray[100]}>
          <CustomDivider />
          <ListNavigation getPath={getPath} currentList={_id} list={sessions} />
        </Box>
      )}
      <Box mt={6} display={getResponsiveValue("none", "block")} />
      <MainWrapper>
        <PhraseWrapper phrase={text.phrase} />
      </MainWrapper>

      {images && fullScreenSlides.isOpen && (
        <CustomFullScreenImgSlider
          onClose={fullScreenSlides.close}
          images={images}
          initialSlide={imgIndex}
        />
      )}
    </>
  );
};

export default SessionDetails;
