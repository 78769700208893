import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { responsiveHeightAppBar } from "constants/constants";

type Props = {
  children: React.ReactNode;
};
const MainAppLayout: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <Box
      sx={{
        pt: responsiveHeightAppBar,
      }}
    >
      {children}
    </Box>
  );
};
export default MainAppLayout;
