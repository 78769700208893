import { API_URL } from "config";
import { appLanguages, Lng, LocalizedString } from "use18n";
import { FormInput, InputStruct, State } from "models/App";
export const DURATION_SPLASH_SCREEN = 1300;
export const initImgState = {
  file: undefined,
  error: false,
};

export const inputEmptyValue = {
  error: "",
  value: "",
};

export const initDateState = {
  date: null,
  error: false,
};

export const initImagesState = {
  files: {},
  error: false,
};

// TODO REFACTO WITH SAME ON EPISODE ADMIN PAGE
// export const emptyLocalizedString: LocalizedString = appLanguages.reduce(
//   (obj, language) => {
//     obj[language] = "";
//     return obj;
//   },
//   {} as LocalizedString
// );

export const validateEmail = (mail: string): boolean => {
  if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export function createInitialState(inputs: FormInput[]): State {
  const initialState: State = {};

  inputs.forEach((input) => {
    if (input.lng) {
      // todo refactor avec appLanguages
      initialState[input.name] = {
        fr: inputEmptyValue,
        en: inputEmptyValue,
      };
    } else {
      initialState[input.name] = inputEmptyValue;
    }
  });

  return initialState;
}

export const createInputStruct = (value?: string): InputStruct => {
  return {
    value: value ?? "",
    error: "",
  };
};

export const createLocalizedInputStruct = (
  localizedValue?: LocalizedString
): Record<Lng, InputStruct> => {
  return appLanguages.reduce((acc, lang) => {
    acc[lang] = createInputStruct(localizedValue?.[lang]);
    return acc;
  }, {} as Record<Lng, InputStruct>);
};

export const maxFileSize = 1024 * 1024 * 5; // Default 5 MB

export const stringToUrl = (str?: string) =>
  str
    ? str
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "")
    : "";

export const getImgUrl = (url?: string) => `${API_URL}/${url}`;

export const capitalizeFirstLetter = (str?: string): string =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

export const getResponsiveValue = <T extends string | number>(
  xsValue: T,
  mdValue: T
) => ({
  // hide some App bar elements on extra small screens
  xs: xsValue,
  // show all App bar elements as a flex container on medium screens and up
  md: mdValue,
});
