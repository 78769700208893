import { FC } from "react";

import { IconButton, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HeaderPageWrapper from "Container/HeaderPageWrapper";

const mainTitle = "Ajouter un Evenement";

type AddTicketingProps = {
  openModalForm: () => void;
};

const AddTicketing: FC<AddTicketingProps> = ({ openModalForm }) => {
  return (
    <HeaderPageWrapper>
      <>
        <Typography sx={{ marginRight: 1 }} variant="h6">
          {mainTitle}
        </Typography>
        <IconButton onClick={openModalForm}>
          <AddCircleIcon />
        </IconButton>
      </>
    </HeaderPageWrapper>
  );
};
export default AddTicketing;
