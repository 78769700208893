import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";

type InputProps = {
  label: string;
  onChange: (e: any) => void;
  type?: React.InputHTMLAttributes<unknown>["type"];
  error?: string;
  autoFocus?: boolean;
  fullwidth?: boolean;
  value?: string;
  name?: string;
  rows?: number;
};
const ERROR_BOX_HEIGHT = "16px";

const CustomInput: FC<InputProps> = ({
  label,
  onChange,
  type,
  autoFocus,
  fullwidth,
  error,
  name,
  value,
  rows,
}) => {
  return (
    <Box
      sx={{ pb: error === undefined || error ? undefined : ERROR_BOX_HEIGHT }}
    >
      <TextField
        fullWidth={fullwidth}
        autoFocus={autoFocus}
        type={type}
        onChange={onChange}
        label={label}
        variant="outlined"
        value={value}
        error={!!error}
        name={name}
        multiline
        minRows={rows}
        color="secondary"
      />
      {!!error && (
        <Box sx={{ height: ERROR_BOX_HEIGHT }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomInput;
