import { Stack } from "@mui/material";

import type { FC, PropsWithChildren } from "react";

import CustomDivider from "ui/CustomDivider";
import { getResponsiveValue } from "utils/app.utils";

const MainWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Stack
      spacing={getResponsiveValue(1, 8)}
      divider={<CustomDivider />}
      sx={{ maxWidth: "1000px", margin: "auto", px: getResponsiveValue(1, 2) }}
    >
      {children}
    </Stack>
  );
};

export default MainWrapper;
