import { useState } from "react";

interface Disclosure {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}
interface DisclosureParams {
  initialState?: boolean;
}

/**
 * @typedef {Disclosure} Disclosure
 * @property {boolean} isOpen - The current open state of the component using the hook. `true` if the component is open, `false` otherwise.
 * @property {() => void} open - A function that sets the open state to `true`. Use this to open the component controlled by the hook.
 * @property {() => void} close - A function that sets the open state to `false`. Use this to close the component controlled by the hook.
 * @property {() => void} toggle - A function that toggles the open state between `true` and `false`. Use this to switch the component controlled by the hook between open and closed states.
 */

/**
 * @typedef {DisclosureParams} DisclosureParams
 * @property {boolean} [initialState] - The initial open state of the component using the hook. Defaults to false.
 */

/**
 * useDisclosure is a custom hook to handle the open/close state of components like modals or dialogs.
 *
 * @param {DisclosureParams} [params] - Optional configuration object for the useDisclosure hook.
 * @returns {Disclosure} An object containing:
 * - isOpen: The current open state of the component (boolean).
 * - open(): A function to set the open state to true.
 * - close(): A function to set the open state to false.
 * - toggle(): A function to toggle the open state between true and false.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import CustomModal from './CustomModal';
 * import { Button } from '@mui/material';
 * import useDisclosure from './useDisclosure';
 *
 * const App: React.FC = () => {
 *   const modalDisclosure = useDisclosure();
 *
 *   const handleConfirm = () => {
 *     console.log('Confirmed action');
 *     modalDisclosure.close();
 *   };
 *
 *   return (
 *     <div>
 *       <Button onClick={modalDisclosure.open}>Open Modal</Button>
 *       <CustomModal
 *         open={modalDisclosure.isOpen}
 *         setOpen={modalDisclosure.toggle}
 *         onConfirm={handleConfirm}
 *       />
 *     </div>
 *   );
 * };
 *
 * export default App;
 * ```
 */

const useDisclosure = (params?: DisclosureParams): Disclosure => {
  const [isOpen, setIsOpen] = useState(params?.initialState ?? false);

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen((prevIsOpen) => !prevIsOpen),
  };
};

export default useDisclosure;
