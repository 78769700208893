import { FC } from "react";
import { Grid, Typography } from "@mui/material";

import CustomLoader from "ui/CustomLoader";

import ErrorAPI from "components/ErrorAPI";
import CustomCardMedia from "ui/CustomCardMedia";
import { useNavigate } from "react-router-dom";
import { EpisodesProps } from "models/Episode";
import { ConsultingProps } from "models/Consulting";

type ListProps = {
  sliceCount?: number;
  data?: EpisodesProps[] | ConsultingProps[];
  isLoading?: boolean;
  errorApi?: string;
  getPath?: (val: string) => string;
};

/**
 * A generic list component that renders a list of items.
 *
 * @param {ListProps} props - The props object.
 * @param {EpisodesProps[]|ConsultingProps[]} props.data - Required - The list of items to render.
 * @param {number} [props.sliceCount] - Optional - The number of items to slice.
 * @param {boolean} [props.isLoading=false] - Optional - Whether the list is currently being loaded.
 * @param {string} [props.errorApi] - Optional - An error message to display if an API error occurs.
 * @param {(val: string) => string} [props.getPath] - Optional - A function that returns the path to navigate to for each item.
 * @returns {JSX.Element} The rendered list component.
 */

const SessionList: FC<ListProps> = ({
  sliceCount,
  data,
  isLoading,
  errorApi,
  getPath,
}) => {
  const navigate = useNavigate();

  if (errorApi) return <ErrorAPI message={errorApi} />;
  if (isLoading) return <CustomLoader />;
  if (!data) return null;

  const slicedData = sliceCount ? data.slice(0, sliceCount) : data;

  return (
    <Grid container spacing={1}>
      {slicedData.length ? (
        slicedData.map((data) => (
          <Grid key={data._id} item xs={12} sm={6} md={4}>
            <CustomCardMedia
              onClick={
                getPath ? () => navigate(getPath(data.title.fr)) : undefined
              }
              item={data}
            />
          </Grid>
        ))
      ) : (
        <Grid item>
          <Typography>Aucun élement...</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SessionList;
