import { FC } from "react";

import CustomLoader from "ui/CustomLoader";
import CustomCard from "ui/CustomCard";
import CustomImg from "ui/images/CustomImg";
import { TemperamentProps } from "models/Studio/Temperament";

type TemparementListProps = {
  temperament?: TemperamentProps;
};

const TemperamentList: FC<TemparementListProps> = ({ temperament }) => {
  if (!temperament) return <CustomLoader />;

  return (
    <CustomCard>
      <CustomImg alt="temperament_img" imgUri={temperament.mainImgUri} />
    </CustomCard>
  );
};

export default TemperamentList;
