import { FC } from "react";
import { Box } from "@mui/material";
import { PressProps } from "models/Studio/Press";
import CustomCard from "ui/CustomCard";
import CustomImg from "ui/images/CustomImg";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
type PressLisrtProps = {
  press?: PressProps[];
  onClickOnUpdatePress: (partner: PressProps) => void;
  onDeletePress: (id: string) => void;
};
const PressList: FC<PressLisrtProps> = ({
  press,
  onClickOnUpdatePress,
  onDeletePress,
}) => {
  return (
    <CustomCard>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {press?.map((partner) => {
          return (
            <Box key={partner._id} sx={{ position: "relative", mr: 1, my: 1 }}>
              <CustomImg
                actionIcon={[
                  {
                    index: 12000,
                    action: () => onClickOnUpdatePress(partner),
                    icon: <BorderColorIcon />,
                    appBarActionHoverColor: "green",
                  },
                  {
                    index: 21001,
                    action: () => onDeletePress(partner._id),
                    icon: <DeleteIcon />,
                    appBarActionHoverColor: "red",
                  },
                ]}
                appBarTitle={partner.articleLink}
                hasAppBar
                size="lg"
                alt="bluana_img"
                imgUri={partner.logo}
              />
            </Box>
          );
        })}
      </Box>
    </CustomCard>
  );
};
export default PressList;
