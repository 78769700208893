import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AppBar, Box, IconButton, Toolbar, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import useMainAppRouter from "router/useMainAppRouter";
import { APP_HOME_PATH } from "router/constants";

import useSocialNetwork from "hooks/useSocialNetwork";

import StudioAmbiantLogo from "components/Logo";

import { responsiveHeightAppBar } from "constants/constants";
import AppBarDrawer from "./AppBarDrawer";
import SocialNetworkIcon from "./SocialNetworkIcon";
import useDisclosure from "hooks/useDisclosure";
import { getResponsiveValue } from "utils/app.utils";
import LanguageSwitcher from "components/LanguageSwitcher";

// Defines the `App bar element` object with screen-size-specific values
const mainAppBarElementDisplay = getResponsiveValue("none", "flex");

const MainAppBar: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { screens } = useMainAppRouter();
  const { socialNetworks } = useSocialNetwork();
  const drawerDisclosure = useDisclosure();

  // Extract menu items from screens, excluding APP_HOME_PATH
  const menu = screens.filter(({ path }) => path !== APP_HOME_PATH);

  const handleDrawerToggle = drawerDisclosure.toggle;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          bgcolor: "white",
          height: responsiveHeightAppBar,
          display: "flex",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* *** Hamburger Icon *** */}
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: getResponsiveValue("block", "none"),
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* *** StudioAmbiantLogo *** */}
          <StudioAmbiantLogo />

          {/* *** Capitalized Menu *** */}
          <Box
            sx={{
              display: mainAppBarElementDisplay,
              width: "100%",
              justifyContent: "center",
            }}
          >
            {menu.map(({ name, path }) => (
              <Box
                key={name + "_" + path}
                sx={{
                  py: "17px",
                  borderBottom: pathname === path ? "2px solid black" : "",
                }}
              >
                <Button
                  sx={{
                    fontFamily: "Futura-Book",
                    textTransform: "uppercase",
                    color: "black",
                    fontWeight: 300,

                    fontSize: "15px",
                  }}
                  onClick={() => navigate(path)}
                >
                  {name}
                </Button>
              </Box>
            ))}
          </Box>

          {/* *** SocialNetworkIcon *** */}
          <Box gap={1} alignItems="center" display="flex">
            {socialNetworks.map(({ icon, url }) => (
              <SocialNetworkIcon key={url} url={url} icon={icon} />
            ))}
          </Box>
          {/* *** LanguageSwitcher *** */}
          <Box
            sx={{
              display: mainAppBarElementDisplay,
            }}
            ml={2}
            flex={1}
          >
            <LanguageSwitcher />
          </Box>
        </Toolbar>
      </AppBar>

      <AppBarDrawer
        isOpen={drawerDisclosure.isOpen}
        menu={menu}
        handleDrawerToggle={handleDrawerToggle}
      />
    </Box>
  );
};

export default MainAppBar;
// 240
