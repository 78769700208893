import { Box, Link, Stack, Typography } from "@mui/material";
import StudioAmbiantLogo from "components/Logo";

import useSocialNetwork from "hooks/useSocialNetwork";
import { getResponsiveValue } from "utils/app.utils";

import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import useMainAppRouter from "router/useMainAppRouter";

const handleMailTo = (mail: string) => {
  window.location.href = `mailto:${mail}`;
};

const Footer: FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { footerScreens } = useMainAppRouter();
  const { socialNetworks } = useSocialNetwork();

  const linkPressKit =
    process.env.PUBLIC_URL + i18n.language === "en"
      ? // ? "/assets/pdf/STUDIO_AMBIANT_PRESENTATION_EN_2023.pdf"
        "/assets/pdf/STUDIO_AMBIANT_DOSSIER_RP_2023_FR.pdf"
      : "/assets/pdf/STUDIO_AMBIANT_DOSSIER_RP_2023_FR.pdf";

  return (
    <Box
      display="flex"
      bgcolor="rgba(0, 0, 0, 0.03)"
      sx={{
        paddingY: getResponsiveValue(4, 10),
        paddingX: getResponsiveValue(1, 0),
        flexDirection: getResponsiveValue("column", "row"),
      }}
      justifyContent="space-evenly"
    >
      {/* *** StudioAmbiantLogo *** */}
      <Box display="flex" justifyContent="center" flex={1} alignSelf="center">
        <StudioAmbiantLogo />
      </Box>
      <Box
        flex={2}
        display="flex"
        sx={{
          // display Contact && SocialNetworks and CGU and MentionLegal in column for small device
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
        justifyContent="space-between"
      >
        {/* *** Contact *** */}
        <Stack flex={1} spacing={4}>
          <Box>
            <Typography variant="body2" fontWeight={900}>
              {t("common.contact")}
            </Typography>
            <Typography
              onClick={() => handleMailTo(t("contact.mail"))}
              sx={{
                ":hover": { cursor: "pointer", opacity: 0.7 },
              }}
              variant="body2"
            >
              {t("contact.mail")}
            </Typography>
            <Typography variant="body2">Paris, Île-de-France</Typography>
          </Box>
          {/* SOCIAL NETWORK */}
          <Stack>
            <Typography variant="body2" fontWeight={900}>
              {t("footer.followUs")}
            </Typography>
            {socialNetworks.map(({ title, url }) => (
              <Box
                onClick={() => window.open(url, "_blank")}
                key={"social_network_footer_" + title}
              >
                <Typography
                  color="black"
                  sx={{
                    ":hover": { cursor: "pointer", opacity: 0.7 },
                  }}
                  variant="body2"
                >
                  {title}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
        {/* ***  CGU and MentionLegal *** */}
        <Stack mt={{ xs: 4, sm: 0 }} flex={1}>
          {footerScreens.map(({ name, path }) => {
            return (
              <Box
                onClick={() => navigate(path)}
                sx={{
                  ":hover": { cursor: "pointer", opacity: 0.7 },
                }}
                key={"footer_link_" + name}
              >
                <Typography variant="body2">{name}</Typography>
              </Box>
            );
          })}
          <Link
            sx={{
              ":hover": { cursor: "pointer", opacity: 0.7 },
              textDecoration: "none",
              color: "black",
            }}
            href={linkPressKit}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body2">{t("footer.pressKit")}</Typography>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
