import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { FC, useState } from "react";
import API from "services/api";
import CustomButton from "ui/CustomButton";

import CustomSnackbar from "ui/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { ADMIN_PATH } from "router/constants";
import { Box } from "@mui/material";
import { InputStruct } from "models/App";
import CustomCard from "ui/CustomCard";
import TextField from "@mui/material/TextField";
import StudioAmbiantLogo from "components/Logo";
import { LOGIN_URL } from "services/endPoint";
import { validateEmail } from "utils/app.utils";

const initInputState = { value: "", error: "" };

const Login: FC = () => {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState<InputStruct>(initInputState);
  const [password, setPassword] = useState<InputStruct>(initInputState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [errorAPI, setErrorAPI] = useState("");

  const onInputsChange = (e: { target: { name: string; value: string } }) => {
    const inputValue = e.target.value;
    if (e.target.name === "email") {
      setEmail({ value: inputValue, error: "" });
    } else {
      setPassword({ value: inputValue, error: "" });
    }
  };
  const handleCloseSnackbar = () => setIsSnackbarOpen(false);
  const onSubmit = () => {
    let error = false;

    if (email.value === "") {
      error = true;
      setEmail({ ...email, error: "Email requis" });
    }
    if (!validateEmail(email.value)) {
      error = true;
      setEmail({
        ...email,
        error: "Le format de votre e-mail n'est pas corect",
      });
    }
    if (password.value === "") {
      error = true;
      setPassword({ ...password, error: "Mot de passe requis" });
    }
    if (error) return;
    setIsLoading(true);
    const body = {
      email: email.value,
      password: password.value,
    };
    API.post({
      path: LOGIN_URL,
      body: { ...body },
    }).then((res) => {
      setIsLoading(false);
      if (res.ok) {
        setUser(res);
        navigate(ADMIN_PATH);
      } else {
        setIsSnackbarOpen(true);
        setErrorAPI(res.error);
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          px: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "40ch",
          margin: "auto",
        }}
      >
        <CustomCard>
          <Box>
            <Box textAlign="center" mb={4}>
              <StudioAmbiantLogo />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="outlined-email-input"
                fullWidth
                focused
                label="Email"
                type="email"
                name="email"
                onChange={onInputsChange}
                helperText={email.error !== "" ? email.error : " "}
                error={email.error !== ""}
                color="secondary"
              />
            </Box>
            <Box sx={{ mb: 3 }}>
              <TextField
                id="outlined-password-input"
                fullWidth
                label="Mot de passe"
                type="password"
                name="password"
                onChange={onInputsChange}
                helperText={password.error !== "" ? password.error : " "}
                error={password.error !== ""}
                color="secondary"
              />
            </Box>

            <CustomButton
              fullWidth
              type="submit"
              title="Connexion"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </CustomCard>
      </Box>
      <CustomSnackbar
        message={errorAPI}
        severity="error"
        open={isSnackbarOpen}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Login;
