import useCustomers from "hooks/useCustomers";
import usePartners from "hooks/usePartners";
import useTemperament from "hooks/useTemperament";
import { CustomersProps } from "models/Studio/Customers";
import { PartnersProps } from "models/Studio/Partners";
import { TemperamentProps } from "models/Studio/Temperament";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
} from "react";

type Context = {
  partners: PartnersProps[] | undefined;
  setPartners: Dispatch<SetStateAction<PartnersProps[] | undefined>>;
  loadingPartners: boolean;
  errorPartners: string;
  customers: CustomersProps[] | undefined;
  setCustomers: Dispatch<SetStateAction<CustomersProps[] | undefined>>;
  loadingCustomers: boolean;
  errorCustomers: string;
  temperament: TemperamentProps | undefined;
  setTemperament: Dispatch<SetStateAction<TemperamentProps | undefined>>;
  loadingTemperament: boolean;
  errorTemperament: string;
};

const StudioContext = createContext<Context>({} as Context);

export const useStudioContext = (): Context => useContext(StudioContext);

type Props = {
  children: React.ReactNode;
};

const StudioContextWrapper: FC<Props> = ({ children }) => {
  const {
    partners,
    setPartners,
    loading: loadingPartners,
    error: errorPartners,
  } = usePartners();

  const {
    customers,
    setCustomers,
    loading: loadingCustomers,
    error: errorCustomers,
  } = useCustomers();

  const {
    temperament,
    setTemperament,
    loading: loadingTemperament,
    error: errorTemperament,
  } = useTemperament();

  const contextValue = useMemo(
    () => ({
      partners,
      setPartners,
      loadingPartners,
      errorPartners,
      customers,
      setCustomers,
      loadingCustomers,
      errorCustomers,
      temperament,
      setTemperament,
      loadingTemperament,
      errorTemperament,
    }),
    [
      partners,
      setPartners,
      loadingPartners,
      errorPartners,
      customers,
      setCustomers,
      loadingCustomers,
      errorCustomers,
      temperament,
      setTemperament,
      loadingTemperament,
      errorTemperament,
    ]
  );

  return (
    <StudioContext.Provider value={contextValue}>
      {children}
    </StudioContext.Provider>
  );
};

export default StudioContextWrapper;
