import { Dispatch, FC, SetStateAction } from "react";
import { IconButton, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import HeaderPageWrapper from "Container/HeaderPageWrapper";

const maintTitle = "Ajouter un épisode";

type AddEpisodeProps = {
  setIsEpisodeFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onResetEpisodeState: () => void;
};

const AddEpisode: FC<AddEpisodeProps> = ({
  setIsEpisodeFormModalOpen,
  onResetEpisodeState,
}) => {
  return (
    <HeaderPageWrapper>
      <>
        <Typography sx={{ marginRight: 1 }} variant="h6">
          {maintTitle}
        </Typography>
        <IconButton
          onClick={() => {
            onResetEpisodeState();
            setIsEpisodeFormModalOpen(true);
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </>
    </HeaderPageWrapper>
  );
};
export default AddEpisode;
