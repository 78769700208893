import { Box, Stack, Switch, Typography } from "@mui/material";
import { appLanguages, Lng, LocalizedString } from "use18n";
import { ImgState, DateState, ImagesState } from "models/App";
import { EpisodeFormState } from "models/Episode";

import { Dispatch, FC, SetStateAction, useCallback } from "react";
import CustomButton from "ui/CustomButton";
import CustomDivider from "ui/CustomDivider";
import CustomModal from "ui/CustomModal";
import CustomDatePicker from "ui/form/CustomDatePicker";
import CustomInput from "ui/form/CustomInput";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomInputUploadFiles from "ui/form/CustomInputUploadFiles";
import CustomImagesList from "ui/images/CustomImagesList";
import CustomImg from "ui/images/CustomImg";

import { episodeInputs } from "utils/episode.utils";

type EpisodeModalFormProps = {
  setEpisodeFormState: Dispatch<SetStateAction<EpisodeFormState>>;
  setMainImgState: Dispatch<SetStateAction<ImgState>>;
  setImagesState: Dispatch<SetStateAction<ImagesState>>;
  setDateState: Dispatch<SetStateAction<DateState>>;
  setIsEpisodeFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmitEpisode: () => void;
  onCloseModal: () => void;
  episodeFormState: EpisodeFormState;
  mainImgState: ImgState;
  imagesState: ImagesState;
  dateState: DateState;
  isEpisodeFormModalOpen: boolean;
  isLoading?: boolean;
};

const EpisodeModalForm: FC<EpisodeModalFormProps> = ({
  setEpisodeFormState,
  setMainImgState,
  setImagesState,
  setDateState,
  setIsEpisodeFormModalOpen,
  onSubmitEpisode,
  onCloseModal,
  episodeFormState,
  mainImgState,
  imagesState,
  dateState,
  isEpisodeFormModalOpen,
  isLoading,
}) => {
  // TODO REFACTO WITH SAME ON EPISODE ADMIN PAGE
  const emptyLocalizedString: LocalizedString = appLanguages.reduce(
    (obj, language) => {
      obj[language] = "";
      return obj;
    },
    {} as LocalizedString
  );

  const isEditing = !!episodeFormState._id;
  const onInputsChange = useCallback(
    (e: { target: { name: string; value: string } }, language?: Lng) => {
      if (language) {
        setEpisodeFormState({
          ...episodeFormState,
          [e.target.name]: {
            // @ts-ignore
            ...episodeFormState[e.target.name],
            [language]: {
              error: "",
              value: e.target.value,
            },
          },
        });
      } else {
        setEpisodeFormState({
          ...episodeFormState,
          [e.target.name]: {
            error: "",
            value: e.target.value,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [episodeFormState]
  );
  const onDescriptionChange = useCallback(
    (
      e: { target: { name: string; value: string } },
      language: Lng,
      index: number
    ) => {
      setEpisodeFormState(() => {
        const updatedDescription = [...(episodeFormState.description ?? [])];
        updatedDescription[index][language] = e.target.value;
        return {
          ...episodeFormState,
          description: updatedDescription,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [episodeFormState, setEpisodeFormState]
  );

  const handleAddDescription = useCallback(() => {
    const newDescription =
      episodeFormState.description?.concat(emptyLocalizedString);

    setEpisodeFormState({
      ...episodeFormState,
      description: newDescription,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeFormState]);

  const handleChangedIsPublished = useCallback(
    (_: any, checked: boolean) => {
      setEpisodeFormState({
        ...episodeFormState,
        published: checked,
      });
      console.log({ checked });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [episodeFormState]
  );

  return (
    <CustomModal
      isLoading={isLoading}
      header={isEditing ? "Modifier l'Épisode" : "Ajouter un Épisode"}
      open={isEpisodeFormModalOpen}
      setOpen={setIsEpisodeFormModalOpen}
      onValidate={onSubmitEpisode}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <Stack divider={<CustomDivider />} spacing={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography
              sx={{ opacity: episodeFormState.published ? 0.2 : 1 }}
              variant="body1"
            >
              {"Non publié"}
            </Typography>
            <Switch
              checked={episodeFormState.published}
              onChange={handleChangedIsPublished}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography
              sx={{ opacity: !episodeFormState.published ? 0.2 : 1 }}
              variant="body1"
            >
              {"Publié"}
            </Typography>
          </Stack>
          {episodeInputs.map(({ lng, name, label, title }) =>
            lng ? (
              <Stack spacing={1} key={name}>
                <Typography variant="body1">{title}</Typography>
                {appLanguages.map((lng) => (
                  <CustomInput
                    key={`${name}_${lng}`}
                    fullwidth
                    autoFocus
                    name={name}
                    // @ts-ignore
                    error={episodeFormState[name][lng].error}
                    // @ts-ignore
                    value={episodeFormState[name][lng].value}
                    label={`${label} (${lng.toUpperCase()})`}
                    onChange={(e) => onInputsChange(e, lng)}
                  />
                ))}
              </Stack>
            ) : (
              <Stack spacing={1} key={name}>
                <Typography variant="body1">{title}</Typography>
                <CustomInput
                  fullwidth
                  autoFocus
                  name={name}
                  // @ts-ignore
                  error={episodeFormState[name].error}
                  // @ts-ignore
                  value={episodeFormState[name].value}
                  label={label}
                  onChange={(e) => onInputsChange(e)}
                />
              </Stack>
            )
          )}
          <Stack spacing={1}>
            <Typography variant="body1">{"Description"}</Typography>
            <Stack pb={1} spacing={2}>
              {episodeFormState.description?.map((descri, descriIndex) =>
                appLanguages.map((language) => (
                  <Box key={`${descriIndex}-${language}`}>
                    <CustomInput
                      fullwidth
                      rows={4}
                      autoFocus
                      name={`description-${descriIndex}`}
                      value={descri[language]}
                      label={`Description ${
                        descriIndex + 1
                      } (${language.toUpperCase()})`}
                      onChange={(e) =>
                        onDescriptionChange(e, language, descriIndex)
                      }
                    />
                  </Box>
                ))
              )}
            </Stack>
            <CustomButton
              title="ajouter un bloc de description"
              onClick={handleAddDescription}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">{"Date"}</Typography>
            <CustomDatePicker
              value={dateState.date}
              label="Date de l'épisode"
              onChange={(date) => {
                setDateState({ date: date as Date, error: false });
              }}
            />
            <Box sx={{ height: "16px" }}>
              {dateState.error && (
                <Typography color="error">{"Date requise"}</Typography>
              )}
            </Box>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">{"Image principale"}</Typography>
            {isEditing ? (
              <>
                {!mainImgState.file && (
                  <Box>
                    <CustomImg
                      alt="Eepisode_image"
                      imgUri={episodeFormState.mainImgUri}
                    />
                  </Box>
                )}
                <CustomInputUploadFile
                  label="Modifier l'image"
                  name="image"
                  file={mainImgState.file}
                  setFile={(file) => setMainImgState({ error: false, file })}
                />
              </>
            ) : (
              <CustomInputUploadFile
                label="Image principale"
                name="image"
                file={mainImgState.file}
                setFile={(file) => setMainImgState({ error: false, file })}
                setError={(val) =>
                  setMainImgState({ ...mainImgState, error: val })
                }
                error={mainImgState.error as boolean}
              />
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body1">{"Les autres images"}</Typography>
            {isEditing ? (
              <>
                {Object.keys(imagesState.files).length === 0 && (
                  <CustomImagesList images={episodeFormState.images} />
                )}
                <CustomInputUploadFiles
                  name="pictures"
                  files={imagesState.files}
                  setFiles={(files) =>
                    setImagesState({ ...imagesState, files })
                  }
                  label="Modifier les images"
                />
              </>
            ) : (
              <CustomInputUploadFiles
                name="pictures"
                files={imagesState.files}
                setFiles={(files) => setImagesState({ ...imagesState, files })}
                label="Images de l'Épisode"
                error={imagesState.error}
                setError={(val) =>
                  setImagesState({ ...imagesState, error: val })
                }
              />
            )}
          </Stack>
        </Stack>
      }
    />
  );
};
export default EpisodeModalForm;
