import { Box, Grid } from "@mui/material";
import ErrorAPI from "components/ErrorAPI";
import API from "services/api";

import useTicketings from "hooks/useTicketings";
import { useEffect, useState } from "react";
import CustomLoader from "ui/CustomLoader";

import TicketingList from "components/Admin/Ticketing/TicketingList";
import AddTicketing from "components/Admin/Ticketing/AddTicketing";
import TicketingModalForm from "components/Admin/Ticketing/TicketingModalForm";
import { DateState, ImgState } from "models/App";
import { initDateState, initImgState } from "utils/app.utils";
import {
  adress,
  description,
  initState,
  link,
  title,
} from "utils/ticketing.utils";
import { TICKETING_URL } from "services/endPoint";
import {
  TicketingForm,
  TicketingPayloadToAPI,
  TicketingProps,
} from "models/Ticketing";

const Ticketing = () => {
  const { error, getTicketings, ticketings, setTicketings } = useTicketings();

  const [ticketingFormState, setTicketingFormState] =
    useState<TicketingForm>(initState);
  const [imgState, setImgState] = useState<ImgState>(initImgState);
  const [dateState, setDateState] = useState<DateState>(initDateState);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const isEditing = !!ticketingFormState?._id;

  const onResetState = () => {
    setTicketingFormState(initState);
    setImgState(initImgState);
    setDateState(initDateState);
  };

  const onOpenModalForm = () => {
    onResetState();
    setIsModalFormOpen(true);
  };

  const onCloseModalForm = () => {
    onResetState();
    setIsModalFormOpen(false);
  };

  const onClickOnUpdateTicketing = async (ticketingData: TicketingProps) => {
    setTicketingFormState({
      title: { value: ticketingData.title, error: "" },
      link: { value: ticketingData.link, error: "" },
      adress: { value: ticketingData.adress, error: "" },
      description: {
        value: ticketingData.description || "",
        error: "",
      },
      _id: ticketingData._id,
      imgUri: ticketingData.imgUri,
    });
    setDateState({ date: ticketingData.date, error: false });
  };

  // *** CREATE UPDATE
  const onSubmitTicketing = async () => {
    let error = false;
    if (
      ticketingFormState[title].value === "" ||
      ticketingFormState[link].value === "" ||
      ticketingFormState[adress].value === ""
    ) {
      error = true;
      setTicketingFormState({
        ...ticketingFormState,
        [title]: {
          ...ticketingFormState[title],
          error: ticketingFormState[title].value === "" ? "Titre requis" : "",
        },
        [link]: {
          ...ticketingFormState[link],
          error: ticketingFormState[link].value === "" ? "Lien requis" : "",
        },
        [adress]: {
          ...ticketingFormState[adress],
          error:
            ticketingFormState[adress].value === "" ? "Adresse requise" : "",
        },
      });
    }

    if (dateState.date === null) {
      error = true;
      setDateState({ ...dateState, error: true });
    }

    if (imgState.file === undefined && !isEditing) {
      error = true;
      setImgState({ ...imgState, error: true });
    }
    if (error) return;

    const body: TicketingPayloadToAPI = {
      title: ticketingFormState[title].value,
      adress: ticketingFormState[adress].value,
      link: ticketingFormState[link].value,
      date: dateState.date as Date,
    };

    let resImg;
    if (imgState.file !== undefined) {
      resImg = await API.uploadFile("image", imgState.file);
      body.imgUri = resImg.path;
    }

    if (ticketingFormState[description].value !== "")
      body.description = ticketingFormState[description].value;
    let res;
    if (isEditing) {
      res = await API.put({
        path: TICKETING_URL + ticketingFormState._id,
        body,
      });
    } else {
      res = await API.post({
        path: TICKETING_URL,
        body,
      });
    }
    if (res.ok) {
      setTicketings(res.data);
      onCloseModalForm();
    } else {
      // TODO TRAIT ERROR
      console.log("error");
    }
  };

  // ! DELETE
  const onDeleteTicketing = async () => {
    const res = await API.delete({
      path: TICKETING_URL + ticketingFormState._id,
    });

    setTicketings(res.data);
  };

  useEffect(() => {
    getTicketings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ErrorAPI message={error} />;
  if (!ticketings) return <CustomLoader />;

  return (
    <Box>
      <AddTicketing openModalForm={onOpenModalForm} />
      <Grid container spacing={2}>
        <TicketingList
          onClickOnUpdateTicketing={onClickOnUpdateTicketing}
          ticketings={ticketings}
          onDeleteTicketing={onDeleteTicketing}
          onOpenEditModal={() => setIsModalFormOpen(true)}
        />
      </Grid>
      <TicketingModalForm
        setIsModalFormOpen={setIsModalFormOpen}
        isModalFormOpen={isModalFormOpen}
        isEditing={isEditing}
        imgState={imgState}
        setImgState={setImgState}
        ticketingFormState={ticketingFormState}
        setTicketingFormState={setTicketingFormState}
        setDateState={setDateState}
        dateState={dateState}
        onCloseModal={onCloseModalForm}
        onSubmitTicketing={onSubmitTicketing}
      />
    </Box>
  );
};

export default Ticketing;
