import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";

type TitleWithListProps = {
  title: string;
  items: ReadonlyArray<string>;
};

const TitleWithList: FC<TitleWithListProps> = ({ title, items }) => {
  return (
    <Stack>
      <Typography variant="h6">{title}</Typography>
      {items.map((item, index) => (
        <Typography key={item + "_" + index} variant="body2">
          {item}
        </Typography>
      ))}
    </Stack>
  );
};

export default TitleWithList;
