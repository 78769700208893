import { Box, IconButton, ImageListItemBar } from "@mui/material";

import { ReactElement, ReactNode } from "react";
import { getImgUrl } from "utils/app.utils";

type SizesScall = {
  lg: number;
  md: number;
  sm: number;
  xl: number;
  xs: number;
  xxs: number;
};
const sizes: SizesScall = {
  lg: 300,
  md: 200,
  sm: 150,
  xl: 400,
  xs: 100,
  xxs: 50,
};

type ActionIconsType = {
  icon: ReactElement;
  action: () => void;
  index: number;
  appBarActionHoverColor?: string;
};

type CustomImgProps = {
  alt: string;
  imgUri?: string;
  preview?: string;
  size?: keyof SizesScall;
  actionIcon?: ActionIconsType[];
  appBarTitle?: string;
  appBarSubtitle?: string;
  hasAppBar?: boolean;
  width?: "auto";
  chip?: ReactNode;
};

const CustomImg = ({
  alt,
  imgUri,
  size = "md",
  preview,
  hasAppBar,
  actionIcon,
  appBarSubtitle,
  appBarTitle,
  width,
  chip,
}: CustomImgProps) => {
  const imgSize = sizes[size];
  const widthWrapper = width !== undefined ? width : imgSize;
  return (
    <Box
      sx={{
        position: "relative",
        height: imgSize,
        width: widthWrapper,
        borderRadius: 8,
      }}
    >
      <img
        style={{
          height: imgSize,
          width: widthWrapper,
          borderRadius: 8,
        }}
        crossOrigin="anonymous"
        src={imgUri ? getImgUrl(imgUri) : preview}
        alt={alt}
        loading="lazy"
      />
      {chip && (
        <Box position="absolute" right={3} top={3}>
          {chip}
        </Box>
      )}
      {hasAppBar && (
        <ImageListItemBar
          title={appBarTitle}
          subtitle={appBarSubtitle}
          sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
          actionIcon={
            <Box>
              {actionIcon?.map((item) => {
                return (
                  <IconButton
                    key={item.index}
                    sx={{
                      color: "rgba(255, 255, 255, 0.54)",
                      "&:hover": { color: item.appBarActionHoverColor },
                    }}
                    onClick={item.action}
                  >
                    {item.icon}
                  </IconButton>
                );
              })}
            </Box>
          }
        />
      )}
    </Box>
  );
};
export default CustomImg;
