import { TicketingProps } from "models/Ticketing";
import { useState } from "react";
import API from "services/api";
import { GET_TICKETINGS_URL } from "services/endPoint";

const useTicketing = () => {
  const [ticketings, setTicketings] = useState<TicketingProps[]>();

  const [error, setError] = useState("");
  const getTicketings = async () => {
    const response = await API.get({ path: GET_TICKETINGS_URL });
    if (response.ok) {
      setTicketings(response.data);
    } else {
      setError(response.error);
    }
  };

  return { ticketings, error, getTicketings, setTicketings };
};

export default useTicketing;
