import { FC, useState } from "react";
import { Box } from "@mui/material";
import UpdateTemperament from "./UpdateTemperament";
import API from "services/api";
import TemperamentList from "./TemperamentList";
import TemperamentModalForm from "./TemperamentModalForm";
import {
  TemperamentFormState,
  TemperamentPayloadToAPI,
} from "models/Studio/Temperament";

import { initImgState } from "utils/app.utils";
import { ImgState } from "models/App";
import { TEMPERAMENT_URL } from "services/endPoint";
import { useStudioContext } from "context/StudioContext";

const StudioTemperament: FC = () => {
  const { temperament, setTemperament } = useStudioContext();

  const [isTemperamentFormModalOpen, setIsTemperamentFormModalOpen] =
    useState(false);
  const [temperamentFormState, setTemperamentFormState] =
    useState<TemperamentFormState>({
      _id: "",
      mainImgUri: "",
    });
  const [imgState, setImgState] = useState<ImgState>(initImgState);

  const onSubmitTemperament = async () => {
    const body: TemperamentPayloadToAPI = {};
    let resImg;
    if (imgState.file !== undefined) {
      resImg = await API.uploadFile("image", imgState.file);
      body.mainImgUri = resImg.path;
    }

    const resTemperament = await await API.put({
      path: TEMPERAMENT_URL + temperamentFormState._id,
      body,
    });
    if (resTemperament.ok) {
      setTemperament(resTemperament.data[0]);
      onCloseModal();
    } else {
      // TODO TRAIT ERROR
      console.log("error");
    }
  };
  const onCloseModal = () => {
    setIsTemperamentFormModalOpen(false);
  };
  const onClickOnUpdateTemperament = async () => {
    setTemperamentFormState({
      _id: temperament?._id,
      mainImgUri: temperament?.mainImgUri,
    });
    setIsTemperamentFormModalOpen(true);
  };

  return (
    <Box>
      <UpdateTemperament
        onClickOnUpdateTemperament={onClickOnUpdateTemperament}
      />
      <TemperamentList temperament={temperament} />
      <TemperamentModalForm
        imgState={imgState}
        setImgState={setImgState}
        isTemperamentFormModalOpen={isTemperamentFormModalOpen}
        setIsTemperamentFormModalOpen={setIsTemperamentFormModalOpen}
        onSubmitTemperament={onSubmitTemperament}
        onCloseModal={onCloseModal}
        temperamentFormState={temperamentFormState}
      />
    </Box>
  );
};
export default StudioTemperament;
