import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`studio-tabpanel-${index}`}
      aria-labelledby={`studio-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `studio-tab-${index}`,
    key: `studio-tab-${index}`,
    "aria-controls": `studio-tabpanel-${index}`,
  };
};

type TabPanelsType = {
  component: React.ReactElement;
};

type TabsType = {
  title: string;
};

type CustomTabPanelProps = {
  tabs: TabsType[];
  tabPanels: TabPanelsType[];
};

const CustomTabPanel: React.FC<CustomTabPanelProps> = ({ tabPanels, tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="CustomTabPanel"
        >
          {tabs.map((tab, index) => (
            <Tab
              sx={{ letterSpacing: "3px", fontWeight: 900 }}
              label={tab.title}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabPanels.map((panel, index) => (
        <TabPanel key={"tabPanel_" + index + panel} value={value} index={index}>
          {panel.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default CustomTabPanel;
