import { Box } from "@mui/material";
import sALogo from "assets/icons/studioAmbiant-logo.png";
import { FC, useEffect, useState } from "react";
const DURATION_EFFECT = 700;

const SplashScreen: FC = () => {
  const [transform, setTransform] = useState("translateY(0%)");

  useEffect(() => {
    const timer = setTimeout(() => {
      setTransform("translateY(-100%)");
    }, DURATION_EFFECT);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      minHeight={"100vh"}
      bgcolor="white"
      width="100vw"
      position="absolute"
      zIndex={1000}
      sx={{
        transition: "transform 2s",
        transform: transform,
      }}
    >
      <img
        style={{
          height: "auto",
          width: "100%",
        }}
        crossOrigin="anonymous"
        src={sALogo}
        alt="splash_screen_logo"
        loading="lazy"
      />
    </Box>
  );
};
export default SplashScreen;
