import { FC } from "react";
import { Helmet } from "react-helmet";

type SEOProps = {
  title: string;
  description?: string;
};
/**
 * SEO - Composant pour ajouter les méta-données et le titre d'une page pour le référencement.
 *
 * @component
 * @example
 * return (
 *   <SEO title="Page d'accueil" description="Description de la page d'accueil" />
 * );
 */

const SEO: FC<SEOProps> = ({ title, description }) => {
  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: "og:url",
          content: "https://studio-ambiant.com/",
        },
        {
          property: "og:type",
          content: "video.movie",
        },
        {
          property: "og:title",
          content: "Studio Ambiant",
        },
        {
          property: "og:description",
          content:
            "Studio de production d’événements créatifs ancré dans la gastronomie",
        },
        {
          property: "og:image",
          content:
            "https://ahrefs.com/blog/wp-content/uploads/2019/12/fb-how-to-become-an-seo-expert.png",
        },
        // {
        //   property: "fb:app_id",
        //   content: someFbAppId
        // }
      ]}
    />
  );
};
export default SEO;
