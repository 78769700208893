import { AppConfig } from "pages/Admin/Application";
import { useState } from "react";
import API from "services/api";
import { GET_CONFIG_URL } from "services/endPoint";

const useConfig = () => {
  const [config, setConfig] = useState<AppConfig>();
  const [error, setError] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const getConfig = async () => {
    setIsloading(true);
    try {
      const response = await API.get({ path: GET_CONFIG_URL });
      if (response.ok) {
        setConfig(response.data[0]);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsloading(false);
    }
  };

  return { config, error, getConfig, isLoading };
};

export default useConfig;
