import { CustomersProps } from "models/Studio/Customers";
import { useEffect, useState } from "react";
import API from "services/api";
import { GET_CUSTOMERS_URL } from "services/endPoint";

const useCustomers = () => {
  const [customers, setCustomers] = useState<CustomersProps[]>();
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const getCustomers = async () => {
    setIsLoading(true);
    try {
      const response = await API.get({ path: GET_CUSTOMERS_URL });
      if (response.ok) {
        setCustomers(response.data);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log("error in useCustomer", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return { customers, error, loading, setCustomers };
};

export default useCustomers;
