import { PartnersProps } from "models/Studio/Partners";
import { useEffect, useState } from "react";
import API from "services/api";
import { GET_PARTNERS_URL } from "services/endPoint";

const usePartners = () => {
  const [partners, setPartners] = useState<PartnersProps[]>();
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const getPartners = async () => {
    setIsLoading(true);
    try {
      const response = await API.get({ path: GET_PARTNERS_URL });
      if (response.ok) {
        setPartners(response.data);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log("error in usePartner", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  return { partners, error, loading, setPartners };
};

export default usePartners;
