import { FormInput } from "models/App";

export const title = "title";
export const description = "description";
export const subtitle = "subtitle";
export const subtitle2 = "subtitle2";
export const city = "city";

export const initConsultingFormState = {
  [description]: { value: "", error: "" },
  [title]: { value: "", error: "" },
  [city]: { value: "", error: "" },
  [subtitle]: { value: "", error: "" },
  [subtitle2]: { value: "", error: "" },
};

export const consultingInputs: FormInput[] = [
  {
    required: true,
    name: "title",
    lng: true,
    label: "Titre de la consultation",
    title: "Titre",
  },
  {
    required: false,
    name: "subtitle",
    lng: true,
    label: "Sous-titre de la consultation",
    title: "Sous-titre",
  },
  {
    required: false,
    name: "subtitle2",
    lng: true,
    label: "Sous-titre 2 de la consultation",
    title: "Sous-titre 2",
  },
  {
    required: true,
    name: "city",
    lng: false,
    label: "Lieu de la consultation",
    title: "Lieu",
  },
];
