import React, { useCallback } from "react";

import { Switch, styled, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb::before": {
        content: '"EN"',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#fff",
        fontFamily: theme.typography.fontFamily,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "black",
    width: 32,
    height: 32,
    "&::before": {
      content: '"FR"',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (event: { target: { checked: boolean } }) => {
      i18n.changeLanguage(event.target.checked ? "en" : "fr");
    },
    [i18n]
  );

  return (
    <FormControlLabel
      control={
        <MaterialUISwitch
          checked={i18n.language === "en" ? true : false}
          onChange={changeLanguage}
        />
      }
      label=""
    />
  );
};

export default LanguageSwitcher;
