/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState } from "react";

import CustomLoader from "ui/CustomLoader";
import { Box, Chip, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import CustomImg from "ui/images/CustomImg";
import CustomImagesList from "ui/images/CustomImagesList";
import useDate from "hooks/useDate";
import { ConsultingProps } from "models/Consulting";
import { getLocalizedString } from "use18n";
import CustomConfirmModal from "ui/CustomConfirmModal";
import CustomDivider from "ui/CustomDivider";
import CustomAlert from "ui/CustomAlert";

type ConsultingListProps = {
  consultings?: ConsultingProps[];
  onDeleteConsulting: (consultingId: string) => void;
  onClickOnUpdateConsulting: (consulting: ConsultingProps) => void;
};

const ConsultingList: FC<ConsultingListProps> = ({
  consultings,
  onDeleteConsulting,
  onClickOnUpdateConsulting,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteConsultingId, setDeleteConsultingId] = useState<string>();
  if (!consultings) return <CustomLoader />;

  return (
    <Stack divider={<CustomDivider />} spacing={2}>
      <CustomAlert severity="info">
        Les paragraphes de description, les sous-titres éventuelles, la date et
        le lieu se trouvent dans la modale lors de l'édition ou l'ajout d'un
        consulting
      </CustomAlert>
      {consultings.map((consulting) => {
        const { _id, title, images, mainImgUri, date, published } = consulting;
        return (
          <Box key={_id}>
            <Stack spacing={2}>
              <CustomImg
                alt="mainImg_consulting"
                imgUri={mainImgUri}
                size="lg"
                actionIcon={[
                  {
                    index: 12000,
                    action: () => onClickOnUpdateConsulting(consulting),
                    icon: <BorderColorIcon />,
                    appBarActionHoverColor: "green",
                  },
                  {
                    index: 12001,
                    action: () => {
                      setDeleteConsultingId(_id);
                      setIsDeleteModalOpen(true);
                    },
                    icon: <DeleteIcon />,
                    appBarActionHoverColor: "red",
                  },
                ]}
                hasAppBar
                appBarSubtitle={`Le ${useDate(date).dayMonthAndYear}`}
                appBarTitle={getLocalizedString(title)}
                chip={
                  <Chip
                    label={published ? "Publié" : "Non publié"}
                    color={published ? "success" : "warning"}
                  />
                }
              />
              <CustomImagesList images={images} />
            </Stack>
          </Box>
        );
      })}

      <CustomConfirmModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onConfirm={() => onDeleteConsulting(deleteConsultingId as string)}
      />
    </Stack>
  );
};
export default ConsultingList;
