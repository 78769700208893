import { FC, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Admin/Login";

import { RequireAuth } from "hooks/useAuth";
import ResponsiveAppBar from "components/Admin/AppBar";
import Layout from "Container/Layout";
import {
  getConsultingPath,
  getEpisodePath,
  // getTicketingByIdPath,
  LOGIN_PATH,
} from "router/constants";
import useAdminRouter from "router/useAdminRouter";
import useMainAppRouter from "router/useMainAppRouter";

import EpisodeDetails from "pages/MainApp/Episode/EpisodeDetails";
import MainAppWrapper from "Container/MainAppWrapper";

import NoMatch from "pages/NoMatch";

// import useTicketing from "hooks/useTicketings";
// import EventTicketing from "pages/MainApp/EventTicketing";
import ErrorAPI from "components/ErrorAPI";

import ConsultingDetails from "pages/MainApp/Consulting/ConsultingDetails";
import CustomLoader from "ui/CustomLoader";

import { useEpisodeContext } from "context/EpisodeContext";
import { useTranslationContext } from "context/TranslationContext";
import { useConsultingContext } from "context/ConsultingContext";

const App: FC = () => {
  const { screens: adminScreens } = useAdminRouter();
  const { screens: mainAppScreens, footerScreens } = useMainAppRouter();

  const { isLoading: isTraductionLoading, error: errorTraductions } =
    useTranslationContext();

  const {
    episodes,
    error: errorEpisodes,
    isLoading: isLoadingEpisode,
  } = useEpisodeContext();

  const {
    consultings,
    error: errorConsultings,
    isLoading: isLoadingConsultings,
  } = useConsultingContext();

  useEffect(() => {
    console.log(
      "%cWelcome to Studio Ambiant!",
      "color: lightblue; font-size: 20px; font-weight: bold;"
    );
    console.log(
      "%cDeveloppé avec 🖤 par Anthony de Cuyper",
      "color: lightblue"
    );
  }, []);

  const error =
    errorEpisodes !== "" || errorConsultings !== "" || errorTraductions !== "";
  const isFetching =
    isLoadingEpisode || isLoadingConsultings || isTraductionLoading;
  if (isFetching) return <CustomLoader />;
  if (error)
    return (
      <ErrorAPI
        message={errorEpisodes ?? errorConsultings ?? errorTraductions}
      />
    );

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* LOGIN ADMIN */}
          <Route path={LOGIN_PATH} element={<Login />} />

          {/* ADMIN */}
          {adminScreens.map(({ component, index, path }) => {
            return (
              <Route
                key={index}
                path={path}
                element={
                  <RequireAuth>
                    <>
                      <ResponsiveAppBar />
                      <Layout>{component}</Layout>
                    </>
                  </RequireAuth>
                }
              />
            );
          })}

          {/* MAIN APP */}
          {mainAppScreens.map(({ component, path }) => {
            return (
              <Route
                key={path}
                path={path}
                element={<MainAppWrapper>{component}</MainAppWrapper>}
              />
            );
          })}
          {episodes?.map((ep) => {
            return (
              <Route
                key={`route_episode_${ep._id}`}
                path={getEpisodePath(ep.title?.fr)}
                element={
                  <MainAppWrapper>
                    <EpisodeDetails episodes={episodes} episode={ep} />
                  </MainAppWrapper>
                }
              />
            );
          })}
          {consultings?.map((consulting) => {
            return (
              <Route
                key={`route_conulting_${consulting._id}`}
                path={getConsultingPath(consulting.title?.fr)}
                element={
                  <MainAppWrapper>
                    <ConsultingDetails
                      consultings={consultings}
                      consulting={consulting}
                    />
                  </MainAppWrapper>
                }
              />
            );
          })}
          {/* {ticketings?.map((tic) => {
                  return (
                    <Route
                      key={`route_episode_${tic._id}`}
                      path={getTicketingByIdPath(tic._id)}
                      element={
                        <MainAppWrapper>
                          <EventTicketing
                            title={tic.title}
                            date={tic.date}
                            iframeLink={tic.link}
                          />
                        </MainAppWrapper>
                      }
                    />
                  );
                })} */}

          {footerScreens.map(({ path, component, index }) => (
            <Route
              key={`route_footer_${index}`}
              path={path}
              element={<MainAppWrapper>{component}</MainAppWrapper>}
            />
          ))}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
