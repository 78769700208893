import { Box } from "@mui/material";
import { PressFormState } from "models/Studio/Press";
import { Dispatch, SetStateAction } from "react";
import CustomModal from "ui/CustomModal";
import CustomInput from "ui/form/CustomInput";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomImg from "ui/images/CustomImg";

type PressModalFormProps = {
  setPressFormState: Dispatch<SetStateAction<PressFormState>>;
  setIsPressFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmitPress: () => void;
  onCloseModal: () => void;
  pressFormState: PressFormState;
  isPressFormModalOpen: boolean;
};
const PressModalForm = ({
  setPressFormState,
  setIsPressFormModalOpen,
  onSubmitPress,
  onCloseModal,
  pressFormState,
  isPressFormModalOpen,
}: PressModalFormProps) => {
  const isEditing = !!pressFormState._id;

  const onInputChange = (e: { target: { name: string; value: string } }) => {
    setPressFormState({
      ...pressFormState,
      [e.target.name]: {
        error: "",
        value: e.target.value,
      },
    });
  };

  return (
    <CustomModal
      header={
        isEditing
          ? "Modifier cette article de presse"
          : "Ajouter un article de presse"
      }
      open={isPressFormModalOpen}
      setOpen={setIsPressFormModalOpen}
      onValidate={onSubmitPress}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <>
          <Box sx={{ mb: 2 }}>
            {isEditing ? (
              <Box>
                {!pressFormState.file && (
                  <Box sx={{ mb: 1 }}>
                    <CustomImg alt="press_image" imgUri={pressFormState.logo} />
                  </Box>
                )}
                <CustomInputUploadFile
                  label="Modifier l'image"
                  name="image"
                  file={pressFormState.file}
                  setFile={(file) =>
                    setPressFormState({ ...pressFormState, file })
                  }
                />
              </Box>
            ) : (
              <CustomInputUploadFile
                name="image"
                file={pressFormState.file}
                setFile={(file) =>
                  setPressFormState({ ...pressFormState, file })
                }
                error={pressFormState.error as boolean}
              />
            )}
          </Box>
          <Box sx={{ mb: 2 }}>
            <CustomInput
              autoFocus
              fullwidth
              name={"articleLink"}
              label="Lien de l'article"
              onChange={onInputChange}
              error={pressFormState?.articleLink?.error}
              value={pressFormState?.articleLink?.value}
            />
          </Box>
        </>
      }
    />
  );
};
export default PressModalForm;
