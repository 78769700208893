import { appLanguages, Lng } from "use18n";
import { useEffect, useState } from "react";
import API from "services/api";
import { GET_JSON } from "services/endPoint";

export interface Translation {
  common: {
    selectLanguage: string;
    studioAmbiant: string;
    contact: string;
    requiredField: string;
    submit: string;
  };
  homePage: {
    legendMainMedia: string;
    introduction: {
      text1: string;
      text2: string;
      text3: string;
    };
    aboutUs: string;
    episodes: {
      select: string;
      labelLink: string;
    };
    phrase: string;
  };
  footer: {
    legalNotice: string;
    useTerms: string;
    followUs: string;
    pressKit: string;
  };
  episode: {
    description: {
      text1: string;
      text2: string;
      text3: string;
    };
    title: string;
    phrase: string;
    interventionAreas: {
      title: string;
      list: {
        [key: string]: string;
      };
    };
    knowHow: {
      title: string;
      list: {
        [key: string]: string;
      };
    };
  };
  episodeDetail: {
    concept: string;
    phrase: string;
  };
  header: {
    studio: string;
    episodes: string;
    consulting: string;
  };
  studio: {
    temperament: {
      title: string;
      description: string;
    };
    values: {
      title: string;
      description: {
        text1: string;
        text2: string;
        text3: string;
      };
    };
    description: {
      text1: string;
      text2: string;
      text3: string;
    };
    founders: {
      title: string;
      [key: string]:
        | {
            name: string;
            job: string;
            description: string;
          }
        | string;
    };
    partners: {
      title: string;
      description: string;
    };
    customers: {
      title: string;
      description: string;
    };
  };
  consulting: {
    title: string;
    description: {
      text1: string;
      text2: string;
    };
    phrase: string;
    interventionAreas: {
      title: string;
      list: {
        item1: string;
        item2: string;
        item3: string;
      };
    };
    missions: {
      title: string;
      list: {
        [key: string]: string;
      };
    };
  };
  consultingDetail: {
    concept: string;
    phrase: string;
  };
  contact: {
    title: string;
    inputs: {
      formatEmailError: string;
    };
    lastName: string;
    firstName: string;
    oneQuestion: string;
    phone: string;
    directionMail: string;
    salesMail: string;
    marketingMail: string;
    jobMail: string;
  };
}

export type TraductionsAPI = {
  [key in Lng]?: Translation;
};

const useTraductions = () => {
  const [translations, setTranslations] = useState<TraductionsAPI>({});
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState("");

  const getTrad = async () => {
    setIsloading(true);
    try {
      const translationsApiResponse: TraductionsAPI = {};

      for (const lng of appLanguages) {
        const res = await API.get({ path: GET_JSON + lng });
        translationsApiResponse[lng] = res;
      }

      setTranslations(translationsApiResponse);
    } catch (error) {
      setError(
        "Une erreur est survenue lors de la récupération des fichiers de traductions"
      );
      console.log({ error });
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getTrad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, translations, error, emptyTrad: translations.fr };
};

export default useTraductions;
