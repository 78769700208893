// InputDragAndDropUploader.tsx
import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDrop, DndProvider, DropTargetMonitor } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";

export interface InputDragAndDropUploaderProps {
  onFileReceived: (file: File) => void;
  label: string;
}

const FileUploader: React.FC<InputDragAndDropUploaderProps> = ({
  onFileReceived,
  label,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [{ canDrop }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item: DropTargetMonitor & { files: FileList }, monitor) {
      const files = item.files;
      if (files.length > 0) {
        onFileReceived(files[0]);
        setFileName(files[0].name);
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileInputChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files?.length) {
      onFileReceived(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  return (
    <Box
      ref={drop}
      onClick={handleClick}
      sx={{
        border: canDrop ? "2px dashed gray" : "2px solid gray",
        padding: "1rem",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: fileName ? "rgba(0, 255, 0, 0.1)" : "white",
      }}
    >
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileInputChanged}
        style={{ display: "none" }}
      />
      {fileName ? fileName : label}
    </Box>
  );
};

const InputDragAndDropUploader: React.FC<InputDragAndDropUploaderProps> = (
  props
) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <FileUploader {...props} />
    </DndProvider>
  );
};

export default InputDragAndDropUploader;
