import { IconButton } from "@mui/material";

import React from "react";

const SocialNetworkIcon: React.FC<{ url: string; icon: React.ReactNode }> = ({
  url,
  icon,
}) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <IconButton aria-label="open drawer" edge="start">
        {icon}
      </IconButton>
    </a>
  );
};

export default SocialNetworkIcon;
