import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

type Props = {
  children: JSX.Element;
};
const Layout: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return <Box sx={{ p: 1 }}>{children}</Box>;
};
export default Layout;
