import { FC } from "react";
import { Box } from "@mui/material";
import { PartnersProps } from "models/Studio/Partners";
import CustomCard from "ui/CustomCard";
import CustomImg from "ui/images/CustomImg";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
type PartnersListProps = {
  partners?: PartnersProps[];
  onClickOnUpdatePartners: (partner: PartnersProps) => void;
  onDeletePartners: (id: string) => void;
};
const PartnersList: FC<PartnersListProps> = ({
  partners,
  onClickOnUpdatePartners,
  onDeletePartners,
}) => {
  return (
    <CustomCard>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {partners?.map((partner) => {
          return (
            <Box key={partner._id} sx={{ position: "relative", mr: 1, my: 1 }}>
              <CustomImg
                actionIcon={[
                  {
                    index: 12000,
                    action: () => onClickOnUpdatePartners(partner),
                    icon: <BorderColorIcon />,
                    appBarActionHoverColor: "green",
                  },
                  {
                    index: 21001,
                    action: () => onDeletePartners(partner._id),
                    icon: <DeleteIcon />,
                    appBarActionHoverColor: "red",
                  },
                ]}
                hasAppBar
                appBarSubtitle={partner.articleLink}
                size="lg"
                alt="bluana_img"
                imgUri={partner.logo}
              />
            </Box>
          );
        })}
      </Box>
    </CustomCard>
  );
};
export default PartnersList;
