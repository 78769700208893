import { FC } from "react";
import { RenderPdf } from "components/MainApp/RenderPdf";
import cgu from "assets/pdf/cgu.pdf";

import { Box } from "@mui/material";

const Cgu: FC = () => (
  <Box p={1}>
    <RenderPdf file={cgu} />
  </Box>
);

export default Cgu;
