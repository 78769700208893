import { Box, IconButton, Typography } from "@mui/material";
import HeaderPageWrapper from "Container/HeaderPageWrapper";
import { FC } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const maintTitle = "Ajouter un client";

type AddCustomersProps = {
  setIsCustomersFormModalOpen: (val: boolean) => void;
};

const AddCustomers: FC<AddCustomersProps> = ({
  setIsCustomersFormModalOpen,
}) => {
  return (
    <Box mt={1}>
      <HeaderPageWrapper>
        <>
          <Typography sx={{ marginRight: 1 }} variant="h6">
            {maintTitle}
          </Typography>
          <IconButton onClick={() => setIsCustomersFormModalOpen(true)}>
            <AddCircleIcon />
          </IconButton>
        </>
      </HeaderPageWrapper>
    </Box>
  );
};
export default AddCustomers;
