import { Box, IconButton, Stack, Typography } from "@mui/material";
import HeaderPageWrapper from "Container/HeaderPageWrapper";
import { AuthContext } from "context/AuthContext";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import API from "services/api";
import CustomLoader from "ui/CustomLoader";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomModal from "ui/CustomModal";
import { ImgState } from "models/App";
import { initImgState } from "utils/app.utils";
import CustomImg from "ui/images/CustomImg";

import CustomCard from "ui/CustomCard";

import {
  CONFIG_URL,
  GET_CONFIG_URL,
  GET_DOWNLOAD_JSON,
  POST_UPLOAD_JSON,
} from "services/endPoint";
import ErrorAPI from "components/ErrorAPI";

import { appLanguages, appLanguagesMapping, Lng } from "use18n";
import CustomButton from "ui/CustomButton";
import useDownloadJson from "hooks/useDownloadJson";
import CustomInputDragAndDropUploader from "ui/form/CustomInputDragAndDropUploader";
import CustomAlert from "ui/CustomAlert";

export type AppConfig = {
  _id: string;
  logo: string;
  mainImgUri: string;
};

type AppConfigPayloadToAPI = {
  logo?: string;
  mainImgUri?: string;
};

type JsonTrads = {
  [key in Lng]?: File;
};
type UploadJsonStatus = {
  [key in Lng]?: string;
};

const Application: FC = () => {
  const downloadJson = useDownloadJson();
  const [appConfig, setAppConfig] = useState<AppConfig>({
    _id: "",
    logo: "",
    mainImgUri: "",
  });
  const [mainImgState, setMainImgState] = useState<ImgState>(initImgState);
  const [logoState, setLogoState] = useState<ImgState>(initImgState);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [jsonTrads, setJsonTrads] = useState<JsonTrads>({});
  const [uploadJsonError, setUploadJsonError] = useState<UploadJsonStatus>({});
  const [uploadJsonSucces, setUploadJsonSuccess] = useState<UploadJsonStatus>(
    {}
  );

  const [isModalUpdateConfigOpen, setIsModalUpdateConfigOpen] = useState(false);

  const { user } = useContext(AuthContext);

  const getAppConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await API.get({ path: GET_CONFIG_URL });

      if (response.ok) {
        setAppConfig(response.data[0]);
        setError("");
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onResetAppConfigState = () => {
    setMainImgState(initImgState);
    setLogoState(initImgState);
    setIsModalUpdateConfigOpen(false);
    setJsonTrads({});
    setUploadJsonSuccess({});
    setUploadJsonError({});
  };

  const onSubmitConfig = async () => {
    const body: AppConfigPayloadToAPI = {};
    if (mainImgState.file !== undefined) {
      const resMainImg = await API.uploadFile("image", mainImgState.file);
      body.mainImgUri = resMainImg.path;
    }
    if (logoState.file !== undefined) {
      const resLogo = await API.uploadFile("image", logoState.file);
      body.logo = resLogo.path;
    }
    console.log("body", body);

    const resConfig = await API.put({
      path: CONFIG_URL + appConfig._id,
      body,
    });

    if (resConfig.ok) {
      setAppConfig(resConfig.data[0]);
      onResetAppConfigState();
    } else {
      // TODO TRAIT ERROR
      console.log("error");
    }
  };

  const onUploadJSON = useCallback(async (lng: Lng) => {
    downloadJson(GET_DOWNLOAD_JSON, lng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadTrad = useCallback(
    async (lng: Lng) => {
      if (!jsonTrads[lng]) {
        setUploadJsonError({
          ...uploadJsonError,
          [lng]: "Veuillez importer un fichier",
        });
        return;
      }
      const fileName = lng + ".json";
      const uploadFileName = jsonTrads[lng]?.name;
      if (fileName === uploadFileName) {
        console.log({ file: jsonTrads[lng] });
        const res = await API.uploadJsonFile(
          POST_UPLOAD_JSON,
          uploadFileName,
          jsonTrads[lng]
        );
        if (res?.message) {
          setUploadJsonSuccess({ ...uploadJsonSucces, [lng]: res.message });
        } else {
          setUploadJsonError({
            ...uploadJsonError,
            [lng]: res?.error,
          });
        }
      } else {
        setUploadJsonError({
          ...uploadJsonError,
          [lng]:
            "Le nom du fichier  " +
            uploadFileName +
            " est incompatible avec la langue attendue : " +
            lng,
        });
      }
    },
    [jsonTrads, uploadJsonError, uploadJsonSucces]
  );

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error !== "") return <ErrorAPI message={error} />;
  if (isLoading) return <CustomLoader />;

  return (
    <>
      <HeaderPageWrapper>
        <>
          <Typography sx={{ marginRight: 1 }} variant="h6">
            Modifier la config de l'app
          </Typography>
          <IconButton onClick={() => setIsModalUpdateConfigOpen(true)}>
            <AddCircleIcon />
          </IconButton>
        </>
      </HeaderPageWrapper>
      <CustomCard>
        <>
          <Box sx={{ mb: 4 }}>
            <Typography textAlign={"center"}>
              Hello {user.user?.email} 👋
            </Typography>
          </Box>
          <Box
            sx={{
              mb: 4,
              pb: 4,
              borderBottom: "1px solid lightGray",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Logo actuel</Typography>
            <CustomImg
              width="auto"
              alt="application_logo"
              imgUri={appConfig?.logo}
              size="xxs"
            />
          </Box>
          <Box
            sx={{
              mb: 4,
              pb: 4,

              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              Fichiers de traduction disponible
            </Typography>

            {appLanguages.map((lng, index) => (
              <Box key={lng + "_description_admin"} display="flex">
                <Typography mx={2} variant="h4">
                  {appLanguagesMapping[lng].nativeName}{" "}
                  {appLanguagesMapping[lng].flag}
                </Typography>
                <Box>{index < appLanguages.length - 1 && "-"}</Box>
              </Box>
            ))}
          </Box>
        </>
      </CustomCard>
      <CustomModal
        header="Modifier la config de l'app"
        open={isModalUpdateConfigOpen}
        setOpen={setIsModalUpdateConfigOpen}
        onCloseButtonLabel="Fermer"
        // onValidate={onSubmitConfig}
        onClose={onResetAppConfigState}
        fullWidth
        maxWidth="md"
        body={
          <Box>
            <CustomAlert severity="warning">
              Attention, cette partie est sensible. Ici vous pouvez :
              <ul>
                <li>
                  Téléverser des fichiers de traduction pour l'application.
                  Veuillez respecter le format, un mauvais fichier ou une erreur
                  dans la saisie peut créer des bugs sur l'application.
                </li>
                <li>
                  Télécharger les fichiers de traduction actuelle sur
                  l'application.
                </li>
                <li>
                  Changer le logo de l'application. Ce dernier doit respecter un
                  format horizontal (dans l'idéal 2268 × 452).
                </li>
                <li>Changer l'image principale de la page d'accueil.</li>
              </ul>
            </CustomAlert>

            <Stack borderBottom="1px solid #E2E8F0" spacing={2} py={8}>
              <Typography variant="h4">
                Glissez/Déposez ou Téléversez un fichier JSON de traduction
              </Typography>
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"space-between"}
              >
                {appLanguages.map((lng) => (
                  <Box
                    p={1}
                    border="1px solid #E2E8F0"
                    flex={1}
                    key={"upaload_language_" + lng}
                  >
                    <Stack alignItems={"flex-end"} direction="row" spacing={2}>
                      <CustomInputDragAndDropUploader
                        label={
                          appLanguagesMapping[lng].flag +
                          " " +
                          appLanguagesMapping[lng].nativeName
                        }
                        onFileReceived={(file) => {
                          setUploadJsonSuccess({
                            ...uploadJsonSucces,
                            [lng]: undefined,
                          });

                          setUploadJsonError({
                            ...uploadJsonError,
                            [lng]: undefined,
                          });

                          setJsonTrads({ ...jsonTrads, [lng]: file });
                        }}
                      />
                      <CustomButton
                        title={
                          "valider la langue " +
                          appLanguagesMapping[lng].nativeName
                        }
                        onClick={() => handleUploadTrad(lng)}
                      />
                    </Stack>
                    {uploadJsonError[lng] && (
                      <Box mt={1}>
                        <CustomAlert severity="error">
                          {uploadJsonError[lng]}
                        </CustomAlert>
                      </Box>
                    )}
                    {uploadJsonSucces[lng] && (
                      <Box mt={1}>
                        <CustomAlert>{uploadJsonSucces[lng]}</CustomAlert>
                      </Box>
                    )}
                  </Box>
                ))}
              </Stack>
            </Stack>

            <Stack borderBottom="1px solid #E2E8F0" spacing={2} py={8}>
              <Typography variant="h4">
                Téléchargez un fichier JSON de traduction
              </Typography>
              <Stack direction="row" spacing={2}>
                {appLanguages.map((lng) => (
                  <Box
                    key={"download_language_" + lng}
                    p={1}
                    border="1px solid #E2E8F0"
                    flex={1}
                  >
                    <CustomButton
                      onClick={() => onUploadJSON(lng)}
                      title={
                        appLanguagesMapping[lng].flag +
                        " " +
                        appLanguagesMapping[lng].nativeName
                      }
                    />
                  </Box>
                ))}
              </Stack>
            </Stack>
            <Stack borderBottom="1px solid #E2E8F0" spacing={2} py={8}>
              <Typography variant="h4">Modifier le logo</Typography>
              <Stack direction={"row"} spacing={4} alignItems={"flex-end"}>
                <CustomInputUploadFile
                  label="Importer un logo"
                  name="logo"
                  file={logoState?.file}
                  setFile={(file) => setLogoState({ error: false, file })}
                />

                <Stack>
                  {!logoState?.file && (
                    <Box sx={{ mb: 2 }}>
                      <CustomImg
                        size="xxs"
                        width="auto"
                        alt="application_logo"
                        imgUri={appConfig?.logo}
                      />
                    </Box>
                  )}
                  <CustomButton
                    disabled={!logoState.file}
                    onClick={onSubmitConfig}
                    title="valider le logo"
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={2} py={8}>
              <Typography variant="h4">Modifier l'image principale</Typography>
              {!mainImgState.file && (
                <Box sx={{ mb: 2 }}>
                  <CustomImg
                    width="auto"
                    alt="application_logo"
                    imgUri={appConfig?.mainImgUri}
                  />
                </Box>
              )}
              <CustomInputUploadFile
                label="Modifier l'image principale"
                name="image"
                file={mainImgState.file}
                setFile={(file) => setMainImgState({ error: false, file })}
              />
              <Box width="auto">
                <CustomButton
                  disabled={!mainImgState.file}
                  onClick={onSubmitConfig}
                  title="valider l'image principale"
                />
              </Box>
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default Application;
