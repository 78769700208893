import { ChangeEvent, FC, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomImg from "../images/CustomImg";
import CustomButtonUploadFile from "./CustomButtonUploadFile";
import { maxFileSize } from "utils/app.utils";

export type Files = Record<number, File>;

const imageSize = "150px";

const useStyles = makeStyles({
  imagesWrapper: {
    borderRadius: 4,
    padding: 8,
    marginTop: "8px",
  },
  imagesSubWrapper: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
  },

  iconTrash: {
    "&:hover": {
      cursor: "pointer",
      color: "red",
    },
    color: "#757575",
  },
});

type Props = {
  label?: string;
  setFiles: (img: Files) => void;
  files: Files;
  name: string;
  setError?: (val: boolean) => void;
  error?: boolean;
};

const CustomInputUploadFiles: FC<Props> = ({
  label = "Importer les images",
  name,
  setFiles,
  files,
  setError,
  error,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setError) setError(false);
    if (e.currentTarget?.files !== null) {
      const filesArray = Array.from(e.currentTarget.files);

      const oversizedFiles = filesArray.filter(
        (file) => file.size > maxFileSize
      );

      if (oversizedFiles.length > 0) {
        setErrorMessage(
          "Un ou plusieurs fichiers sont trop volumineux. La taille maximale autorisée par fichier est de 5 Mo."
        );
        return;
      } else {
        setErrorMessage(null);
      }

      const filesObject: Files = {};

      filesArray.forEach((file, i) => {
        filesObject[i] = file;
      });

      setFiles(filesObject);
    }
  };

  return (
    <>
      <div>
        <input
          multiple
          accept="image/jpeg, image/png"
          onChange={(e) => onInputChange(e)}
          style={{ display: "none" }}
          type="file"
          name={name}
          id={name}
        />
        <label htmlFor={name}>
          <CustomButtonUploadFile label={label} />
        </label>
      </div>

      <div
        style={{ border: `1px solid ${error ? "#DD5C5D" : "#C4C4C4"}` }}
        className={classes.imagesWrapper}
      >
        {Object.keys(files).length > 0 ? (
          <div className={classes.imagesSubWrapper}>
            {Object.keys(files).map((file, i) => {
              return (
                <Box key={"previewImages_" + i} sx={{ mr: 2 }}>
                  <CustomImg
                    preview={URL.createObjectURL(files[parseInt(file, 10)])}
                    alt={"file" + i}
                    size="sm"
                  />
                </Box>
              );
            })}
            <div
              onClick={() => setFiles({})}
              className="cursor-pointer hover:text-red-500"
            >
              <DeleteIcon className={classes.iconTrash} />
            </div>
          </div>
        ) : (
          <Typography color={error ? "error" : ""} sx={{ height: imageSize }}>
            Aucune images ...
          </Typography>
        )}
      </div>
      {errorMessage && (
        <Typography color="error" variant="caption">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default CustomInputUploadFiles;
