import { FC } from "react";

import { getEpisodePath } from "router/constants";

import SessionList from "components/MainApp/Session/SessionList";
import { useEpisodeContext } from "context/EpisodeContext";

type EpisodesListProps = {
  sliceCount?: number;
};

const EpisodesList: FC<EpisodesListProps> = ({ sliceCount }) => {
  const { episodes, error, isLoading } = useEpisodeContext();

  return (
    <SessionList
      data={episodes?.filter(({ published }) => published)}
      getPath={getEpisodePath}
      errorApi={error}
      isLoading={isLoading}
      sliceCount={sliceCount}
    />
  );
};

export default EpisodesList;
