const API_DOMAIN = "https://studio-ambiant.com";
const API_PATH = "/api";

const API_URL =
  //*** LOCAL
  process.env.REACT_APP_API_URL ||
  //*** PROD
  API_DOMAIN + API_PATH;
console.log("API_URL", API_URL);
export { API_URL, API_PATH, API_DOMAIN };
