import { Box, IconButton, Typography } from "@mui/material";
import HeaderPageWrapper from "Container/HeaderPageWrapper";
import { FC } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const maintTitle = "Ajouter un partenaire";

type AddPartnersProps = {
  setIsPartnersFormModalOpen: (val: boolean) => void;
};

const AddPartners: FC<AddPartnersProps> = ({ setIsPartnersFormModalOpen }) => {
  return (
    <Box mt={1}>
      <HeaderPageWrapper>
        <>
          <Typography sx={{ marginRight: 1 }} variant="h6">
            {maintTitle}
          </Typography>
          <IconButton onClick={() => setIsPartnersFormModalOpen(true)}>
            <AddCircleIcon />
          </IconButton>
        </>
      </HeaderPageWrapper>
    </Box>
  );
};
export default AddPartners;
