import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import LanguageSwitcher from "components/LanguageSwitcher";

import StudioAmbiantLogo from "components/Logo";
import type { FC } from "react";

import React from "react";
import { useNavigate } from "react-router-dom";
import { ScreenProps } from "router/useMainAppRouter";
import { capitalizeFirstLetter } from "utils/app.utils";

const getResponsiveHeaderValue = (xsValue: string, smValue: string) => ({
  // hide some App bar elements on extra small screens
  xs: xsValue,
  // show all App bar elements as a flex container on small screens and up
  sm: smValue,
});

const drawerWidth = 240;
type Props = {
  handleDrawerToggle: () => void;
  menu: ScreenProps[];
  isOpen: boolean;
};

/**
 *
 *
 * @param {Props} { handleDrawerToggle, menu, isOpen }
 * @return {*}
 */

const AppBarDrawer: FC<Props> = ({ handleDrawerToggle, menu, isOpen }) => {
  const navigate = useNavigate();
  return (
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: getResponsiveHeaderValue("border-box", "none"),
          width: drawerWidth,
        },
      }}
    >
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Box
          sx={{
            marginBottom: getResponsiveHeaderValue("7px", "14px"),
            marginTop: getResponsiveHeaderValue("6px", "13px"),
          }}
        >
          <StudioAmbiantLogo />
        </Box>
        <Divider />
        <List>
          {menu.map(({ name, path }) => (
            <ListItem
              onClick={() => navigate(path)}
              key={"drawer_appBar_item_" + name}
              disablePadding
            >
              <ListItemButton
                sx={{
                  textAlign: "center",
                  fontWeight: 800,
                  textTransform: "none",
                }}
              >
                <ListItemText primary={capitalizeFirstLetter(name)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box sx={{ my: 4 }}>
          <LanguageSwitcher />
        </Box>
      </Box>
    </Drawer>
  );
};

export default AppBarDrawer;
