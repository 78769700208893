import { FC } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import HeaderPageWrapper from "Container/HeaderPageWrapper";

const maintTitle = "Modifer le tempérament";

type UpdateTemperamentProps = {
  onClickOnUpdateTemperament: () => void;
};

const UpdateTemperament: FC<UpdateTemperamentProps> = ({
  onClickOnUpdateTemperament,
}) => {
  return (
    <Box mt={1}>
      <HeaderPageWrapper>
        <>
          <Typography sx={{ marginRight: 1 }} variant="h6">
            {maintTitle}
          </Typography>
          <IconButton onClick={onClickOnUpdateTemperament}>
            <AddCircleIcon />
          </IconButton>
        </>
      </HeaderPageWrapper>
    </Box>
  );
};
export default UpdateTemperament;
