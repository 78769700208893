import Footer from "components/MainApp/Footer";
import MainAppBar from "components/MainApp/AppBar";
import MainAppLayout from "Container/MainAppLayout";
import { FC } from "react";

type Props = {
  children: React.ReactNode;
};

const MainAppWrapper: FC<Props> = ({ children }) => {
  return (
    <>
      <MainAppBar />
      <MainAppLayout>{children}</MainAppLayout>
      <Footer />
    </>
  );
};

export default MainAppWrapper;
