import { Button, CircularProgress } from "@mui/material";
import { FC } from "react";

type ButtonProps = {
  title: string;
  onClick: () => void;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  isLoading?: boolean;
};

const CustomButton: FC<ButtonProps> = ({
  title,
  onClick,
  fullWidth = false,
  type,
  disabled = false,
  isLoading = false,
}) => {
  return (
    <Button
      aria-label={title}
      type={type}
      fullWidth={fullWidth}
      onClick={onClick}
      variant="contained"
      color="secondary"
      disabled={disabled || isLoading}
      sx={{ fontFamily: "Futura-bold", letterSpacing: "0.5px" }}
    >
      {isLoading ? <CircularProgress size={24} color="inherit" /> : title}
    </Button>
  );
};

export default CustomButton;
