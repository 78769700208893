import { FC, useMemo } from "react";
import CustomLoader from "ui/CustomLoader";

import ErrorAPI from "components/ErrorAPI";
import SEO from "components/SEO";
import MainWrapper from "Container/MainWrapper";
import { useTranslation } from "react-i18next";
import SectionWrapper from "Container/SectionWrapper";
import PhraseWrapper from "Container/PhraseWrapper";
import SessionList from "components/MainApp/Session/SessionList";

import { Box, Stack } from "@mui/material";
import { getResponsiveValue } from "utils/app.utils";
import { getConsultingPath } from "router/constants";
import TitleWithList from "components/MainApp/TitleWithList";

import { useTranslationContext } from "context/TranslationContext";
import { useConsultingContext } from "context/ConsultingContext";

const Consulting: FC = () => {
  const { t } = useTranslation();
  const { emptyTrad } = useTranslationContext();
  const { consultings, isLoading, error } = useConsultingContext();

  const introConsulting = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.consulting.description).map((key) =>
            t("consulting.description." + key)
          )
        : [],
    [t, emptyTrad]
  );

  const interventionAreas = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.consulting.interventionAreas.list).map((key) =>
            t("consulting.interventionAreas.list." + key)
          )
        : [],
    [t, emptyTrad]
  );
  const missions = useMemo(
    () =>
      emptyTrad
        ? Object.keys(emptyTrad.consulting.missions.list).map((key) =>
            t("consulting.missions.list." + key)
          )
        : [],
    [t, emptyTrad]
  );

  if (error) return <ErrorAPI message={error} />;
  if (isLoading) return <CustomLoader />;

  return (
    <MainWrapper>
      <SEO
        title="Studio Ambiant - Conseil"
        description="Le studio Ambiant développe des projets de restauration en exprimant votre tempérament, comprenant vos enjeux et en impliquant son écosystème au profit de l’expérience client."
      />
      <Stack spacing={6}>
        <SectionWrapper
          title={t("consulting.title")}
          description={introConsulting}
        />
        <Box
          sx={{ px: getResponsiveValue(1, 0), pb: getResponsiveValue(4, 0) }}
        >
          <SessionList
            getPath={getConsultingPath}
            data={consultings?.filter(({ published }) => published)}
            errorApi={error}
            isLoading={isLoading}
          />
        </Box>
      </Stack>

      <Stack
        py={getResponsiveValue(6, 0)}
        direction={getResponsiveValue("column", "row")}
        spacing={6}
      >
        <Box flex={1}>
          <TitleWithList
            title={t("consulting.interventionAreas.title")}
            items={interventionAreas}
          />
        </Box>
        <Box flex={1}>
          <TitleWithList
            title={t("consulting.missions.title")}
            items={missions}
          />
        </Box>
      </Stack>

      <PhraseWrapper phrase={t("consulting.phrase")} />
    </MainWrapper>
  );
};

export default Consulting;
