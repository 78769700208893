import { useCallback } from "react";
import API from "services/api";

const useDownloadJson = () => {
  const downloadJson = useCallback(async (url: string, filename: string) => {
    try {
      const response = await API.get({ path: url + filename });

      if (response.error) {
        throw new Error("Erreur lors du téléchargement du fichier.");
      }

      const jsonString = JSON.stringify(response, null, 2);

      const blob = new Blob([jsonString], { type: "application/json" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier:", error);
    }
  }, []);

  return downloadJson;
};

export default useDownloadJson;
