import { FC } from "react";

import {
  StudioTemperament,
  StudioPress,
  StudioPartners,
  StudioCustomers,
} from "components/Admin/Studio";

import CustomTabPanel from "ui/CustomTabPanel";

const tabPanels = [
  {
    component: <StudioTemperament />,
  },
  {
    component: <StudioPartners />,
  },
  {
    component: <StudioCustomers />,
  },
  {
    component: <StudioPress />,
  },
];

const tabs = [
  {
    title: "Le tempérament",
  },
  {
    title: "Les partenaires",
  },
  {
    title: "Les clients",
  },
  {
    title: "La presse",
  },
];

const Studio: FC = () => {
  return <CustomTabPanel tabs={tabs} tabPanels={tabPanels} />;
};

export default Studio;
