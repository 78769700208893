import React, { useEffect } from "react";
import API from "services/api";
import { SIGNIN_TOKEN_URL } from "services/endPoint";

type User = {
  isLogged?: boolean;
  isLoaded?: boolean;
  user?: {
    email: string;
    _id: string;
  };
};
type AuthContextType = {
  setUser: (user: User) => void;
  user: User;
};
export const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<User>({});
  const getUserByToken = () =>
    API.get({
      path: SIGNIN_TOKEN_URL,
    });

  useEffect(() => {
    getUserByToken().then((res) =>
      setUser({ isLogged: res.ok, user: res.user, isLoaded: true })
    );
  }, []);

  const value = {
    user,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
