import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { FC } from "react";
import useDate from "hooks/useDate";
import { useNavigate } from "react-router-dom";
import { getTicketingByIdPath } from "router/constants";

import { getImgUrl } from "utils/app.utils";

type TicketingCardItem = {
  _id: string;
  description?: string;
  imgUri?: string;
  date: string | Date;
  title: string;
  adress: string;
};

type TicketingCardProps = {
  ticketing: TicketingCardItem;
  isAdmin?: boolean;
};

export const TICKETING_WIDTH_CARD = { xs: "100%", sm: 350 };

const TicketingCard: FC<TicketingCardProps> = ({
  ticketing: { _id, imgUri, description, date, title, adress },
  isAdmin,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Card
        onClick={() => {
          if (isAdmin) return;
          navigate(getTicketingByIdPath(_id));
        }}
        sx={{
          borderRadius: 0,
          boxShadow: 0,
        }}
      >
        <CardActionArea>
          <CardMedia
            height="350"
            component="img"
            image={getImgUrl(imgUri)}
            alt={"ticketingImg_" + title}
            crossOrigin="anonymous"
          />
          <CardContent>
            <Box mb={1}>
              <Typography
                textTransform="uppercase"
                variant="h2"
                component="div"
              >
                {title}
              </Typography>
              <Typography
                textTransform="uppercase"
                variant="h2"
                component="div"
              >
                {useDate(date).dayMonthAndYear}
              </Typography>
            </Box>
            <Typography height="20px" variant="body2" color="text.secondary">
              {adress}
            </Typography>
            <Typography height="20px" variant="body1" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default TicketingCard;
