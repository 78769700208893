import { AlertColor } from "@mui/material";
import type { FC, PropsWithChildren } from "react";
import { Alert } from "@mui/material";

import React from "react";

type Props = { severity?: AlertColor };

const CustomAlert: FC<PropsWithChildren<Props>> = ({
  severity = "success",
  children,
}) => <Alert severity={severity}>{children}</Alert>;

export default CustomAlert;
