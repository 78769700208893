import { FC, useEffect, useState } from "react";

import AddPress from "./AddPress";
import {
  PressFormState,
  PressPayloadToAPI,
  PressProps,
} from "models/Studio/Press";
import API from "services/api";
import PressModalForm from "./PressModalForm";
import PressList from "./PressList";
import { PRESS_URL } from "services/endPoint";
import usePress from "hooks/usePress";
import ErrorAPI from "components/ErrorAPI";
import CustomLoader from "ui/CustomLoader";

const initState = {
  _id: "",
  logo: "",
  file: undefined,
  error: false,
  articleLink: { value: "", error: "" },
};

const StudioPress: FC = () => {
  const { press, getPress, error, setPress } = usePress();

  const [isPressFormModalOpen, setIsPressFormModalOpen] = useState(false);
  const [pressFormState, setPressFormState] =
    useState<PressFormState>(initState);
  const isEditing = !!pressFormState._id;
  // TODO TRAIT ERROR

  const onClickOnUpdatePress = async (pressData: PressProps) => {
    setPressFormState({
      ...pressFormState,
      _id: pressData?._id,
      logo: pressData?.logo,
      articleLink: { error: "", value: pressData?.articleLink },
    });
    setIsPressFormModalOpen(true);
  };

  // ! DELETE
  const onDeletePress = async (_id: string) => {
    const resPress = await API.delete({
      path: PRESS_URL + _id,
    });
    setPress(resPress.data);
  };

  const onResetState = () => {
    setIsPressFormModalOpen(false);
    setPressFormState(initState);
  };

  const onCloseModal = () => {
    onResetState();
  };

  const onSubmitPress = async () => {
    const body: PressPayloadToAPI = {};
    let error = false;
    let resImg;
    if (pressFormState.articleLink?.value === "") {
      error = true;
    }

    if (pressFormState.file !== undefined) {
      resImg = await API.uploadFile("image", pressFormState.file);
      body.logo = resImg.path;
    }
    if (pressFormState.file === undefined && !isEditing) {
      error = true;
      setPressFormState({ ...pressFormState, error: true });
    }

    body.articleLink = pressFormState.articleLink?.value;

    if (error) {
      setPressFormState({
        ...pressFormState,
        error: pressFormState.file === undefined,
        articleLink: {
          value: pressFormState?.articleLink?.value as string,
          error:
            pressFormState?.articleLink?.value === ""
              ? "Lien vers la page manquant"
              : "",
        },
      });
      return;
    }
    let resPress;
    if (isEditing) {
      resPress = await API.put({
        path: PRESS_URL + pressFormState._id,
        body,
      });
    } else {
      resPress = await API.post({
        path: PRESS_URL,
        body,
      });
    }
    if (resPress.ok) {
      setPress(resPress.data);
      onResetState();
    } else {
      // TODO TRAIT ERROR
      console.log("error");
    }
  };

  useEffect(() => {
    getPress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ErrorAPI minHeight="72vh" message={error} />;
  if (!press) return <CustomLoader />;

  return (
    <>
      <AddPress setIsPressFormModalOpen={setIsPressFormModalOpen} />
      <PressList
        press={press}
        onClickOnUpdatePress={onClickOnUpdatePress}
        onDeletePress={onDeletePress}
      />
      <PressModalForm
        setPressFormState={setPressFormState}
        setIsPressFormModalOpen={setIsPressFormModalOpen}
        onSubmitPress={onSubmitPress}
        onCloseModal={onCloseModal}
        pressFormState={pressFormState}
        isPressFormModalOpen={isPressFormModalOpen}
      />
    </>
  );
};
export default StudioPress;
