import { TemperamentProps } from "models/Studio/Temperament";

import { useEffect, useState } from "react";
import API from "services/api";
import { GET_TEMPERAMENTS_URL } from "services/endPoint";

const useTemperament = () => {
  const [temperament, setTemperament] = useState<TemperamentProps>();
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);

  const getTemperaments = async () => {
    setIsLoading(true);
    try {
      const response = await API.get({ path: GET_TEMPERAMENTS_URL });
      if (response.ok) {
        setTemperament(response.data[0]);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.log("error in useTemperament", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTemperaments();
  }, []);

  return { temperament, loading, error, setTemperament };
};

export default useTemperament;
