import { Box } from "@mui/material";
import { CustomersFormState } from "models/Studio/Customers";
import { Dispatch, SetStateAction } from "react";
import CustomModal from "ui/CustomModal";
import CustomInput from "ui/form/CustomInput";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";
import CustomImg from "ui/images/CustomImg";

type CustomersModalFormProps = {
  setCustomersFormState: Dispatch<SetStateAction<CustomersFormState>>;
  setIsCustomersFormModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmitCustomers: () => void;
  onCloseModal: () => void;
  customersFormState: CustomersFormState;
  isCustomersFormModalOpen: boolean;
};
const CustomersModalForm = ({
  setCustomersFormState,
  setIsCustomersFormModalOpen,
  onSubmitCustomers,
  onCloseModal,
  customersFormState,
  isCustomersFormModalOpen,
}: CustomersModalFormProps) => {
  const isEditing = !!customersFormState._id;
  const onInputsChange = (e: { target: { name: string; value: string } }) => {
    setCustomersFormState({
      ...customersFormState,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <CustomModal
      header={isEditing ? "Modifier le client" : "Ajouter un client"}
      open={isCustomersFormModalOpen}
      setOpen={setIsCustomersFormModalOpen}
      onValidate={onSubmitCustomers}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <>
          <Box sx={{ mb: 1 }}>
            {isEditing ? (
              <Box>
                {!customersFormState.file && (
                  <Box sx={{ mb: 1 }}>
                    <CustomImg
                      alt="customers_image"
                      imgUri={customersFormState.logo}
                    />
                  </Box>
                )}
                <CustomInputUploadFile
                  label="Modifier l'image"
                  name="image"
                  file={customersFormState.file}
                  setFile={(file) =>
                    setCustomersFormState({ ...customersFormState, file })
                  }
                />
              </Box>
            ) : (
              <CustomInputUploadFile
                name="image"
                file={customersFormState.file}
                setFile={(file) =>
                  setCustomersFormState({ ...customersFormState, file })
                }
                error={customersFormState.error as boolean}
              />
            )}
          </Box>
          <Box>
            <CustomInput
              fullwidth
              name={"articleLink"}
              label="Lien du client"
              onChange={onInputsChange}
              value={customersFormState["articleLink"]}
            />
          </Box>
        </>
      }
    />
  );
};
export default CustomersModalForm;
