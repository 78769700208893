import { Box } from "@mui/material";
import CustomImg from "./CustomImg";
type CustomImagesListProps = {
  images?: ReadonlyArray<string>;
};
const CustomImagesList = ({ images }: CustomImagesListProps) => {
  if (!images) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {images.map((item) => (
        <Box sx={{ mr: "4px", mb: "4px" }} key={item}>
          <CustomImg imgUri={item} alt={item} />
        </Box>
      ))}
    </Box>
  );
};
export default CustomImagesList;
