import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import useI18n from "use18n";
import SplashScreen from "pages/SplashScreen";
import { useEffect, useState } from "react";
import { DURATION_SPLASH_SCREEN } from "utils/app.utils";
import EpisodesContextWrapper from "context/EpisodeContext";
import { ThemeProvider } from "@mui/material";
import theme from "ui/theme";
import ConfigContextWrapper from "context/ConfigContext";
import { AuthProvider } from "context/AuthContext";
import TranslationContextWrapper from "context/TranslationContext";
import ConsultingContextWrapper from "context/ConsultingContext";
import StudioContextWrapper from "context/StudioContext";

const I18nProvider: React.FC = () => {
  const { isLoading } = useI18n();
  const [displaySplashScreen, setDisplaySplashScreen] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(
        () => setDisplaySplashScreen(false),
        DURATION_SPLASH_SCREEN
      );
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  return displaySplashScreen ? (
    <SplashScreen />
  ) : (
    <ThemeProvider theme={theme}>
      <TranslationContextWrapper>
        <ConfigContextWrapper>
          <AuthProvider>
            <EpisodesContextWrapper>
              <ConsultingContextWrapper>
                <StudioContextWrapper>
                  <App />
                </StudioContextWrapper>
              </ConsultingContextWrapper>
            </EpisodesContextWrapper>
          </AuthProvider>
        </ConfigContextWrapper>
      </TranslationContextWrapper>
    </ThemeProvider>
  );
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<I18nProvider />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
