import { FC } from "react";
import { useTranslation } from "react-i18next";

import { getLocalizedString } from "use18n";
import { ConsultingProps } from "models/Consulting";

import useDate from "hooks/useDate";

import SEO from "components/SEO";
import SessionDetailHeader from "components/MainApp/Session/SessionDetailHeader";
import SessionDetails from "components/MainApp/Session/SessionDetails";
import { getConsultingPath } from "router/constants";

type Props = {
  consulting: ConsultingProps;
  consultings: ConsultingProps[];
};

/**
 * ConsultingDetails component that displays the details of an consulting.
 * @param {Props} props - Component props
 * @param {ConsultingProps} props.consulting - Consulting object
 * @param {Array<ConsultingProps>} props.consultings - Array of consultings objects
 * @returns {JSX.Element} - ConsultingDetails component
 */

const ConsultingDetails: FC<Props> = ({ consulting, consultings }) => {
  const { t } = useTranslation();

  const { title, mainImgUri, subtitle, subtitle2, city, date } = consulting;

  return (
    <>
      <SEO title={getLocalizedString(title) as string} />
      <SessionDetailHeader
        image={mainImgUri}
        title={[getLocalizedString(title), getLocalizedString(subtitle)]}
        text={[
          getLocalizedString(subtitle2),
          useDate(date).monthAndYear + ", " + city,
        ]}
      />
      {/* CONSULTING DESCRIPTION */}
      <SessionDetails
        text={{
          title: t("consultingDetail.concept"),
          phrase: t("consultingDetail.phrase"),
        }}
        session={consulting}
        sessions={consultings}
        getPath={getConsultingPath}
      />
    </>
  );
};

export default ConsultingDetails;
