import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CustomButton from "./CustomButton";
import { Breakpoint } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type CustomModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  onClose?: () => void;
  header?: string;
  onValidate?: () => void;
  body?: React.ReactElement;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  isLoading?: boolean;
  onCloseButtonLabel?: string;
};

const CustomModal = ({
  open,
  setOpen,
  header,
  onValidate,
  body,
  fullWidth,
  maxWidth,
  isLoading,
  onCloseButtonLabel = "Annuler",
  onClose,
}: CustomModalProps) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else setOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {header && <DialogTitle>{header}</DialogTitle>}
        {body && <DialogContent>{body}</DialogContent>}
        <DialogActions>
          {!isLoading && (
            <CustomButton title={onCloseButtonLabel} onClick={handleClose} />
          )}

          {onValidate && (
            <CustomButton
              isLoading={isLoading}
              title="Valider"
              onClick={onValidate}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomModal;
