// import bandeauVideoMP4 from "assets/videos/bandeauVideo.MP4";
// import bandeauVideoWebM from "assets/videos/bandeauVideo.webm";
// import posterImage from "assets/imgs/posterBandeauVideo.png";

// import { makeStyles } from "@material-ui/core/styles";
// import { Box } from "@material-ui/core";
// import { minHeight } from "constants/constants";

// const useStyles = makeStyles(() => ({
//   videoContainer: {
//     position: "relative",
//     width: "100%",
//     overflow: "hidden",
//   },
//   video: {
//     position: "absolute",
//     width: "100%",
//     height: "100%",
//     objectFit: "cover",
//   },
// }));

// function MainMediaApp() {
//   const classes = useStyles();

//   return (
//     <Box className={classes.videoContainer} sx={{ height: minHeight }}>
//       <video
//         autoPlay
//         playsInline
//         loop
//         muted
//         preload="metadata"
//         poster={posterImage}
//         className={classes.video}
//       >
//         <source src={bandeauVideoMP4} type="video/mp4" />
//         <source src={bandeauVideoWebM} type="video/webm" />
//         Votre navigateur ne prend pas en charge la lecture de vidéos.
//       </video>
//     </Box>
//   );
// }

// export default MainMediaApp;

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { xsHeightAppBar, smHeightAppBar } from "constants/constants";
import { FC } from "react";
import { getImgUrl } from "utils/app.utils";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - ${xsHeightAppBar})`,
    },
    [theme.breakpoints.up("sm")]: {
      height: `calc(100vh - ${smHeightAppBar})`,
    },
  },
  image: {
    width: "100%",
    height: "100%", // this will ensure the image takes the full height of its container
    objectFit: "cover",
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // this centers the log
    height: "auto", // maintain aspect ratio
    zIndex: 2, // to ensure the logo is on top
    width: "100%", // take full width of the parent
    maxWidth: "1000px", // set maximum width
  },
}));

const MainMediaApp: FC<{
  src?: string;
  logo?: string;
}> = ({ src, logo }) => {
  const classes = useStyles();

  return (
    <Box className={classes.imageContainer}>
      {src && (
        <img src={getImgUrl(src)} alt="Description" className={classes.image} />
      )}
      {logo && (
        <img
          src={getImgUrl(logo)}
          alt="Logo Studio Ambiant"
          className={classes.logo}
        />
      )}
    </Box>
  );
};

export default MainMediaApp;
