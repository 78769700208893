import useConfig from "hooks/useConfig";
import { AppConfig } from "pages/Admin/Application";
import { createContext, FC, useContext, useEffect, useMemo } from "react";

type Context = {
  config: AppConfig | undefined;
  error: string;
  isLoading: boolean;
};

const ConfigContext = createContext<Context>({} as Context);

export const useConfigContext = () => useContext(ConfigContext);

type Props = {
  children: React.ReactNode;
};

const ConfigContextWrapper: FC<Props> = ({ children }) => {
  const { config, getConfig, error, isLoading } = useConfig();

  const contextValue = useMemo(
    () => ({
      config,
      error,
      isLoading,
    }),
    [config, error, isLoading]
  );

  useEffect(() => {
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextWrapper;
