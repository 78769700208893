import { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
type CustomLoaderProps = {
  minH?: string;
};
const CustomLoader: FC<CustomLoaderProps> = ({ minH = "60vh" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: minH,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};
export default CustomLoader;
