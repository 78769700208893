import AddConsulting from "components/Admin/Consulting/AddConsulting";
import ConsultingModalForm from "components/Admin/Consulting/ConsultingModalForm";
import ConsultingList from "components/Admin/Consulting/ConsultingList";
import ErrorAPI from "components/ErrorAPI";

import { appLanguages, LocalizedString } from "use18n";
import { ImgState, DateState, ImagesState } from "models/App";
import {
  ConsultingFormState,
  ConsultingPayloadToAPI,
  ConsultingProps,
} from "models/Consulting";
import { FC, useCallback, useState } from "react";
import API from "services/api";
import { CONSULTING_URL } from "services/endPoint";

import CustomLoader from "ui/CustomLoader";
import { Files } from "ui/form/CustomInputUploadFiles";
import {
  createInitialState,
  createInputStruct,
  createLocalizedInputStruct,
  initDateState,
  initImagesState,
  initImgState,
} from "utils/app.utils";
import { consultingInputs } from "utils/consulting.utils";
import { useConsultingContext } from "context/ConsultingContext";

const Consultings: FC = () => {
  const {
    consultings,
    isLoading: isLoadingConsulting,
    error: isConsultingsError,
    setConsultings,
  } = useConsultingContext();

  const emptyLocalizedString: LocalizedString = appLanguages.reduce(
    (obj, language) => {
      obj[language] = "";
      return obj;
    },
    {} as LocalizedString
  );

  const initialConsultingFormState = () => ({
    ...createInitialState(consultingInputs),
    description: [emptyLocalizedString],
    published: false,
  });
  const [consultingFormState, setConsultingFormState] =
    useState<ConsultingFormState>(
      initialConsultingFormState() as ConsultingFormState
    );
  const [isConsultingFormModalOpen, setIsConsultingFormModalOpen] =
    useState(false);
  const [mainImgState, setMainImgState] = useState<ImgState>(initImgState);
  const [dateState, setDateState] = useState<DateState>(initDateState);
  const [imagesState, setImagesState] = useState<ImagesState>(initImagesState);
  const [isLoading, setIsLoading] = useState(false);
  const isEditing = !!consultingFormState._id;

  const onResetConsultingState = () => {
    setConsultingFormState(initialConsultingFormState() as ConsultingFormState);
    setMainImgState(initImgState);
    setDateState(initDateState);
    setImagesState(initImagesState);
  };

  const onSubmitConsulting = async () => {
    if (
      consultingFormState.title.fr.value === "" ||
      consultingFormState.title.en.value === ""
    ) {
      setConsultingFormState({
        ...consultingFormState,
        title: {
          ...consultingFormState.title,
          fr: {
            ...consultingFormState.title.fr,
            error:
              consultingFormState.title.fr.value === ""
                ? "Le champ titre français est requis"
                : "",
          },
          en: {
            ...consultingFormState.title.en,
            error:
              consultingFormState.title.en.value === ""
                ? "Le champ titre anglais est requis"
                : "",
          },
        },
      });
      return;
    }

    try {
      setIsLoading(true);
      const body: ConsultingPayloadToAPI = {
        title: {
          fr: consultingFormState.title.fr.value,
          en: consultingFormState.title.en.value,
        },
        subtitle: {
          fr: consultingFormState.subtitle.fr.value,
          en: consultingFormState.subtitle.en.value,
        },
        subtitle2: {
          fr: consultingFormState.subtitle2.fr.value,
          en: consultingFormState.subtitle2.en.value,
        },
        description: consultingFormState.description,
        city: consultingFormState.city.value,
        date: dateState.date as Date,
        published: consultingFormState.published,
      };

      if (mainImgState.file !== undefined) {
        try {
          const resMainImg = await API.uploadFile("image", mainImgState.file);

          body.mainImgUri = resMainImg.path;
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }

      if (Object.keys(imagesState.files).length !== 0) {
        let responseApiImages: string[] = [];
        for (const image of Object.keys(imagesState.files)) {
          const fileIndex = Number(image);

          await API.uploadFile(
            "image",
            (imagesState.files as Files)[fileIndex]
          ).then((resFile) => {
            if (resFile.ok) responseApiImages.push(resFile.path);
          });
        }
        body.images = responseApiImages;
      }

      const resConsulting = isEditing
        ? await API.put({
            path: CONSULTING_URL + consultingFormState._id,
            body,
          })
        : await API.post({
            path: CONSULTING_URL,
            body,
          });

      if (resConsulting.ok) {
        setConsultings(resConsulting.data);

        onResetConsultingState();
      }
    } catch (error) {
      // TODO HANDLE ERROR
      console.log("error", error);
    } finally {
      setIsConsultingFormModalOpen(false);
      setIsLoading(false);
    }
  };
  const onClickOnUpdateConsulting = useCallback(
    async (consultingData: ConsultingProps) => {
      setConsultingFormState({
        _id: consultingData._id,
        mainImgUri: consultingData.mainImgUri,
        date: consultingData.date,
        images: consultingData.images,
        title: createLocalizedInputStruct(consultingData?.title),
        description: consultingData.description,
        city: createInputStruct(consultingData?.city),
        subtitle: createLocalizedInputStruct(consultingData?.subtitle),
        subtitle2: createLocalizedInputStruct(consultingData?.subtitle2),
        published: consultingData.published,
      });
      setDateState({ date: consultingData.date, error: false });
      setIsConsultingFormModalOpen(true);
    },
    []
  );

  const onDeleteConsulting = useCallback(
    async (_id: string) => {
      const resConsulting = await API.delete({
        path: CONSULTING_URL + _id,
      });
      if (resConsulting.ok)
        setConsultings(consultings?.filter((co) => co._id !== _id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consultings]
  );

  if (isConsultingsError) return <ErrorAPI message={isConsultingsError} />;
  if (isLoadingConsulting) return <CustomLoader />;

  return (
    <>
      <AddConsulting
        onResetConsultingState={onResetConsultingState}
        setIsConsultingFormModalOpen={setIsConsultingFormModalOpen}
      />
      <ConsultingList
        onClickOnUpdateConsulting={onClickOnUpdateConsulting}
        consultings={consultings}
        onDeleteConsulting={onDeleteConsulting}
      />
      <ConsultingModalForm
        isLoading={isLoading}
        setConsultingFormState={setConsultingFormState}
        setMainImgState={setMainImgState}
        setImagesState={setImagesState}
        setDateState={setDateState}
        setIsConsultingFormModalOpen={setIsConsultingFormModalOpen}
        onSubmitConsulting={onSubmitConsulting}
        onCloseModal={() => setIsConsultingFormModalOpen(false)}
        consultingFormState={consultingFormState}
        mainImgState={mainImgState}
        imagesState={imagesState}
        dateState={dateState}
        isConsultingFormModalOpen={isConsultingFormModalOpen}
      />
    </>
  );
};

export default Consultings;
