import { Dispatch, FC, SetStateAction } from "react";
import { Box } from "@mui/material";
import CustomModal from "ui/CustomModal";
import { ImgState } from "models/App";

import { TemperamentFormState } from "models/Studio/Temperament";
import CustomImg from "ui/images/CustomImg";
import CustomInputUploadFile from "ui/form/CustomInputUploadFile";

type TemperamentModalFormProps = {
  isTemperamentFormModalOpen: boolean;
  setIsTemperamentFormModalOpen: Dispatch<SetStateAction<boolean>>;
  setImgState: Dispatch<SetStateAction<ImgState>>;
  onSubmitTemperament: () => void;
  onCloseModal: () => void;
  temperamentFormState: TemperamentFormState;
  imgState: ImgState;
};

const TemperamentModalForm: FC<TemperamentModalFormProps> = ({
  isTemperamentFormModalOpen,
  setIsTemperamentFormModalOpen,
  onSubmitTemperament,
  onCloseModal,
  temperamentFormState,
  imgState,
  setImgState,
}) => {
  return (
    <CustomModal
      header={"Modifier le Temperament"}
      open={isTemperamentFormModalOpen}
      setOpen={setIsTemperamentFormModalOpen}
      onValidate={onSubmitTemperament}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      body={
        <>
          <Box sx={{ mb: 4 }}>
            {!imgState.file && (
              <Box sx={{ mb: 1 }}>
                <CustomImg
                  alt="temperament_image"
                  imgUri={temperamentFormState.mainImgUri}
                />
              </Box>
            )}
            <CustomInputUploadFile
              label="Modifier la photo"
              name="image"
              file={imgState.file}
              setFile={(file) => setImgState({ error: false, file })}
            />
          </Box>
        </>
      }
    />
  );
};
export default TemperamentModalForm;
// 131
