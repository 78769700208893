export const smHeightAppBar = "73px";
export const xsHeightAppBar = "56px";

export const heightTabMenu = "48px";

export const minHeight = {
  xs: `calc(100vh - ${xsHeightAppBar})`,
  sm: `calc(100vh - ${smHeightAppBar})`,
};

export const responsiveHeightAppBar = {
  xs: xsHeightAppBar,
  sm: smHeightAppBar,
};

export const sessionLeftPanelWidth = "48%";
