import useEpisodes from "hooks/useEpisodes";
import { EpisodesProps } from "models/Episode";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
} from "react";

// ** UN USED

type Context = {
  episodes: EpisodesProps[] | undefined;
  error: string;
  isLoading: boolean;
  setEpisodes: Dispatch<SetStateAction<EpisodesProps[] | undefined>>;
};

const EpisodesContext = createContext<Context>({} as Context);

export const useEpisodeContext = (): Context => useContext(EpisodesContext);

type Props = {
  children: React.ReactNode;
};

const EpisodesContextWrapper: FC<Props> = ({ children }) => {
  const { episodes, error, isLoading, setEpisodes } = useEpisodes();

  const contextValue = useMemo(
    () => ({
      episodes,
      error,
      isLoading,
      setEpisodes,
    }),
    [episodes, error, isLoading, setEpisodes]
  );

  return (
    <EpisodesContext.Provider value={contextValue}>
      {children}
    </EpisodesContext.Provider>
  );
};

export default EpisodesContextWrapper;
