import { Box, Stack, Typography } from "@mui/material";

import type { FC, ReactElement } from "react";
import { getResponsiveValue } from "utils/app.utils";

type Props = {
  title: string;
  description: ReadonlyArray<string>;
  footer?: ReactElement;
};

const SectionWrapper: FC<Props> = ({ title, description, footer }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        flexDirection: getResponsiveValue("column", "row"),
        py: getResponsiveValue(2, 0),
      }}
    >
      <Box flex={1} sx={{ pb: getResponsiveValue(4, 0) }}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Stack flex={1} spacing={2}>
        {description.map((text) => (
          <Typography variant="body2" key={"homePage_Introduction_" + text}>
            {text}
          </Typography>
        ))}
        {footer}
      </Stack>
    </Box>
  );
};

export default SectionWrapper;
