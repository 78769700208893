import { Box, Grid } from "@mui/material";
import TicketingCard, {
  TICKETING_WIDTH_CARD,
} from "components/MainApp/TicketingCard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { FC, useState } from "react";
import { TicketingProps } from "models/Ticketing";
import CustomConfirmModal from "ui/CustomConfirmModal";

const colorEditIcon = "rgba(255, 255, 255, 0.54)";

type TicketingListProps = {
  ticketings: TicketingProps[];
  onClickOnUpdateTicketing: (ticketing: TicketingProps) => void;
  onDeleteTicketing: () => void;
  onOpenEditModal: () => void;
};

const TicketingList: FC<TicketingListProps> = ({
  ticketings,
  onClickOnUpdateTicketing,
  onDeleteTicketing,
  onOpenEditModal,
}) => {
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  return (
    <>
      {ticketings.map((ticketing) => (
        <Grid
          key={"ticketing_admin_" + ticketing._id}
          item
          xs={12}
          md={6}
          lg={4}
        >
          <Box
            maxWidth={400}
            margin="auto"
            width={TICKETING_WIDTH_CARD}
            position="relative"
          >
            <TicketingCard isAdmin ticketing={ticketing} />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                background: "rgba(10, 9, 9, 0.5)",
              }}
            >
              <Box textAlign="right" px={1} py={1}>
                <EditIcon
                  onClick={() => {
                    onClickOnUpdateTicketing(ticketing);
                    onOpenEditModal();
                  }}
                  sx={{
                    color: colorEditIcon,
                    ":hover": { cursor: "pointer", color: "green" },
                    mr: 1,
                  }}
                />
                <DeleteIcon
                  onClick={() => {
                    onClickOnUpdateTicketing(ticketing);
                    setIsConfirmDeleteModalOpen(true);
                  }}
                  sx={{
                    color: colorEditIcon,
                    ":hover": { cursor: "pointer", color: "red" },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
      <CustomConfirmModal
        open={isConfirmDeleteModalOpen}
        setOpen={setIsConfirmDeleteModalOpen}
        onConfirm={onDeleteTicketing}
        text={
          "Vous êtes sur le point de supprimer un evenement, êtes vous sur ?"
        }
      />
    </>
  );
};
export default TicketingList;
