import { FC, useState } from "react";

import AddCustomers from "./AddCustomers";
import {
  CustomersFormState,
  CustomersPayloadToAPI,
  CustomersProps,
} from "models/Studio/Customers";
import API from "services/api";
import CustomersModalForm from "./CustomersModalForm";
import CustomersList from "./CustomersList";
import { CUSTOMERS_URL } from "services/endPoint";

import ErrorAPI from "components/ErrorAPI";
import CustomLoader from "ui/CustomLoader";
import { useStudioContext } from "context/StudioContext";

const initState = {
  _id: "",
  logo: "",
  file: undefined,
  error: false,
  articleLink: "",
};

const StudioCustomers: FC = () => {
  const {
    customers,
    loadingCustomers: loading,
    errorCustomers: error,
    setCustomers,
  } = useStudioContext();

  const [isCustomersFormModalOpen, setIsCustomersFormModalOpen] =
    useState(false);
  const [customersFormState, setCustomersFormState] =
    useState<CustomersFormState>(initState);
  const isEditing = !!customersFormState._id;

  const onClickOnUpdateCustomers = async (customersData: CustomersProps) => {
    setCustomersFormState({
      ...customersFormState,
      _id: customersData?._id,
      logo: customersData?.logo,
      articleLink: customersData?.articleLink,
    });
    setIsCustomersFormModalOpen(true);
  };

  // ! DELETE
  const onDeleteCustomers = async (_id: string) => {
    const resCustomers = await API.delete({
      path: CUSTOMERS_URL + _id,
    });
    setCustomers(resCustomers.data);
  };

  const onResetState = () => {
    setIsCustomersFormModalOpen(false);
    setCustomersFormState(initState);
  };

  const onCloseModal = () => {
    onResetState();
  };

  const onSubmitCustomers = async () => {
    const body: CustomersPayloadToAPI = {};
    body.articleLink = customersFormState.articleLink;
    let resImg;
    if (customersFormState.file !== undefined) {
      resImg = await API.uploadFile("image", customersFormState.file);
      body.logo = resImg.path;
    }
    let resCustomers;
    if (isEditing) {
      resCustomers = await API.put({
        path: CUSTOMERS_URL + customersFormState._id,
        body,
      });
    } else {
      resCustomers = await API.post({
        path: CUSTOMERS_URL,
        body,
      });
    }
    if (resCustomers.ok) {
      setCustomers(resCustomers.data);
      onResetState();
    } else {
      // TODO TRAIT ERROR
      console.log("error");
    }
  };

  if (error) return <ErrorAPI minHeight="72vh" message={error} />;
  if (loading) return <CustomLoader />;

  return (
    <>
      <AddCustomers setIsCustomersFormModalOpen={setIsCustomersFormModalOpen} />
      <CustomersList
        customers={customers}
        onClickOnUpdateCustomers={onClickOnUpdateCustomers}
        onDeleteCustomers={onDeleteCustomers}
      />
      <CustomersModalForm
        setCustomersFormState={setCustomersFormState}
        setIsCustomersFormModalOpen={setIsCustomersFormModalOpen}
        onSubmitCustomers={onSubmitCustomers}
        onCloseModal={onCloseModal}
        customersFormState={customersFormState}
        isCustomersFormModalOpen={isCustomersFormModalOpen}
      />
    </>
  );
};
export default StudioCustomers;
