import { FC } from "react";
import { CardMedia } from "@mui/material";

import { getImgUrl } from "utils/app.utils";

interface LogoItemProps {
  logo: string;
  articleLink: string;
}

const LogoItem: FC<LogoItemProps> = ({ logo, articleLink }) => {
  return (
    <a href={articleLink} target="_blank" rel="noreferrer">
      <CardMedia
        sx={{
          ":hover": {
            opacity: 0.7,
            cursor: "pointer",
          },
        }}
        component="img"
        image={getImgUrl(logo)}
        alt={"episodeImg_" + articleLink}
        crossOrigin="anonymous"
      />
    </a>
  );
};

export default LogoItem;
