/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState } from "react";

import CustomLoader from "ui/CustomLoader";
import { Box, Chip, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import CustomImg from "ui/images/CustomImg";
import CustomImagesList from "ui/images/CustomImagesList";
import useDate from "hooks/useDate";
import { EpisodesProps } from "models/Episode";
import { getLocalizedString } from "use18n";
import CustomConfirmModal from "ui/CustomConfirmModal";
import CustomDivider from "ui/CustomDivider";
import CustomAlert from "ui/CustomAlert";

type EpisodesListProps = {
  episodes?: EpisodesProps[];
  onDeleteEpisode: (episodeId: string) => void;
  onClickOnUpdateEpisode: (episode: EpisodesProps) => void;
};

const EpisodesList: FC<EpisodesListProps> = ({
  episodes,
  onDeleteEpisode,
  onClickOnUpdateEpisode,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteEpisodeId, setDeleteEpisodeId] = useState<string>();
  if (!episodes) return <CustomLoader />;

  return (
    <Stack divider={<CustomDivider />} spacing={2}>
      <CustomAlert severity="info">
        Les paragraphes de description, les sous-titres éventuelles, la date et
        le lieu se trouvent dans la modale lors de l'édition ou l'ajout d'un
        épisode
      </CustomAlert>
      {episodes.map((episode) => {
        const { _id, title, images, mainImgUri, date, published } = episode;
        return (
          <Box key={_id}>
            <Stack spacing={2}>
              <Box position="relative">
                <CustomImg
                  alt="mainImg_episode"
                  imgUri={mainImgUri}
                  size="lg"
                  actionIcon={[
                    {
                      index: 12000,
                      action: () => onClickOnUpdateEpisode(episode),
                      icon: <BorderColorIcon />,
                      appBarActionHoverColor: "green",
                    },
                    {
                      index: 12001,
                      action: () => {
                        setDeleteEpisodeId(_id);
                        setIsDeleteModalOpen(true);
                      },
                      icon: <DeleteIcon />,
                      appBarActionHoverColor: "red",
                    },
                  ]}
                  hasAppBar
                  appBarSubtitle={`Le ${useDate(date).dayMonthAndYear}`}
                  appBarTitle={getLocalizedString(title)}
                  chip={
                    <Chip
                      label={published ? "Publié" : "Non publié"}
                      color={published ? "success" : "warning"}
                    />
                  }
                />
              </Box>
              <CustomImagesList images={images} />
            </Stack>
          </Box>
        );
      })}

      <CustomConfirmModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onConfirm={() => onDeleteEpisode(deleteEpisodeId as string)}
      />
    </Stack>
  );
};
export default EpisodesList;
