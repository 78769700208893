import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
const fontFamily = "Futura-Light";

export const COLORS = {
  gray: {
    100: "#F2F3F3",
    500: "rgba(162, 162, 162, 1)",
  },
};

type ColorKey = keyof typeof COLORS;

type ColorValue<T extends ColorKey> = keyof (typeof COLORS)[T];

export type ColorsType = {
  [K in ColorKey]: {
    [P in ColorValue<K>]: string;
  };
};

const theme = createTheme({
  typography: {
    fontFamily: [
      fontFamily,
      // "Futura-Bold",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    //     font-family: Futura PT;
    // font-size: 82px;
    // font-weight: 400;
    // line-height: 80px;
    // letter-spacing: 1px;
    // text-align: left;

    h1: {
      fontSize: "36px", // avant 82px
      fontWeight: 400,
      lineHeight: 1.07692,
      letterSpacing: "1px",
      fontFamily: "Futura-Book", // todo use FuturaPT
    },
    h2: {
      fontSize: "22px", // avant 30 px
      fontWeight: 400,
      lineHeight: 1.32,
      letterSpacing: "1px",
      fontFamily: "Futura-Book", // todo use FuturaPT
    },
    h3: {
      fontSize: "42px",
      fontWeight: 400,
      lineHeight: "50px",
      fontFamily: "Futura-Book", // todo use FuturaPT
      letterSpacing: "1px",
    },
    h4: {
      fontSize: "26px",
      fontFamily: "Futura-Book",
      fontWeight: 500,
      lineHeight: 1.07692,
    },
    h5: {
      fontSize: "18px", // only used on title card media
      fontWeight: 400,
      lineHeight: "32px",
      fontFamily: "Futura-Book", // todo use FuturaPT
      letterSpacing: "1px",
    },
    h6: {
      fontSize: "18px",
      lineHeight: "32px",
      fontWeight: "800",
      fontFamily: "Futura-Book", // todo use FuturaPT
    },

    caption: {
      fontFamily: "Futura-Book", // todo use FuturaPT
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "1px",
    },
    subtitle1: {
      fontSize: "16px",
      letterSpacing: "3px",
      textTransform: "uppercase",
      lineHeight: 1.1,
    },
    subtitle2: {
      fontFamily: "Futura-Book", // todo use FuturaPT
      fontSize: "13px", // avant 20px
      fontWeight: 400,
      lineHeight: 1.1,
    },
    body1: {
      fontFamily: "Futura-Book", // todo use FuturaPT
      fontSize: "15px", // avant 23px
      fontWeight: 400,
      lineHeight: "27px",
      letterSpacing: "1px",
    },
    body2: {
      fontSize: "18px",
      lineHeight: "32px",
      fontWeight: "400",
      fontFamily: "Futura-Book", // todo use FuturaPT
    },
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#000",
    },
  },
});

export default theme;
