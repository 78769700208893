export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const SIGNIN_TOKEN_URL = "/signin-token";
export const CONTACT_URL = "/contact";

export const SUB_URL = process.env.REACT_APP_API_URL
  ? // LOCAL
    ""
  : // PROD
    "/";

// * * * CONFIG
export const GET_CONFIG_URL = "/config";
export const CONFIG_URL = SUB_URL + "config/";

// * * * EPISODES
export const GET_EPISODES_URL = "/episodes";
export const EPISODE_URL = SUB_URL + "episode/";

// * * * CONSULTING
export const GET_CONSULTINGS_URL = "/consultings";
export const CONSULTING_URL = SUB_URL + "consulting/";

// * * * TICKETING
export const GET_TICKETINGS_URL = "/ticketings";
export const TICKETING_URL = SUB_URL + "ticketing/";

// * * * STUDIO/TEMPERAMENT
export const GET_TEMPERAMENTS_URL = "/temperament";
export const TEMPERAMENT_URL = SUB_URL + "temperament/";

// * * * STUDIO/PARTNERS
export const GET_PARTNERS_URL = "/partners";
export const PARTNERS_URL = SUB_URL + "partners/";

// * * * STUDIO/CUSTOMERS
export const GET_CUSTOMERS_URL = "/customers";
export const CUSTOMERS_URL = SUB_URL + "customers/";

// * * * STUDIO/PRESS
export const GET_PRESS_URL = "/press";
export const PRESS_URL = SUB_URL + "press/";

// * * * JSON
export const GET_JSON = SUB_URL + "json/";
export const GET_DOWNLOAD_JSON = SUB_URL + "download/";
export const POST_UPLOAD_JSON = SUB_URL + "upload/";
