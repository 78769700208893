import { Typography } from "@mui/material";
import { FC } from "react";
import CustomModal from "./CustomModal";

type CustomConfirmModalProps = {
  text?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  onConfirm: () => void;
};

/**
 * Props for the CustomConfirmModal component.
 * @typedef {CustomConfirmModalProps} CustomConfirmModalProps
 * @property {string} [text="Vous êtes sur le point de supprimer un élément, êtes vous sur ?"] - The confirmation message displayed in the modal.
 * @property {boolean} open - Whether the modal is open or not.
 * @property {(val: boolean) => void} setOpen - Function to set the open state of the modal.
 * @property {() => void} onConfirm - Function to be executed when the user confirms the action.
 */

/**
 * CustomConfirmModal component.
 * Displays a custom confirmation modal with a custom message and a callback for the confirmation action.
 *
 * @param {CustomConfirmModalProps} props - Props for the CustomConfirmModal component.
 * @example
 * ```jsx
 * import CustomConfirmModal from './CustomConfirmModal';
 *
 * function App() {
 *   const [isModalOpen, setIsModalOpen] = useState(false);
 *
 *   const handleDelete = () => {
 *     console.log('Item deleted');
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={() => setIsModalOpen(true)}>
 *         Delete item
 *       </button>
 *       <CustomConfirmModal
 *         open={isModalOpen}
 *         setOpen={setIsModalOpen}
 *         onConfirm={handleDelete}
 *       />
 *     </div>
 *   );
 * }
 * ```
 * @returns {JSX.Element} The rendered CustomConfirmModal component.
 */

const CustomConfirmModal: FC<CustomConfirmModalProps> = ({
  text = "Vous êtes sur le point de supprimer un élément, êtes vous sur ?",
  open,
  setOpen,
  onConfirm,
}) => {
  return (
    <CustomModal
      header={"Attention"}
      body={<Typography variant="body2">{text}</Typography>}
      open={open}
      setOpen={setOpen}
      onValidate={() => {
        onConfirm();
        setOpen(false);
      }}
    />
  );
};

export default CustomConfirmModal;
